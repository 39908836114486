//
// Flush tables
//


.table-flush {
  th, td {
    border-left: 0;
    border-right: 0;
  }

  thead > tr {
    th {
      border-top: 0;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }

  tbody > tr {
    &:last-child {
      th, td {
        border-bottom: 0;
      }
    }
    th,
    td {
      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }
}
