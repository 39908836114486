//
// Reboot
//

@import "bootstrap/scss/reboot";

*,
*::before,
*::after {
  border: 0 solid $border-color;
}

iframe {
  display: block;
  vertical-align: middle;
}

figcaption,
figure,
main {
  display: block;
  margin: 0;
}

figure {
  max-width: 100%;
  min-height: 1px;
}

img,
video {
  max-width: 100%;
  height: auto;
}

svg {
  vertical-align: middle;
  overflow: hidden;
}

a {
  outline: none;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  color: var(--#{$prefix}headings-color);
}
