// @import "@webpixels/css/core";

// Default: Dark theme
@mixin dark {
  // Document
  color-scheme: dark;

  // Body
  --x-body-bg: #{to-rgb($gray-900)};
  --x-body-color: var(--x-gray-100);

  // Typography
  --x-headings-color: var(--x-gray-50);
  --x-heading-rgb: #{to-rgb($gray-50)};
  --x-muted-rgb: #{to-rgb($gray-300)};

  // Global
  --x-border-color: #{to-rgb($gray-800)};

  // Colors
  --x-surface-primary-rgb: #{to-rgb($gray-900)};
  --x-surface-secondary-rgb: #{to-rgb($gray-800)};

  // Navbar
  .navbar {
    --x-navbar-color: var(--x-gray-100);
    --x-navbar-hover-bg: #{darken($gray-800, 3%)};
    --x-navbar-hover-color: var(--x-gray-100);
  }

  // Cards
  .card {
    --x-card-bg: var(--x-gray-800);
    --x-card-border-color: var(--x-gray-800);
    --x-card-color: var(--x-body-color);
  }

}
