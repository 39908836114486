@keyframes progressCircleGrow {
  0%, 33% {
    --#{$prefix}progress-circle-percentage: 0;
  }
  100% {
    --#{$prefix}progress-circle-percentage: var(--value);
  }
}

@property --#{$prefix}progress-circle-percentage {
  syntax: '<number>';
  inherits: false;
  initial-value: 0;
}

.progress-circle {
  --#{$prefix}progress-circle-size: #{$progress-circle-size};
  --#{$prefix}progress-circle-bg: #{$progress-circle-bg};
  --#{$prefix}progress-circle-color: #{$progress-circle-color};
  --#{$prefix}progress-circle-bar-bg: #{$progress-circle-bar-bg};
  --#{$prefix}progress-circle-percentage: var(--value);

  animation: progressCircleGrow 3s 1 forwards;
  width: var(--#{$prefix}progress-circle-size);
  height: var(--#{$prefix}progress-circle-size);
  border-radius: 50%;
  display: grid;
  place-items: center;
  background: radial-gradient(closest-side, currentColor 80%, transparent 0 99.9%, currentColor 0),
    conic-gradient(var(--#{$prefix}progress-circle-bar-bg) calc(var(--#{$prefix}progress-circle-percentage) * 1%), var(--#{$prefix}progress-circle-bg) 0);
  font-size: calc(var(--#{$prefix}progress-circle-size) / 5);
}

.progress-circle-counter {
  &::before {
    counter-reset: percentage var(--value);
    content: counter(percentage) '%';
    color: var(--#{$prefix}progress-circle-color);
  }
}

.progress-circle-2xl {
  --#{$prefix}progress-circle-size: #{$progress-circle-size-2xl};
}

.progress-circle-xl {
  --#{$prefix}progress-circle-size: #{$progress-circle-size-xl};
}

.progress-circle-lg {
  --#{$prefix}progress-circle-size: #{$progress-circle-size-lg};
}

.progress-circle-sm {
  --#{$prefix}progress-circle-size: #{$progress-circle-size-sm};
}

@each $color, $value in $theme-colors {
  .progress-circle-#{$color} {
    --#{$prefix}progress-circle-color: var(--#{$prefix}#{$color});
    --#{$prefix}progress-circle-bar-bg: var(--#{$prefix}#{$color});
  }
}

//
// .progress-circle {
//     width: 100px;
//     height: 100px;
//
//     .progressbar-text {
//         font-size: 1.5rem;
//         font-weight: $headings-font-weight;
//         color: $headings-color;
//     }
//
//     [class^="display"],
//     .h1, .h2, .h3 {
//         color: $headings-color;
//     }
//
//     svg path {
//         @if $enable-rounded {
//             stroke-linecap: round;
//         }
//     }
//
//     &.progress-sm {
//         width: 60px;
//         height: 60px;
//
//         .progressbar-text {
//             font-size: .875rem;
//         }
//     }
//
//     &.progress-lg {
//         width: 140px;
//         height: 140px;
//
//         .progressbar-text {
//             font-size: 1.25rem;
//         }
//     }
// }
