/**
 * Theme: default
 */



// Variables
@import "clever/variables";

// Commons styles
// @import "clever/styles";

// Light theme (Default)
// Can be forced with data-theme="light"
@import "clever/light";

// Dark theme (Auto)
// Automatically enabled if user has Dark mode enabled
@import "clever/dark";
