// Functions
@import "@webpixels/css/core/functions";
// @import "@webpixels/css/core/variables";

// Variables
@import 'themes/clever';

// Core
@import "@webpixels/css/core/variables";
@import "@webpixels/css/core/maps";
@import "@webpixels/css/core/mixins";

// Components
@import "@webpixels/css/base";
@import "@webpixels/css/forms";
@import "@webpixels/css/components";

// Extras
@import "@webpixels/css/extras/article";
@import "@webpixels/css/extras/section-step";

// Libs
@import "libs/apexcharts";
@import "libs/flatpickr";
// @import "libs/choices"; 

// List and Dark Mode
@media only screen and (prefers-color-scheme: dark) {
  :root:not([data-theme="light"]) {
    @include dark;
  }
}

// Dark theme (Forced)
// Enabled if forced with data-theme="dark"
[data-theme="dark"] {
  @include dark;
}
