//
// Icon
//

.icon {
	font-size: calc($icon-size / 1.75);
	line-height: 1;

	svg {
		width: $svg-icon-size;
    height: $svg-icon-size;
	}
}

// Size variations

.icon-xl {
	font-size: calc($icon-size-xl / 1.75);
}

.icon-lg {
	font-size: calc($icon-size-lg / 1.75);
}

.icon-sm {
	font-size: calc($icon-size-sm / 1.75);
}

.icon-xs {
	font-size: calc($icon-size-xs / 1.75);
}
