[data-theme="light"], :root:not([data-theme="dark"]) {
  color-scheme: light;
}

:root {
  --x-white: #fff;
  --x-black: #000;
  --x-gray-50: #fafafa;
  --x-gray-100: #f5f9fc;
  --x-gray-200: #e7eaf0;
  --x-gray-300: #cfd6df;
  --x-gray-400: #abb6c5;
  --x-gray-500: #8898a9;
  --x-gray-600: #6b7b93;
  --x-gray-700: #525f7f;
  --x-gray-800: #2d3748;
  --x-gray-900: #16192c;
  --x-blue-100: #ccebff;
  --x-blue-200: #99d6ff;
  --x-blue-300: #66c2ff;
  --x-blue-400: #33adff;
  --x-blue-500: #09f;
  --x-blue-600: #007acc;
  --x-blue-700: #005c99;
  --x-blue-800: #003d66;
  --x-blue-900: #001f33;
  --x-indigo-100: #dedffd;
  --x-indigo-200: #bebffb;
  --x-indigo-300: #9da0f9;
  --x-indigo-400: #7d80f7;
  --x-indigo-500: #5c60f5;
  --x-indigo-600: #4a4dc4;
  --x-indigo-700: #373a93;
  --x-indigo-800: #252662;
  --x-indigo-900: #121331;
  --x-purple-100: #e7ddff;
  --x-purple-200: #d0bcff;
  --x-purple-300: #b89aff;
  --x-purple-400: #a179ff;
  --x-purple-500: #8957ff;
  --x-purple-600: #6e46cc;
  --x-purple-700: #523499;
  --x-purple-800: #372366;
  --x-purple-900: #1b1133;
  --x-pink-100: #ffddeb;
  --x-pink-200: #ffbcd7;
  --x-pink-300: #ff9ac2;
  --x-pink-400: #ff79ae;
  --x-pink-500: #ff579a;
  --x-pink-600: #cc467b;
  --x-pink-700: #99345c;
  --x-pink-800: #66233e;
  --x-pink-900: #33111f;
  --x-magenta-100: #fcd6ff;
  --x-magenta-200: #f8adff;
  --x-magenta-300: #f585ff;
  --x-magenta-400: #f15cff;
  --x-magenta-500: #e3f;
  --x-magenta-600: #be29cc;
  --x-magenta-700: #8f1f99;
  --x-magenta-800: #5f1466;
  --x-magenta-900: #300a33;
  --x-red-100: #ffd6e0;
  --x-red-200: #ffadc2;
  --x-red-300: #ff85a3;
  --x-red-400: #ff5c85;
  --x-red-500: #f36;
  --x-red-600: #cc2952;
  --x-red-700: #991f3d;
  --x-red-800: #661429;
  --x-red-900: #330a14;
  --x-orange-100: #ffe8cc;
  --x-orange-200: #ffd199;
  --x-orange-300: #ffba66;
  --x-orange-400: #ffa333;
  --x-orange-500: #ff8c00;
  --x-orange-600: #cc7000;
  --x-orange-700: #995400;
  --x-orange-800: #663800;
  --x-orange-900: #331c00;
  --x-yellow-100: #fff1cc;
  --x-yellow-200: #ffe499;
  --x-yellow-300: #ffd666;
  --x-yellow-400: #ffc933;
  --x-yellow-500: #fb0;
  --x-yellow-600: #cc9600;
  --x-yellow-700: #997000;
  --x-yellow-800: #664b00;
  --x-yellow-900: #332500;
  --x-green-100: #ccf5e7;
  --x-green-200: #99ebcf;
  --x-green-300: #66e0b8;
  --x-green-400: #33d6a0;
  --x-green-500: #0c8;
  --x-green-600: #00a36d;
  --x-green-700: #007a52;
  --x-green-800: #005236;
  --x-green-900: #00291b;
  --x-teal-100: #d3f8f8;
  --x-teal-200: #a7f1f1;
  --x-teal-300: #7aebeb;
  --x-teal-400: #4ee4e4;
  --x-teal-500: #2dd;
  --x-teal-600: #1bb1b1;
  --x-teal-700: #148585;
  --x-teal-800: #0e5858;
  --x-teal-900: #072c2c;
  --x-cyan-100: #ccf6ff;
  --x-cyan-200: #9ef;
  --x-cyan-300: #66e5ff;
  --x-cyan-400: #3df;
  --x-cyan-500: #00d4ff;
  --x-cyan-600: #0ac;
  --x-cyan-700: #007f99;
  --x-cyan-800: #056;
  --x-cyan-900: #002a33;
  --x-primary: #5c60f5;
  --x-secondary: #cfd6df;
  --x-tertiary: #ff579a;
  --x-success: #0c8;
  --x-info: #00d4ff;
  --x-warning: #ff8c00;
  --x-danger: #f36;
  --x-white: #fff;
  --x-light: #fff;
  --x-dark: #16192c;
  --x-surface-primary: #fff;
  --x-surface-secondary: #f5f9fc;
  --x-surface-tertiary: #fafafa;
  --x-surface-light: #e7eaf0;
  --x-surface-dark: #141729;
  --x-primary-rgb: 92, 96, 245;
  --x-secondary-rgb: 207, 214, 223;
  --x-tertiary-rgb: 255, 87, 154;
  --x-success-rgb: 0, 204, 136;
  --x-info-rgb: 0, 212, 255;
  --x-warning-rgb: 255, 140, 0;
  --x-danger-rgb: 255, 51, 102;
  --x-white-rgb: 255, 255, 255;
  --x-light-rgb: 255, 255, 255;
  --x-dark-rgb: 22, 25, 44;
  --x-white-rgb: 255, 255, 255;
  --x-black-rgb: 0, 0, 0;
  --x-gray-50-rgb: 250, 250, 250;
  --x-gray-100-rgb: 245, 249, 252;
  --x-gray-200-rgb: 231, 234, 240;
  --x-gray-300-rgb: 207, 214, 223;
  --x-gray-400-rgb: 171, 182, 197;
  --x-gray-500-rgb: 136, 152, 169;
  --x-gray-600-rgb: 107, 123, 147;
  --x-gray-700-rgb: 82, 95, 127;
  --x-gray-800-rgb: 45, 55, 72;
  --x-gray-900-rgb: 22, 25, 44;
  --x-blue-100-rgb: 204, 235, 255;
  --x-blue-200-rgb: 153, 214, 255;
  --x-blue-300-rgb: 102, 194, 255;
  --x-blue-400-rgb: 51, 173, 255;
  --x-blue-500-rgb: 0, 153, 255;
  --x-blue-600-rgb: 0, 122, 204;
  --x-blue-700-rgb: 0, 92, 153;
  --x-blue-800-rgb: 0, 61, 102;
  --x-blue-900-rgb: 0, 31, 51;
  --x-indigo-100-rgb: 222, 223, 253;
  --x-indigo-200-rgb: 190, 191, 251;
  --x-indigo-300-rgb: 157, 160, 249;
  --x-indigo-400-rgb: 125, 128, 247;
  --x-indigo-500-rgb: 92, 96, 245;
  --x-indigo-600-rgb: 74, 77, 196;
  --x-indigo-700-rgb: 55, 58, 147;
  --x-indigo-800-rgb: 37, 38, 98;
  --x-indigo-900-rgb: 18, 19, 49;
  --x-purple-100-rgb: 231, 221, 255;
  --x-purple-200-rgb: 208, 188, 255;
  --x-purple-300-rgb: 184, 154, 255;
  --x-purple-400-rgb: 161, 121, 255;
  --x-purple-500-rgb: 137, 87, 255;
  --x-purple-600-rgb: 110, 70, 204;
  --x-purple-700-rgb: 82, 52, 153;
  --x-purple-800-rgb: 55, 35, 102;
  --x-purple-900-rgb: 27, 17, 51;
  --x-pink-100-rgb: 255, 221, 235;
  --x-pink-200-rgb: 255, 188, 215;
  --x-pink-300-rgb: 255, 154, 194;
  --x-pink-400-rgb: 255, 121, 174;
  --x-pink-500-rgb: 255, 87, 154;
  --x-pink-600-rgb: 204, 70, 123;
  --x-pink-700-rgb: 153, 52, 92;
  --x-pink-800-rgb: 102, 35, 62;
  --x-pink-900-rgb: 51, 17, 31;
  --x-magenta-100-rgb: 252, 214, 255;
  --x-magenta-200-rgb: 248, 173, 255;
  --x-magenta-300-rgb: 245, 133, 255;
  --x-magenta-400-rgb: 241, 92, 255;
  --x-magenta-500-rgb: 238, 51, 255;
  --x-magenta-600-rgb: 190, 41, 204;
  --x-magenta-700-rgb: 143, 31, 153;
  --x-magenta-800-rgb: 95, 20, 102;
  --x-magenta-900-rgb: 48, 10, 51;
  --x-red-100-rgb: 255, 214, 224;
  --x-red-200-rgb: 255, 173, 194;
  --x-red-300-rgb: 255, 133, 163;
  --x-red-400-rgb: 255, 92, 133;
  --x-red-500-rgb: 255, 51, 102;
  --x-red-600-rgb: 204, 41, 82;
  --x-red-700-rgb: 153, 31, 61;
  --x-red-800-rgb: 102, 20, 41;
  --x-red-900-rgb: 51, 10, 20;
  --x-orange-100-rgb: 255, 232, 204;
  --x-orange-200-rgb: 255, 209, 153;
  --x-orange-300-rgb: 255, 186, 102;
  --x-orange-400-rgb: 255, 163, 51;
  --x-orange-500-rgb: 255, 140, 0;
  --x-orange-600-rgb: 204, 112, 0;
  --x-orange-700-rgb: 153, 84, 0;
  --x-orange-800-rgb: 102, 56, 0;
  --x-orange-900-rgb: 51, 28, 0;
  --x-yellow-100-rgb: 255, 241, 204;
  --x-yellow-200-rgb: 255, 228, 153;
  --x-yellow-300-rgb: 255, 214, 102;
  --x-yellow-400-rgb: 255, 201, 51;
  --x-yellow-500-rgb: 255, 187, 0;
  --x-yellow-600-rgb: 204, 150, 0;
  --x-yellow-700-rgb: 153, 112, 0;
  --x-yellow-800-rgb: 102, 75, 0;
  --x-yellow-900-rgb: 51, 37, 0;
  --x-green-100-rgb: 204, 245, 231;
  --x-green-200-rgb: 153, 235, 207;
  --x-green-300-rgb: 102, 224, 184;
  --x-green-400-rgb: 51, 214, 160;
  --x-green-500-rgb: 0, 204, 136;
  --x-green-600-rgb: 0, 163, 109;
  --x-green-700-rgb: 0, 122, 82;
  --x-green-800-rgb: 0, 82, 54;
  --x-green-900-rgb: 0, 41, 27;
  --x-teal-100-rgb: 211, 248, 248;
  --x-teal-200-rgb: 167, 241, 241;
  --x-teal-300-rgb: 122, 235, 235;
  --x-teal-400-rgb: 78, 228, 228;
  --x-teal-500-rgb: 34, 221, 221;
  --x-teal-600-rgb: 27, 177, 177;
  --x-teal-700-rgb: 20, 133, 133;
  --x-teal-800-rgb: 14, 88, 88;
  --x-teal-900-rgb: 7, 44, 44;
  --x-cyan-100-rgb: 204, 246, 255;
  --x-cyan-200-rgb: 153, 238, 255;
  --x-cyan-300-rgb: 102, 229, 255;
  --x-cyan-400-rgb: 51, 221, 255;
  --x-cyan-500-rgb: 0, 212, 255;
  --x-cyan-600-rgb: 0, 170, 204;
  --x-cyan-700-rgb: 0, 127, 153;
  --x-cyan-800-rgb: 0, 85, 102;
  --x-cyan-900-rgb: 0, 42, 51;
  --x-surface-primary-rgb: 255, 255, 255;
  --x-surface-secondary-rgb: 245, 249, 252;
  --x-surface-tertiary-rgb: 250, 250, 250;
  --x-surface-light-rgb: 231, 234, 240;
  --x-surface-dark-rgb: 20, 23, 41;
  --x-white-rgb: 255, 255, 255;
  --x-black-rgb: 0, 0, 0;
  --x-muted-rgb: 107, 123, 147;
  --x-body-rgb: 82, 95, 127;
  --x-heading-rgb: 22, 25, 44;
  --x-card-rgb: 255, 255, 255;
  --x-font-sans-serif: Inter, ui-sans-serif, system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  --x-font-monospace: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --x-font-display: Inter, ui-sans-serif, system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  --x-font-serif: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
  --x-gradient: linear-gradient(180deg, rgba(255, 255, 255, .15), rgba(255, 255, 255, 0));
  --x-body-font-family: var(--x-font-sans-serif);
  --x-body-font-size: 1rem;
  --x-body-font-weight: 400;
  --x-body-line-height: 1.625;
  --x-body-color: #525f7f;
  --x-body-bg: #fff;
  --x-border-width: 1px;
  --x-border-style: solid;
  --x-border-color: #e7eaf0;
  --x-border-color-translucent: rgba(0, 0, 0, .176);
  --x-border-radius: .375rem;
  --x-border-radius-sm: .25rem;
  --x-border-radius-lg: .5rem;
  --x-border-radius-xl: .75rem;
  --x-border-radius-2xl: 1rem;
  --x-border-radius-pill: 50rem;
  --x-link-color: #5c60f5;
  --x-link-hover-color: #4e52d0;
  --x-headings-color: #16192c;
  --x-code-color: #ff579a;
  --x-highlight-bg: #fcf8e3;
}

*, :before, :after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  font-family: var(--x-body-font-family);
  font-size: var(--x-body-font-size);
  font-weight: var(--x-body-font-weight);
  line-height: var(--x-body-line-height);
  color: var(--x-body-color);
  text-align: var(--x-body-text-align);
  background-color: var(--x-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  margin: 0;
}

hr {
  color: #e7eaf0;
  opacity: .9;
  border: 0;
  border-top: 1px solid;
  margin: 1rem 0;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  color: #16192c;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 600;
  line-height: 1.3;
}

h1, .h1 {
  font-size: calc(1.35rem + 1.2vw);
}

@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.25rem;
  }
}

h2, .h2 {
  font-size: calc(1.3rem + .6vw);
}

@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 1.75rem;
  }
}

h3, .h3 {
  font-size: calc(1.2625rem + .15vw);
}

@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.375rem;
  }
}

h4, .h4 {
  font-size: 1.125rem;
}

h5, .h5 {
  font-size: 1rem;
}

h6, .h6 {
  font-size: .875rem;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

abbr[title] {
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

ol, ul {
  padding-left: 2rem;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 600;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b, strong {
  font-weight: bolder;
}

small, .small {
  font-size: .875em;
}

mark, .mark {
  background-color: var(--x-highlight-bg);
  padding: 0;
}

sub, sup {
  vertical-align: baseline;
  font-size: .75em;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: var(--x-link-color);
  text-decoration: none;
}

a:hover {
  color: var(--x-link-hover-color);
  text-decoration: none;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre, code, kbd, samp {
  font-family: var(--x-font-monospace);
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: .875em;
  display: block;
  overflow: auto;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  color: var(--x-code-color);
  word-wrap: break-word;
  font-size: .875em;
}

a > code {
  color: inherit;
}

kbd {
  color: var(--x-body-bg);
  background-color: var(--x-body-color);
  border-radius: .25rem;
  padding: .1875rem .375rem;
  font-size: .875em;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 600;
}

figure {
  margin: 0 0 1rem;
}

img, svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  color: #6b7b93;
  text-align: left;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
  font-weight: 500;
}

thead, tbody, tfoot, tr, td, th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input, button, select, optgroup, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  float: left;
  width: 100%;
  font-size: calc(1.275rem + .3vw);
  line-height: inherit;
  margin-bottom: .5rem;
  padding: 0;
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper, ::-webkit-datetime-edit-text, ::-webkit-datetime-edit-minute, ::-webkit-datetime-edit-hour-field, ::-webkit-datetime-edit-day-field, ::-webkit-datetime-edit-month-field, ::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  cursor: pointer;
  display: list-item;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

*, :before, :after {
  border: 0 solid #e7eaf0;
}

iframe {
  vertical-align: middle;
  display: block;
}

figcaption, figure, main {
  margin: 0;
  display: block;
}

figure {
  max-width: 100%;
  min-height: 1px;
}

img, video {
  max-width: 100%;
  height: auto;
}

svg {
  vertical-align: middle;
  overflow: hidden;
}

a {
  outline: none;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  color: var(--x-headings-color);
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  border: 1px solid var(--x-border-color);
  max-width: 100%;
  height: auto;
  background-color: #fff;
  border-radius: .375rem;
  padding: .25rem;
  box-shadow: 0 1px 1px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06);
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: .5rem;
  line-height: 1;
}

.figure-caption {
  color: #6b7b93;
  font-size: .875em;
}

.container, .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm {
  --x-gutter-x: 1.5rem;
  --x-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--x-gutter-x) * .5);
  padding-left: calc(var(--x-gutter-x) * .5);
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1360px;
  }
}

.row {
  --x-gutter-x: 1.5rem;
  --x-gutter-y: 0;
  margin-top: calc(-1 * var(--x-gutter-y));
  margin-right: calc(-.5 * var(--x-gutter-x));
  margin-left: calc(-.5 * var(--x-gutter-x));
  flex-wrap: wrap;
  display: flex;
}

.row > * {
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--x-gutter-x) * .5);
  padding-left: calc(var(--x-gutter-x) * .5);
  margin-top: var(--x-gutter-y);
  flex-shrink: 0;
}

.grid {
  grid-template-rows: repeat(var(--x-rows, 1), 1fr);
  grid-template-columns: repeat(var(--x-columns, 12), 1fr);
  gap: var(--x-gap, 1.5rem);
  display: grid;
}

.grid .g-col-1 {
  grid-column: auto / span 1;
}

.grid .g-col-2 {
  grid-column: auto / span 2;
}

.grid .g-col-3 {
  grid-column: auto / span 3;
}

.grid .g-col-4 {
  grid-column: auto / span 4;
}

.grid .g-col-5 {
  grid-column: auto / span 5;
}

.grid .g-col-6 {
  grid-column: auto / span 6;
}

.grid .g-col-7 {
  grid-column: auto / span 7;
}

.grid .g-col-8 {
  grid-column: auto / span 8;
}

.grid .g-col-9 {
  grid-column: auto / span 9;
}

.grid .g-col-10 {
  grid-column: auto / span 10;
}

.grid .g-col-11 {
  grid-column: auto / span 11;
}

.grid .g-col-12 {
  grid-column: auto / span 12;
}

.grid .g-start-1 {
  grid-column-start: 1;
}

.grid .g-start-2 {
  grid-column-start: 2;
}

.grid .g-start-3 {
  grid-column-start: 3;
}

.grid .g-start-4 {
  grid-column-start: 4;
}

.grid .g-start-5 {
  grid-column-start: 5;
}

.grid .g-start-6 {
  grid-column-start: 6;
}

.grid .g-start-7 {
  grid-column-start: 7;
}

.grid .g-start-8 {
  grid-column-start: 8;
}

.grid .g-start-9 {
  grid-column-start: 9;
}

.grid .g-start-10 {
  grid-column-start: 10;
}

.grid .g-start-11 {
  grid-column-start: 11;
}

@media (min-width: 576px) {
  .grid .g-col-sm-1 {
    grid-column: auto / span 1;
  }

  .grid .g-col-sm-2 {
    grid-column: auto / span 2;
  }

  .grid .g-col-sm-3 {
    grid-column: auto / span 3;
  }

  .grid .g-col-sm-4 {
    grid-column: auto / span 4;
  }

  .grid .g-col-sm-5 {
    grid-column: auto / span 5;
  }

  .grid .g-col-sm-6 {
    grid-column: auto / span 6;
  }

  .grid .g-col-sm-7 {
    grid-column: auto / span 7;
  }

  .grid .g-col-sm-8 {
    grid-column: auto / span 8;
  }

  .grid .g-col-sm-9 {
    grid-column: auto / span 9;
  }

  .grid .g-col-sm-10 {
    grid-column: auto / span 10;
  }

  .grid .g-col-sm-11 {
    grid-column: auto / span 11;
  }

  .grid .g-col-sm-12 {
    grid-column: auto / span 12;
  }

  .grid .g-start-sm-1 {
    grid-column-start: 1;
  }

  .grid .g-start-sm-2 {
    grid-column-start: 2;
  }

  .grid .g-start-sm-3 {
    grid-column-start: 3;
  }

  .grid .g-start-sm-4 {
    grid-column-start: 4;
  }

  .grid .g-start-sm-5 {
    grid-column-start: 5;
  }

  .grid .g-start-sm-6 {
    grid-column-start: 6;
  }

  .grid .g-start-sm-7 {
    grid-column-start: 7;
  }

  .grid .g-start-sm-8 {
    grid-column-start: 8;
  }

  .grid .g-start-sm-9 {
    grid-column-start: 9;
  }

  .grid .g-start-sm-10 {
    grid-column-start: 10;
  }

  .grid .g-start-sm-11 {
    grid-column-start: 11;
  }
}

@media (min-width: 768px) {
  .grid .g-col-md-1 {
    grid-column: auto / span 1;
  }

  .grid .g-col-md-2 {
    grid-column: auto / span 2;
  }

  .grid .g-col-md-3 {
    grid-column: auto / span 3;
  }

  .grid .g-col-md-4 {
    grid-column: auto / span 4;
  }

  .grid .g-col-md-5 {
    grid-column: auto / span 5;
  }

  .grid .g-col-md-6 {
    grid-column: auto / span 6;
  }

  .grid .g-col-md-7 {
    grid-column: auto / span 7;
  }

  .grid .g-col-md-8 {
    grid-column: auto / span 8;
  }

  .grid .g-col-md-9 {
    grid-column: auto / span 9;
  }

  .grid .g-col-md-10 {
    grid-column: auto / span 10;
  }

  .grid .g-col-md-11 {
    grid-column: auto / span 11;
  }

  .grid .g-col-md-12 {
    grid-column: auto / span 12;
  }

  .grid .g-start-md-1 {
    grid-column-start: 1;
  }

  .grid .g-start-md-2 {
    grid-column-start: 2;
  }

  .grid .g-start-md-3 {
    grid-column-start: 3;
  }

  .grid .g-start-md-4 {
    grid-column-start: 4;
  }

  .grid .g-start-md-5 {
    grid-column-start: 5;
  }

  .grid .g-start-md-6 {
    grid-column-start: 6;
  }

  .grid .g-start-md-7 {
    grid-column-start: 7;
  }

  .grid .g-start-md-8 {
    grid-column-start: 8;
  }

  .grid .g-start-md-9 {
    grid-column-start: 9;
  }

  .grid .g-start-md-10 {
    grid-column-start: 10;
  }

  .grid .g-start-md-11 {
    grid-column-start: 11;
  }
}

@media (min-width: 992px) {
  .grid .g-col-lg-1 {
    grid-column: auto / span 1;
  }

  .grid .g-col-lg-2 {
    grid-column: auto / span 2;
  }

  .grid .g-col-lg-3 {
    grid-column: auto / span 3;
  }

  .grid .g-col-lg-4 {
    grid-column: auto / span 4;
  }

  .grid .g-col-lg-5 {
    grid-column: auto / span 5;
  }

  .grid .g-col-lg-6 {
    grid-column: auto / span 6;
  }

  .grid .g-col-lg-7 {
    grid-column: auto / span 7;
  }

  .grid .g-col-lg-8 {
    grid-column: auto / span 8;
  }

  .grid .g-col-lg-9 {
    grid-column: auto / span 9;
  }

  .grid .g-col-lg-10 {
    grid-column: auto / span 10;
  }

  .grid .g-col-lg-11 {
    grid-column: auto / span 11;
  }

  .grid .g-col-lg-12 {
    grid-column: auto / span 12;
  }

  .grid .g-start-lg-1 {
    grid-column-start: 1;
  }

  .grid .g-start-lg-2 {
    grid-column-start: 2;
  }

  .grid .g-start-lg-3 {
    grid-column-start: 3;
  }

  .grid .g-start-lg-4 {
    grid-column-start: 4;
  }

  .grid .g-start-lg-5 {
    grid-column-start: 5;
  }

  .grid .g-start-lg-6 {
    grid-column-start: 6;
  }

  .grid .g-start-lg-7 {
    grid-column-start: 7;
  }

  .grid .g-start-lg-8 {
    grid-column-start: 8;
  }

  .grid .g-start-lg-9 {
    grid-column-start: 9;
  }

  .grid .g-start-lg-10 {
    grid-column-start: 10;
  }

  .grid .g-start-lg-11 {
    grid-column-start: 11;
  }
}

@media (min-width: 1200px) {
  .grid .g-col-xl-1 {
    grid-column: auto / span 1;
  }

  .grid .g-col-xl-2 {
    grid-column: auto / span 2;
  }

  .grid .g-col-xl-3 {
    grid-column: auto / span 3;
  }

  .grid .g-col-xl-4 {
    grid-column: auto / span 4;
  }

  .grid .g-col-xl-5 {
    grid-column: auto / span 5;
  }

  .grid .g-col-xl-6 {
    grid-column: auto / span 6;
  }

  .grid .g-col-xl-7 {
    grid-column: auto / span 7;
  }

  .grid .g-col-xl-8 {
    grid-column: auto / span 8;
  }

  .grid .g-col-xl-9 {
    grid-column: auto / span 9;
  }

  .grid .g-col-xl-10 {
    grid-column: auto / span 10;
  }

  .grid .g-col-xl-11 {
    grid-column: auto / span 11;
  }

  .grid .g-col-xl-12 {
    grid-column: auto / span 12;
  }

  .grid .g-start-xl-1 {
    grid-column-start: 1;
  }

  .grid .g-start-xl-2 {
    grid-column-start: 2;
  }

  .grid .g-start-xl-3 {
    grid-column-start: 3;
  }

  .grid .g-start-xl-4 {
    grid-column-start: 4;
  }

  .grid .g-start-xl-5 {
    grid-column-start: 5;
  }

  .grid .g-start-xl-6 {
    grid-column-start: 6;
  }

  .grid .g-start-xl-7 {
    grid-column-start: 7;
  }

  .grid .g-start-xl-8 {
    grid-column-start: 8;
  }

  .grid .g-start-xl-9 {
    grid-column-start: 9;
  }

  .grid .g-start-xl-10 {
    grid-column-start: 10;
  }

  .grid .g-start-xl-11 {
    grid-column-start: 11;
  }
}

@media (min-width: 1400px) {
  .grid .g-col-xxl-1 {
    grid-column: auto / span 1;
  }

  .grid .g-col-xxl-2 {
    grid-column: auto / span 2;
  }

  .grid .g-col-xxl-3 {
    grid-column: auto / span 3;
  }

  .grid .g-col-xxl-4 {
    grid-column: auto / span 4;
  }

  .grid .g-col-xxl-5 {
    grid-column: auto / span 5;
  }

  .grid .g-col-xxl-6 {
    grid-column: auto / span 6;
  }

  .grid .g-col-xxl-7 {
    grid-column: auto / span 7;
  }

  .grid .g-col-xxl-8 {
    grid-column: auto / span 8;
  }

  .grid .g-col-xxl-9 {
    grid-column: auto / span 9;
  }

  .grid .g-col-xxl-10 {
    grid-column: auto / span 10;
  }

  .grid .g-col-xxl-11 {
    grid-column: auto / span 11;
  }

  .grid .g-col-xxl-12 {
    grid-column: auto / span 12;
  }

  .grid .g-start-xxl-1 {
    grid-column-start: 1;
  }

  .grid .g-start-xxl-2 {
    grid-column-start: 2;
  }

  .grid .g-start-xxl-3 {
    grid-column-start: 3;
  }

  .grid .g-start-xxl-4 {
    grid-column-start: 4;
  }

  .grid .g-start-xxl-5 {
    grid-column-start: 5;
  }

  .grid .g-start-xxl-6 {
    grid-column-start: 6;
  }

  .grid .g-start-xxl-7 {
    grid-column-start: 7;
  }

  .grid .g-start-xxl-8 {
    grid-column-start: 8;
  }

  .grid .g-start-xxl-9 {
    grid-column-start: 9;
  }

  .grid .g-start-xxl-10 {
    grid-column-start: 10;
  }

  .grid .g-start-xxl-11 {
    grid-column-start: 11;
  }
}

.col {
  flex: 1 0;
}

.row-cols-auto > * {
  width: auto;
  flex: none;
}

.row-cols-1 > * {
  width: 100%;
  flex: none;
}

.row-cols-2 > * {
  width: 50%;
  flex: none;
}

.row-cols-3 > * {
  width: 33.3333%;
  flex: none;
}

.row-cols-4 > * {
  width: 25%;
  flex: none;
}

.row-cols-5 > * {
  width: 20%;
  flex: none;
}

.row-cols-6 > * {
  width: 16.6667%;
  flex: none;
}

.col-auto {
  width: auto;
  flex: none;
}

.col-1 {
  width: 8.33333%;
  flex: none;
}

.col-2 {
  width: 16.6667%;
  flex: none;
}

.col-3 {
  width: 25%;
  flex: none;
}

.col-4 {
  width: 33.3333%;
  flex: none;
}

.col-5 {
  width: 41.6667%;
  flex: none;
}

.col-6 {
  width: 50%;
  flex: none;
}

.col-7 {
  width: 58.3333%;
  flex: none;
}

.col-8 {
  width: 66.6667%;
  flex: none;
}

.col-9 {
  width: 75%;
  flex: none;
}

.col-10 {
  width: 83.3333%;
  flex: none;
}

.col-11 {
  width: 91.6667%;
  flex: none;
}

.col-12 {
  width: 100%;
  flex: none;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.6667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333%;
}

.offset-5 {
  margin-left: 41.6667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333%;
}

.offset-8 {
  margin-left: 66.6667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333%;
}

.offset-11 {
  margin-left: 91.6667%;
}

.g-0, .gx-0 {
  --x-gutter-x: 0;
}

.g-0, .gy-0 {
  --x-gutter-y: 0;
}

.g-1, .gx-1 {
  --x-gutter-x: .25rem;
}

.g-1, .gy-1 {
  --x-gutter-y: .25rem;
}

.g-2, .gx-2 {
  --x-gutter-x: .5rem;
}

.g-2, .gy-2 {
  --x-gutter-y: .5rem;
}

.g-3, .gx-3 {
  --x-gutter-x: .75rem;
}

.g-3, .gy-3 {
  --x-gutter-y: .75rem;
}

.g-4, .gx-4 {
  --x-gutter-x: 1rem;
}

.g-4, .gy-4 {
  --x-gutter-y: 1rem;
}

.g-5, .gx-5 {
  --x-gutter-x: 1.25rem;
}

.g-5, .gy-5 {
  --x-gutter-y: 1.25rem;
}

.g-6, .gx-6 {
  --x-gutter-x: 1.5rem;
}

.g-6, .gy-6 {
  --x-gutter-y: 1.5rem;
}

.g-7, .gx-7 {
  --x-gutter-x: 1.75rem;
}

.g-7, .gy-7 {
  --x-gutter-y: 1.75rem;
}

.g-8, .gx-8 {
  --x-gutter-x: 2rem;
}

.g-8, .gy-8 {
  --x-gutter-y: 2rem;
}

.g-10, .gx-10 {
  --x-gutter-x: 2.5rem;
}

.g-10, .gy-10 {
  --x-gutter-y: 2.5rem;
}

.g-12, .gx-12 {
  --x-gutter-x: 3rem;
}

.g-12, .gy-12 {
  --x-gutter-y: 3rem;
}

.g-14, .gx-14 {
  --x-gutter-x: 3.5rem;
}

.g-14, .gy-14 {
  --x-gutter-y: 3.5rem;
}

.g-16, .gx-16 {
  --x-gutter-x: 4rem;
}

.g-16, .gy-16 {
  --x-gutter-y: 4rem;
}

.g-18, .gx-18 {
  --x-gutter-x: 4.5rem;
}

.g-18, .gy-18 {
  --x-gutter-y: 4.5rem;
}

.g-20, .gx-20 {
  --x-gutter-x: 5rem;
}

.g-20, .gy-20 {
  --x-gutter-y: 5rem;
}

.g-24, .gx-24 {
  --x-gutter-x: 6rem;
}

.g-24, .gy-24 {
  --x-gutter-y: 6rem;
}

.g-32, .gx-32 {
  --x-gutter-x: 8rem;
}

.g-32, .gy-32 {
  --x-gutter-y: 8rem;
}

.g-40, .gx-40 {
  --x-gutter-x: 10rem;
}

.g-40, .gy-40 {
  --x-gutter-y: 10rem;
}

.g-48, .gx-48 {
  --x-gutter-x: 12rem;
}

.g-48, .gy-48 {
  --x-gutter-y: 12rem;
}

.g-56, .gx-56 {
  --x-gutter-x: 14rem;
}

.g-56, .gy-56 {
  --x-gutter-y: 14rem;
}

.g-64, .gx-64 {
  --x-gutter-x: 16rem;
}

.g-64, .gy-64 {
  --x-gutter-y: 16rem;
}

.g-72, .gx-72 {
  --x-gutter-x: 18rem;
}

.g-72, .gy-72 {
  --x-gutter-y: 18rem;
}

.g-80, .gx-80 {
  --x-gutter-x: 20rem;
}

.g-80, .gy-80 {
  --x-gutter-y: 20rem;
}

.g-88, .gx-88 {
  --x-gutter-x: 22rem;
}

.g-88, .gy-88 {
  --x-gutter-y: 22rem;
}

.g-96, .gx-96 {
  --x-gutter-x: 24rem;
}

.g-96, .gy-96 {
  --x-gutter-y: 24rem;
}

.g-px, .gx-px {
  --x-gutter-x: 1px;
}

.g-px, .gy-px {
  --x-gutter-y: 1px;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0;
  }

  .row-cols-sm-auto > * {
    width: auto;
    flex: none;
  }

  .row-cols-sm-1 > * {
    width: 100%;
    flex: none;
  }

  .row-cols-sm-2 > * {
    width: 50%;
    flex: none;
  }

  .row-cols-sm-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .row-cols-sm-4 > * {
    width: 25%;
    flex: none;
  }

  .row-cols-sm-5 > * {
    width: 20%;
    flex: none;
  }

  .row-cols-sm-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .col-sm-auto {
    width: auto;
    flex: none;
  }

  .col-sm-1 {
    width: 8.33333%;
    flex: none;
  }

  .col-sm-2 {
    width: 16.6667%;
    flex: none;
  }

  .col-sm-3 {
    width: 25%;
    flex: none;
  }

  .col-sm-4 {
    width: 33.3333%;
    flex: none;
  }

  .col-sm-5 {
    width: 41.6667%;
    flex: none;
  }

  .col-sm-6 {
    width: 50%;
    flex: none;
  }

  .col-sm-7 {
    width: 58.3333%;
    flex: none;
  }

  .col-sm-8 {
    width: 66.6667%;
    flex: none;
  }

  .col-sm-9 {
    width: 75%;
    flex: none;
  }

  .col-sm-10 {
    width: 83.3333%;
    flex: none;
  }

  .col-sm-11 {
    width: 91.6667%;
    flex: none;
  }

  .col-sm-12 {
    width: 100%;
    flex: none;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.6667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333%;
  }

  .offset-sm-5 {
    margin-left: 41.6667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333%;
  }

  .offset-sm-8 {
    margin-left: 66.6667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333%;
  }

  .offset-sm-11 {
    margin-left: 91.6667%;
  }

  .g-sm-0, .gx-sm-0 {
    --x-gutter-x: 0;
  }

  .g-sm-0, .gy-sm-0 {
    --x-gutter-y: 0;
  }

  .g-sm-1, .gx-sm-1 {
    --x-gutter-x: .25rem;
  }

  .g-sm-1, .gy-sm-1 {
    --x-gutter-y: .25rem;
  }

  .g-sm-2, .gx-sm-2 {
    --x-gutter-x: .5rem;
  }

  .g-sm-2, .gy-sm-2 {
    --x-gutter-y: .5rem;
  }

  .g-sm-3, .gx-sm-3 {
    --x-gutter-x: .75rem;
  }

  .g-sm-3, .gy-sm-3 {
    --x-gutter-y: .75rem;
  }

  .g-sm-4, .gx-sm-4 {
    --x-gutter-x: 1rem;
  }

  .g-sm-4, .gy-sm-4 {
    --x-gutter-y: 1rem;
  }

  .g-sm-5, .gx-sm-5 {
    --x-gutter-x: 1.25rem;
  }

  .g-sm-5, .gy-sm-5 {
    --x-gutter-y: 1.25rem;
  }

  .g-sm-6, .gx-sm-6 {
    --x-gutter-x: 1.5rem;
  }

  .g-sm-6, .gy-sm-6 {
    --x-gutter-y: 1.5rem;
  }

  .g-sm-7, .gx-sm-7 {
    --x-gutter-x: 1.75rem;
  }

  .g-sm-7, .gy-sm-7 {
    --x-gutter-y: 1.75rem;
  }

  .g-sm-8, .gx-sm-8 {
    --x-gutter-x: 2rem;
  }

  .g-sm-8, .gy-sm-8 {
    --x-gutter-y: 2rem;
  }

  .g-sm-10, .gx-sm-10 {
    --x-gutter-x: 2.5rem;
  }

  .g-sm-10, .gy-sm-10 {
    --x-gutter-y: 2.5rem;
  }

  .g-sm-12, .gx-sm-12 {
    --x-gutter-x: 3rem;
  }

  .g-sm-12, .gy-sm-12 {
    --x-gutter-y: 3rem;
  }

  .g-sm-14, .gx-sm-14 {
    --x-gutter-x: 3.5rem;
  }

  .g-sm-14, .gy-sm-14 {
    --x-gutter-y: 3.5rem;
  }

  .g-sm-16, .gx-sm-16 {
    --x-gutter-x: 4rem;
  }

  .g-sm-16, .gy-sm-16 {
    --x-gutter-y: 4rem;
  }

  .g-sm-18, .gx-sm-18 {
    --x-gutter-x: 4.5rem;
  }

  .g-sm-18, .gy-sm-18 {
    --x-gutter-y: 4.5rem;
  }

  .g-sm-20, .gx-sm-20 {
    --x-gutter-x: 5rem;
  }

  .g-sm-20, .gy-sm-20 {
    --x-gutter-y: 5rem;
  }

  .g-sm-24, .gx-sm-24 {
    --x-gutter-x: 6rem;
  }

  .g-sm-24, .gy-sm-24 {
    --x-gutter-y: 6rem;
  }

  .g-sm-32, .gx-sm-32 {
    --x-gutter-x: 8rem;
  }

  .g-sm-32, .gy-sm-32 {
    --x-gutter-y: 8rem;
  }

  .g-sm-40, .gx-sm-40 {
    --x-gutter-x: 10rem;
  }

  .g-sm-40, .gy-sm-40 {
    --x-gutter-y: 10rem;
  }

  .g-sm-48, .gx-sm-48 {
    --x-gutter-x: 12rem;
  }

  .g-sm-48, .gy-sm-48 {
    --x-gutter-y: 12rem;
  }

  .g-sm-56, .gx-sm-56 {
    --x-gutter-x: 14rem;
  }

  .g-sm-56, .gy-sm-56 {
    --x-gutter-y: 14rem;
  }

  .g-sm-64, .gx-sm-64 {
    --x-gutter-x: 16rem;
  }

  .g-sm-64, .gy-sm-64 {
    --x-gutter-y: 16rem;
  }

  .g-sm-72, .gx-sm-72 {
    --x-gutter-x: 18rem;
  }

  .g-sm-72, .gy-sm-72 {
    --x-gutter-y: 18rem;
  }

  .g-sm-80, .gx-sm-80 {
    --x-gutter-x: 20rem;
  }

  .g-sm-80, .gy-sm-80 {
    --x-gutter-y: 20rem;
  }

  .g-sm-88, .gx-sm-88 {
    --x-gutter-x: 22rem;
  }

  .g-sm-88, .gy-sm-88 {
    --x-gutter-y: 22rem;
  }

  .g-sm-96, .gx-sm-96 {
    --x-gutter-x: 24rem;
  }

  .g-sm-96, .gy-sm-96 {
    --x-gutter-y: 24rem;
  }

  .g-sm-px, .gx-sm-px {
    --x-gutter-x: 1px;
  }

  .g-sm-px, .gy-sm-px {
    --x-gutter-y: 1px;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex: 1 0;
  }

  .row-cols-md-auto > * {
    width: auto;
    flex: none;
  }

  .row-cols-md-1 > * {
    width: 100%;
    flex: none;
  }

  .row-cols-md-2 > * {
    width: 50%;
    flex: none;
  }

  .row-cols-md-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .row-cols-md-4 > * {
    width: 25%;
    flex: none;
  }

  .row-cols-md-5 > * {
    width: 20%;
    flex: none;
  }

  .row-cols-md-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .col-md-auto {
    width: auto;
    flex: none;
  }

  .col-md-1 {
    width: 8.33333%;
    flex: none;
  }

  .col-md-2 {
    width: 16.6667%;
    flex: none;
  }

  .col-md-3 {
    width: 25%;
    flex: none;
  }

  .col-md-4 {
    width: 33.3333%;
    flex: none;
  }

  .col-md-5 {
    width: 41.6667%;
    flex: none;
  }

  .col-md-6 {
    width: 50%;
    flex: none;
  }

  .col-md-7 {
    width: 58.3333%;
    flex: none;
  }

  .col-md-8 {
    width: 66.6667%;
    flex: none;
  }

  .col-md-9 {
    width: 75%;
    flex: none;
  }

  .col-md-10 {
    width: 83.3333%;
    flex: none;
  }

  .col-md-11 {
    width: 91.6667%;
    flex: none;
  }

  .col-md-12 {
    width: 100%;
    flex: none;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.6667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333%;
  }

  .offset-md-5 {
    margin-left: 41.6667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333%;
  }

  .offset-md-8 {
    margin-left: 66.6667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333%;
  }

  .offset-md-11 {
    margin-left: 91.6667%;
  }

  .g-md-0, .gx-md-0 {
    --x-gutter-x: 0;
  }

  .g-md-0, .gy-md-0 {
    --x-gutter-y: 0;
  }

  .g-md-1, .gx-md-1 {
    --x-gutter-x: .25rem;
  }

  .g-md-1, .gy-md-1 {
    --x-gutter-y: .25rem;
  }

  .g-md-2, .gx-md-2 {
    --x-gutter-x: .5rem;
  }

  .g-md-2, .gy-md-2 {
    --x-gutter-y: .5rem;
  }

  .g-md-3, .gx-md-3 {
    --x-gutter-x: .75rem;
  }

  .g-md-3, .gy-md-3 {
    --x-gutter-y: .75rem;
  }

  .g-md-4, .gx-md-4 {
    --x-gutter-x: 1rem;
  }

  .g-md-4, .gy-md-4 {
    --x-gutter-y: 1rem;
  }

  .g-md-5, .gx-md-5 {
    --x-gutter-x: 1.25rem;
  }

  .g-md-5, .gy-md-5 {
    --x-gutter-y: 1.25rem;
  }

  .g-md-6, .gx-md-6 {
    --x-gutter-x: 1.5rem;
  }

  .g-md-6, .gy-md-6 {
    --x-gutter-y: 1.5rem;
  }

  .g-md-7, .gx-md-7 {
    --x-gutter-x: 1.75rem;
  }

  .g-md-7, .gy-md-7 {
    --x-gutter-y: 1.75rem;
  }

  .g-md-8, .gx-md-8 {
    --x-gutter-x: 2rem;
  }

  .g-md-8, .gy-md-8 {
    --x-gutter-y: 2rem;
  }

  .g-md-10, .gx-md-10 {
    --x-gutter-x: 2.5rem;
  }

  .g-md-10, .gy-md-10 {
    --x-gutter-y: 2.5rem;
  }

  .g-md-12, .gx-md-12 {
    --x-gutter-x: 3rem;
  }

  .g-md-12, .gy-md-12 {
    --x-gutter-y: 3rem;
  }

  .g-md-14, .gx-md-14 {
    --x-gutter-x: 3.5rem;
  }

  .g-md-14, .gy-md-14 {
    --x-gutter-y: 3.5rem;
  }

  .g-md-16, .gx-md-16 {
    --x-gutter-x: 4rem;
  }

  .g-md-16, .gy-md-16 {
    --x-gutter-y: 4rem;
  }

  .g-md-18, .gx-md-18 {
    --x-gutter-x: 4.5rem;
  }

  .g-md-18, .gy-md-18 {
    --x-gutter-y: 4.5rem;
  }

  .g-md-20, .gx-md-20 {
    --x-gutter-x: 5rem;
  }

  .g-md-20, .gy-md-20 {
    --x-gutter-y: 5rem;
  }

  .g-md-24, .gx-md-24 {
    --x-gutter-x: 6rem;
  }

  .g-md-24, .gy-md-24 {
    --x-gutter-y: 6rem;
  }

  .g-md-32, .gx-md-32 {
    --x-gutter-x: 8rem;
  }

  .g-md-32, .gy-md-32 {
    --x-gutter-y: 8rem;
  }

  .g-md-40, .gx-md-40 {
    --x-gutter-x: 10rem;
  }

  .g-md-40, .gy-md-40 {
    --x-gutter-y: 10rem;
  }

  .g-md-48, .gx-md-48 {
    --x-gutter-x: 12rem;
  }

  .g-md-48, .gy-md-48 {
    --x-gutter-y: 12rem;
  }

  .g-md-56, .gx-md-56 {
    --x-gutter-x: 14rem;
  }

  .g-md-56, .gy-md-56 {
    --x-gutter-y: 14rem;
  }

  .g-md-64, .gx-md-64 {
    --x-gutter-x: 16rem;
  }

  .g-md-64, .gy-md-64 {
    --x-gutter-y: 16rem;
  }

  .g-md-72, .gx-md-72 {
    --x-gutter-x: 18rem;
  }

  .g-md-72, .gy-md-72 {
    --x-gutter-y: 18rem;
  }

  .g-md-80, .gx-md-80 {
    --x-gutter-x: 20rem;
  }

  .g-md-80, .gy-md-80 {
    --x-gutter-y: 20rem;
  }

  .g-md-88, .gx-md-88 {
    --x-gutter-x: 22rem;
  }

  .g-md-88, .gy-md-88 {
    --x-gutter-y: 22rem;
  }

  .g-md-96, .gx-md-96 {
    --x-gutter-x: 24rem;
  }

  .g-md-96, .gy-md-96 {
    --x-gutter-y: 24rem;
  }

  .g-md-px, .gx-md-px {
    --x-gutter-x: 1px;
  }

  .g-md-px, .gy-md-px {
    --x-gutter-y: 1px;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0;
  }

  .row-cols-lg-auto > * {
    width: auto;
    flex: none;
  }

  .row-cols-lg-1 > * {
    width: 100%;
    flex: none;
  }

  .row-cols-lg-2 > * {
    width: 50%;
    flex: none;
  }

  .row-cols-lg-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .row-cols-lg-4 > * {
    width: 25%;
    flex: none;
  }

  .row-cols-lg-5 > * {
    width: 20%;
    flex: none;
  }

  .row-cols-lg-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .col-lg-auto {
    width: auto;
    flex: none;
  }

  .col-lg-1 {
    width: 8.33333%;
    flex: none;
  }

  .col-lg-2 {
    width: 16.6667%;
    flex: none;
  }

  .col-lg-3 {
    width: 25%;
    flex: none;
  }

  .col-lg-4 {
    width: 33.3333%;
    flex: none;
  }

  .col-lg-5 {
    width: 41.6667%;
    flex: none;
  }

  .col-lg-6 {
    width: 50%;
    flex: none;
  }

  .col-lg-7 {
    width: 58.3333%;
    flex: none;
  }

  .col-lg-8 {
    width: 66.6667%;
    flex: none;
  }

  .col-lg-9 {
    width: 75%;
    flex: none;
  }

  .col-lg-10 {
    width: 83.3333%;
    flex: none;
  }

  .col-lg-11 {
    width: 91.6667%;
    flex: none;
  }

  .col-lg-12 {
    width: 100%;
    flex: none;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.6667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333%;
  }

  .offset-lg-5 {
    margin-left: 41.6667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333%;
  }

  .offset-lg-8 {
    margin-left: 66.6667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333%;
  }

  .offset-lg-11 {
    margin-left: 91.6667%;
  }

  .g-lg-0, .gx-lg-0 {
    --x-gutter-x: 0;
  }

  .g-lg-0, .gy-lg-0 {
    --x-gutter-y: 0;
  }

  .g-lg-1, .gx-lg-1 {
    --x-gutter-x: .25rem;
  }

  .g-lg-1, .gy-lg-1 {
    --x-gutter-y: .25rem;
  }

  .g-lg-2, .gx-lg-2 {
    --x-gutter-x: .5rem;
  }

  .g-lg-2, .gy-lg-2 {
    --x-gutter-y: .5rem;
  }

  .g-lg-3, .gx-lg-3 {
    --x-gutter-x: .75rem;
  }

  .g-lg-3, .gy-lg-3 {
    --x-gutter-y: .75rem;
  }

  .g-lg-4, .gx-lg-4 {
    --x-gutter-x: 1rem;
  }

  .g-lg-4, .gy-lg-4 {
    --x-gutter-y: 1rem;
  }

  .g-lg-5, .gx-lg-5 {
    --x-gutter-x: 1.25rem;
  }

  .g-lg-5, .gy-lg-5 {
    --x-gutter-y: 1.25rem;
  }

  .g-lg-6, .gx-lg-6 {
    --x-gutter-x: 1.5rem;
  }

  .g-lg-6, .gy-lg-6 {
    --x-gutter-y: 1.5rem;
  }

  .g-lg-7, .gx-lg-7 {
    --x-gutter-x: 1.75rem;
  }

  .g-lg-7, .gy-lg-7 {
    --x-gutter-y: 1.75rem;
  }

  .g-lg-8, .gx-lg-8 {
    --x-gutter-x: 2rem;
  }

  .g-lg-8, .gy-lg-8 {
    --x-gutter-y: 2rem;
  }

  .g-lg-10, .gx-lg-10 {
    --x-gutter-x: 2.5rem;
  }

  .g-lg-10, .gy-lg-10 {
    --x-gutter-y: 2.5rem;
  }

  .g-lg-12, .gx-lg-12 {
    --x-gutter-x: 3rem;
  }

  .g-lg-12, .gy-lg-12 {
    --x-gutter-y: 3rem;
  }

  .g-lg-14, .gx-lg-14 {
    --x-gutter-x: 3.5rem;
  }

  .g-lg-14, .gy-lg-14 {
    --x-gutter-y: 3.5rem;
  }

  .g-lg-16, .gx-lg-16 {
    --x-gutter-x: 4rem;
  }

  .g-lg-16, .gy-lg-16 {
    --x-gutter-y: 4rem;
  }

  .g-lg-18, .gx-lg-18 {
    --x-gutter-x: 4.5rem;
  }

  .g-lg-18, .gy-lg-18 {
    --x-gutter-y: 4.5rem;
  }

  .g-lg-20, .gx-lg-20 {
    --x-gutter-x: 5rem;
  }

  .g-lg-20, .gy-lg-20 {
    --x-gutter-y: 5rem;
  }

  .g-lg-24, .gx-lg-24 {
    --x-gutter-x: 6rem;
  }

  .g-lg-24, .gy-lg-24 {
    --x-gutter-y: 6rem;
  }

  .g-lg-32, .gx-lg-32 {
    --x-gutter-x: 8rem;
  }

  .g-lg-32, .gy-lg-32 {
    --x-gutter-y: 8rem;
  }

  .g-lg-40, .gx-lg-40 {
    --x-gutter-x: 10rem;
  }

  .g-lg-40, .gy-lg-40 {
    --x-gutter-y: 10rem;
  }

  .g-lg-48, .gx-lg-48 {
    --x-gutter-x: 12rem;
  }

  .g-lg-48, .gy-lg-48 {
    --x-gutter-y: 12rem;
  }

  .g-lg-56, .gx-lg-56 {
    --x-gutter-x: 14rem;
  }

  .g-lg-56, .gy-lg-56 {
    --x-gutter-y: 14rem;
  }

  .g-lg-64, .gx-lg-64 {
    --x-gutter-x: 16rem;
  }

  .g-lg-64, .gy-lg-64 {
    --x-gutter-y: 16rem;
  }

  .g-lg-72, .gx-lg-72 {
    --x-gutter-x: 18rem;
  }

  .g-lg-72, .gy-lg-72 {
    --x-gutter-y: 18rem;
  }

  .g-lg-80, .gx-lg-80 {
    --x-gutter-x: 20rem;
  }

  .g-lg-80, .gy-lg-80 {
    --x-gutter-y: 20rem;
  }

  .g-lg-88, .gx-lg-88 {
    --x-gutter-x: 22rem;
  }

  .g-lg-88, .gy-lg-88 {
    --x-gutter-y: 22rem;
  }

  .g-lg-96, .gx-lg-96 {
    --x-gutter-x: 24rem;
  }

  .g-lg-96, .gy-lg-96 {
    --x-gutter-y: 24rem;
  }

  .g-lg-px, .gx-lg-px {
    --x-gutter-x: 1px;
  }

  .g-lg-px, .gy-lg-px {
    --x-gutter-y: 1px;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0;
  }

  .row-cols-xl-auto > * {
    width: auto;
    flex: none;
  }

  .row-cols-xl-1 > * {
    width: 100%;
    flex: none;
  }

  .row-cols-xl-2 > * {
    width: 50%;
    flex: none;
  }

  .row-cols-xl-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .row-cols-xl-4 > * {
    width: 25%;
    flex: none;
  }

  .row-cols-xl-5 > * {
    width: 20%;
    flex: none;
  }

  .row-cols-xl-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .col-xl-auto {
    width: auto;
    flex: none;
  }

  .col-xl-1 {
    width: 8.33333%;
    flex: none;
  }

  .col-xl-2 {
    width: 16.6667%;
    flex: none;
  }

  .col-xl-3 {
    width: 25%;
    flex: none;
  }

  .col-xl-4 {
    width: 33.3333%;
    flex: none;
  }

  .col-xl-5 {
    width: 41.6667%;
    flex: none;
  }

  .col-xl-6 {
    width: 50%;
    flex: none;
  }

  .col-xl-7 {
    width: 58.3333%;
    flex: none;
  }

  .col-xl-8 {
    width: 66.6667%;
    flex: none;
  }

  .col-xl-9 {
    width: 75%;
    flex: none;
  }

  .col-xl-10 {
    width: 83.3333%;
    flex: none;
  }

  .col-xl-11 {
    width: 91.6667%;
    flex: none;
  }

  .col-xl-12 {
    width: 100%;
    flex: none;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.6667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333%;
  }

  .offset-xl-5 {
    margin-left: 41.6667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333%;
  }

  .offset-xl-8 {
    margin-left: 66.6667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333%;
  }

  .offset-xl-11 {
    margin-left: 91.6667%;
  }

  .g-xl-0, .gx-xl-0 {
    --x-gutter-x: 0;
  }

  .g-xl-0, .gy-xl-0 {
    --x-gutter-y: 0;
  }

  .g-xl-1, .gx-xl-1 {
    --x-gutter-x: .25rem;
  }

  .g-xl-1, .gy-xl-1 {
    --x-gutter-y: .25rem;
  }

  .g-xl-2, .gx-xl-2 {
    --x-gutter-x: .5rem;
  }

  .g-xl-2, .gy-xl-2 {
    --x-gutter-y: .5rem;
  }

  .g-xl-3, .gx-xl-3 {
    --x-gutter-x: .75rem;
  }

  .g-xl-3, .gy-xl-3 {
    --x-gutter-y: .75rem;
  }

  .g-xl-4, .gx-xl-4 {
    --x-gutter-x: 1rem;
  }

  .g-xl-4, .gy-xl-4 {
    --x-gutter-y: 1rem;
  }

  .g-xl-5, .gx-xl-5 {
    --x-gutter-x: 1.25rem;
  }

  .g-xl-5, .gy-xl-5 {
    --x-gutter-y: 1.25rem;
  }

  .g-xl-6, .gx-xl-6 {
    --x-gutter-x: 1.5rem;
  }

  .g-xl-6, .gy-xl-6 {
    --x-gutter-y: 1.5rem;
  }

  .g-xl-7, .gx-xl-7 {
    --x-gutter-x: 1.75rem;
  }

  .g-xl-7, .gy-xl-7 {
    --x-gutter-y: 1.75rem;
  }

  .g-xl-8, .gx-xl-8 {
    --x-gutter-x: 2rem;
  }

  .g-xl-8, .gy-xl-8 {
    --x-gutter-y: 2rem;
  }

  .g-xl-10, .gx-xl-10 {
    --x-gutter-x: 2.5rem;
  }

  .g-xl-10, .gy-xl-10 {
    --x-gutter-y: 2.5rem;
  }

  .g-xl-12, .gx-xl-12 {
    --x-gutter-x: 3rem;
  }

  .g-xl-12, .gy-xl-12 {
    --x-gutter-y: 3rem;
  }

  .g-xl-14, .gx-xl-14 {
    --x-gutter-x: 3.5rem;
  }

  .g-xl-14, .gy-xl-14 {
    --x-gutter-y: 3.5rem;
  }

  .g-xl-16, .gx-xl-16 {
    --x-gutter-x: 4rem;
  }

  .g-xl-16, .gy-xl-16 {
    --x-gutter-y: 4rem;
  }

  .g-xl-18, .gx-xl-18 {
    --x-gutter-x: 4.5rem;
  }

  .g-xl-18, .gy-xl-18 {
    --x-gutter-y: 4.5rem;
  }

  .g-xl-20, .gx-xl-20 {
    --x-gutter-x: 5rem;
  }

  .g-xl-20, .gy-xl-20 {
    --x-gutter-y: 5rem;
  }

  .g-xl-24, .gx-xl-24 {
    --x-gutter-x: 6rem;
  }

  .g-xl-24, .gy-xl-24 {
    --x-gutter-y: 6rem;
  }

  .g-xl-32, .gx-xl-32 {
    --x-gutter-x: 8rem;
  }

  .g-xl-32, .gy-xl-32 {
    --x-gutter-y: 8rem;
  }

  .g-xl-40, .gx-xl-40 {
    --x-gutter-x: 10rem;
  }

  .g-xl-40, .gy-xl-40 {
    --x-gutter-y: 10rem;
  }

  .g-xl-48, .gx-xl-48 {
    --x-gutter-x: 12rem;
  }

  .g-xl-48, .gy-xl-48 {
    --x-gutter-y: 12rem;
  }

  .g-xl-56, .gx-xl-56 {
    --x-gutter-x: 14rem;
  }

  .g-xl-56, .gy-xl-56 {
    --x-gutter-y: 14rem;
  }

  .g-xl-64, .gx-xl-64 {
    --x-gutter-x: 16rem;
  }

  .g-xl-64, .gy-xl-64 {
    --x-gutter-y: 16rem;
  }

  .g-xl-72, .gx-xl-72 {
    --x-gutter-x: 18rem;
  }

  .g-xl-72, .gy-xl-72 {
    --x-gutter-y: 18rem;
  }

  .g-xl-80, .gx-xl-80 {
    --x-gutter-x: 20rem;
  }

  .g-xl-80, .gy-xl-80 {
    --x-gutter-y: 20rem;
  }

  .g-xl-88, .gx-xl-88 {
    --x-gutter-x: 22rem;
  }

  .g-xl-88, .gy-xl-88 {
    --x-gutter-y: 22rem;
  }

  .g-xl-96, .gx-xl-96 {
    --x-gutter-x: 24rem;
  }

  .g-xl-96, .gy-xl-96 {
    --x-gutter-y: 24rem;
  }

  .g-xl-px, .gx-xl-px {
    --x-gutter-x: 1px;
  }

  .g-xl-px, .gy-xl-px {
    --x-gutter-y: 1px;
  }
}

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0;
  }

  .row-cols-xxl-auto > * {
    width: auto;
    flex: none;
  }

  .row-cols-xxl-1 > * {
    width: 100%;
    flex: none;
  }

  .row-cols-xxl-2 > * {
    width: 50%;
    flex: none;
  }

  .row-cols-xxl-3 > * {
    width: 33.3333%;
    flex: none;
  }

  .row-cols-xxl-4 > * {
    width: 25%;
    flex: none;
  }

  .row-cols-xxl-5 > * {
    width: 20%;
    flex: none;
  }

  .row-cols-xxl-6 > * {
    width: 16.6667%;
    flex: none;
  }

  .col-xxl-auto {
    width: auto;
    flex: none;
  }

  .col-xxl-1 {
    width: 8.33333%;
    flex: none;
  }

  .col-xxl-2 {
    width: 16.6667%;
    flex: none;
  }

  .col-xxl-3 {
    width: 25%;
    flex: none;
  }

  .col-xxl-4 {
    width: 33.3333%;
    flex: none;
  }

  .col-xxl-5 {
    width: 41.6667%;
    flex: none;
  }

  .col-xxl-6 {
    width: 50%;
    flex: none;
  }

  .col-xxl-7 {
    width: 58.3333%;
    flex: none;
  }

  .col-xxl-8 {
    width: 66.6667%;
    flex: none;
  }

  .col-xxl-9 {
    width: 75%;
    flex: none;
  }

  .col-xxl-10 {
    width: 83.3333%;
    flex: none;
  }

  .col-xxl-11 {
    width: 91.6667%;
    flex: none;
  }

  .col-xxl-12 {
    width: 100%;
    flex: none;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333%;
  }

  .offset-xxl-2 {
    margin-left: 16.6667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.3333%;
  }

  .offset-xxl-5 {
    margin-left: 41.6667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.3333%;
  }

  .offset-xxl-8 {
    margin-left: 66.6667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.3333%;
  }

  .offset-xxl-11 {
    margin-left: 91.6667%;
  }

  .g-xxl-0, .gx-xxl-0 {
    --x-gutter-x: 0;
  }

  .g-xxl-0, .gy-xxl-0 {
    --x-gutter-y: 0;
  }

  .g-xxl-1, .gx-xxl-1 {
    --x-gutter-x: .25rem;
  }

  .g-xxl-1, .gy-xxl-1 {
    --x-gutter-y: .25rem;
  }

  .g-xxl-2, .gx-xxl-2 {
    --x-gutter-x: .5rem;
  }

  .g-xxl-2, .gy-xxl-2 {
    --x-gutter-y: .5rem;
  }

  .g-xxl-3, .gx-xxl-3 {
    --x-gutter-x: .75rem;
  }

  .g-xxl-3, .gy-xxl-3 {
    --x-gutter-y: .75rem;
  }

  .g-xxl-4, .gx-xxl-4 {
    --x-gutter-x: 1rem;
  }

  .g-xxl-4, .gy-xxl-4 {
    --x-gutter-y: 1rem;
  }

  .g-xxl-5, .gx-xxl-5 {
    --x-gutter-x: 1.25rem;
  }

  .g-xxl-5, .gy-xxl-5 {
    --x-gutter-y: 1.25rem;
  }

  .g-xxl-6, .gx-xxl-6 {
    --x-gutter-x: 1.5rem;
  }

  .g-xxl-6, .gy-xxl-6 {
    --x-gutter-y: 1.5rem;
  }

  .g-xxl-7, .gx-xxl-7 {
    --x-gutter-x: 1.75rem;
  }

  .g-xxl-7, .gy-xxl-7 {
    --x-gutter-y: 1.75rem;
  }

  .g-xxl-8, .gx-xxl-8 {
    --x-gutter-x: 2rem;
  }

  .g-xxl-8, .gy-xxl-8 {
    --x-gutter-y: 2rem;
  }

  .g-xxl-10, .gx-xxl-10 {
    --x-gutter-x: 2.5rem;
  }

  .g-xxl-10, .gy-xxl-10 {
    --x-gutter-y: 2.5rem;
  }

  .g-xxl-12, .gx-xxl-12 {
    --x-gutter-x: 3rem;
  }

  .g-xxl-12, .gy-xxl-12 {
    --x-gutter-y: 3rem;
  }

  .g-xxl-14, .gx-xxl-14 {
    --x-gutter-x: 3.5rem;
  }

  .g-xxl-14, .gy-xxl-14 {
    --x-gutter-y: 3.5rem;
  }

  .g-xxl-16, .gx-xxl-16 {
    --x-gutter-x: 4rem;
  }

  .g-xxl-16, .gy-xxl-16 {
    --x-gutter-y: 4rem;
  }

  .g-xxl-18, .gx-xxl-18 {
    --x-gutter-x: 4.5rem;
  }

  .g-xxl-18, .gy-xxl-18 {
    --x-gutter-y: 4.5rem;
  }

  .g-xxl-20, .gx-xxl-20 {
    --x-gutter-x: 5rem;
  }

  .g-xxl-20, .gy-xxl-20 {
    --x-gutter-y: 5rem;
  }

  .g-xxl-24, .gx-xxl-24 {
    --x-gutter-x: 6rem;
  }

  .g-xxl-24, .gy-xxl-24 {
    --x-gutter-y: 6rem;
  }

  .g-xxl-32, .gx-xxl-32 {
    --x-gutter-x: 8rem;
  }

  .g-xxl-32, .gy-xxl-32 {
    --x-gutter-y: 8rem;
  }

  .g-xxl-40, .gx-xxl-40 {
    --x-gutter-x: 10rem;
  }

  .g-xxl-40, .gy-xxl-40 {
    --x-gutter-y: 10rem;
  }

  .g-xxl-48, .gx-xxl-48 {
    --x-gutter-x: 12rem;
  }

  .g-xxl-48, .gy-xxl-48 {
    --x-gutter-y: 12rem;
  }

  .g-xxl-56, .gx-xxl-56 {
    --x-gutter-x: 14rem;
  }

  .g-xxl-56, .gy-xxl-56 {
    --x-gutter-y: 14rem;
  }

  .g-xxl-64, .gx-xxl-64 {
    --x-gutter-x: 16rem;
  }

  .g-xxl-64, .gy-xxl-64 {
    --x-gutter-y: 16rem;
  }

  .g-xxl-72, .gx-xxl-72 {
    --x-gutter-x: 18rem;
  }

  .g-xxl-72, .gy-xxl-72 {
    --x-gutter-y: 18rem;
  }

  .g-xxl-80, .gx-xxl-80 {
    --x-gutter-x: 20rem;
  }

  .g-xxl-80, .gy-xxl-80 {
    --x-gutter-y: 20rem;
  }

  .g-xxl-88, .gx-xxl-88 {
    --x-gutter-x: 22rem;
  }

  .g-xxl-88, .gy-xxl-88 {
    --x-gutter-y: 22rem;
  }

  .g-xxl-96, .gx-xxl-96 {
    --x-gutter-x: 24rem;
  }

  .g-xxl-96, .gy-xxl-96 {
    --x-gutter-y: 24rem;
  }

  .g-xxl-px, .gx-xxl-px {
    --x-gutter-x: 1px;
  }

  .g-xxl-px, .gy-xxl-px {
    --x-gutter-y: 1px;
  }
}

.lead {
  font-size: 1.125rem;
  font-weight: 400;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-family: var(--x-font-display);
  font-weight: bolder;
  line-height: 1.3;
}

@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-family: var(--x-font-display);
  font-weight: bolder;
  line-height: 1.3;
}

@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-family: var(--x-font-display);
  font-weight: bolder;
  line-height: 1.3;
}

@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-family: var(--x-font-display);
  font-weight: bolder;
  line-height: 1.3;
}

@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-family: var(--x-font-display);
  font-weight: bolder;
  line-height: 1.3;
}

@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-family: var(--x-font-display);
  font-weight: bolder;
  line-height: 1.3;
}

@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled, .list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: .5rem;
}

.initialism {
  text-transform: uppercase;
  font-size: .875em;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  color: #6b7b93;
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: .875em;
}

.blockquote-footer:before {
  content: "— ";
}

.surtitle {
  color: #525f7f;
  text-transform: uppercase;
  font-size: .75rem;
  font-weight: 500;
}

.text-highlight-primary {
  background-image: linear-gradient(rgba(0, 0, 0, 0) 66%, rgba(92, 96, 245, .15) 34%);
  background-repeat: repeat-x;
}

.text-highlight-secondary {
  background-image: linear-gradient(rgba(0, 0, 0, 0) 66%, rgba(207, 214, 223, .15) 34%);
  background-repeat: repeat-x;
}

.text-highlight-tertiary {
  background-image: linear-gradient(rgba(0, 0, 0, 0) 66%, rgba(255, 87, 154, .15) 34%);
  background-repeat: repeat-x;
}

.text-highlight-success {
  background-image: linear-gradient(rgba(0, 0, 0, 0) 66%, rgba(0, 204, 136, .15) 34%);
  background-repeat: repeat-x;
}

.text-highlight-info {
  background-image: linear-gradient(rgba(0, 0, 0, 0) 66%, rgba(0, 212, 255, .15) 34%);
  background-repeat: repeat-x;
}

.text-highlight-warning {
  background-image: linear-gradient(rgba(0, 0, 0, 0) 66%, rgba(255, 140, 0, .15) 34%);
  background-repeat: repeat-x;
}

.text-highlight-danger {
  background-image: linear-gradient(rgba(0, 0, 0, 0) 66%, rgba(255, 51, 102, .15) 34%);
  background-repeat: repeat-x;
}

.text-highlight-white, .text-highlight-light {
  background-image: linear-gradient(rgba(0, 0, 0, 0) 66%, rgba(255, 255, 255, .15) 34%);
  background-repeat: repeat-x;
}

.text-highlight-dark {
  background-image: linear-gradient(rgba(0, 0, 0, 0) 66%, rgba(22, 25, 44, .15) 34%);
  background-repeat: repeat-x;
}

.dropcaps > p {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.dropcaps > p:first-child:first-letter {
  float: left;
  margin-top: .05em;
  margin-right: .15em;
  font-size: 3.5em;
  font-weight: 700;
  line-height: 1;
}

.clearfix:after {
  clear: both;
  content: "";
  display: block;
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(92, 96, 245, var(--x-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #000 !important;
  background-color: RGBA(207, 214, 223, var(--x-bg-opacity, 1)) !important;
}

.text-bg-tertiary {
  color: #fff !important;
  background-color: RGBA(255, 87, 154, var(--x-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(0, 204, 136, var(--x-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(0, 212, 255, var(--x-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #fff !important;
  background-color: RGBA(255, 140, 0, var(--x-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(255, 51, 102, var(--x-bg-opacity, 1)) !important;
}

.text-bg-white, .text-bg-light {
  color: #000 !important;
  background-color: RGBA(255, 255, 255, var(--x-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(22, 25, 44, var(--x-bg-opacity, 1)) !important;
}

.link-primary {
  color: #5c60f5 !important;
}

.link-primary:hover, .link-primary:focus {
  color: #4a4dc4 !important;
}

.link-secondary {
  color: #cfd6df !important;
}

.link-secondary:hover, .link-secondary:focus {
  color: #d9dee5 !important;
}

.link-tertiary {
  color: #ff579a !important;
}

.link-tertiary:hover, .link-tertiary:focus {
  color: #cc467b !important;
}

.link-success {
  color: #0c8 !important;
}

.link-success:hover, .link-success:focus {
  color: #00a36d !important;
}

.link-info {
  color: #00d4ff !important;
}

.link-info:hover, .link-info:focus {
  color: #3df !important;
}

.link-warning {
  color: #ff8c00 !important;
}

.link-warning:hover, .link-warning:focus {
  color: #cc7000 !important;
}

.link-danger {
  color: #f36 !important;
}

.link-danger:hover, .link-danger:focus {
  color: #cc2952 !important;
}

.link-white, .link-white:hover, .link-white:focus, .link-light, .link-light:hover, .link-light:focus {
  color: #fff !important;
}

.link-dark {
  color: #16192c !important;
}

.link-dark:hover, .link-dark:focus {
  color: #121423 !important;
}

.ratio {
  width: 100%;
  position: relative;
}

.ratio:before {
  padding-top: var(--x-aspect-ratio);
  content: "";
  display: block;
}

.ratio > * {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.ratio-1x1 {
  --x-aspect-ratio: 100%;
}

.ratio-4x3 {
  --x-aspect-ratio: 75%;
}

.ratio-16x9 {
  --x-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --x-aspect-ratio: 42.8571%;
}

.fixed-top {
  z-index: 1030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.fixed-bottom {
  z-index: 1030;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.sticky-top {
  z-index: 1020;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.sticky-bottom {
  z-index: 1020;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    z-index: 1020;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  .sticky-sm-bottom {
    z-index: 1020;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 768px) {
  .sticky-md-top {
    z-index: 1020;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  .sticky-md-bottom {
    z-index: 1020;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 992px) {
  .sticky-lg-top {
    z-index: 1020;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  .sticky-lg-bottom {
    z-index: 1020;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 1200px) {
  .sticky-xl-top {
    z-index: 1020;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  .sticky-xl-bottom {
    z-index: 1020;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
  }
}

@media (min-width: 1400px) {
  .sticky-xxl-top {
    z-index: 1020;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  .sticky-xxl-bottom {
    z-index: 1020;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
  }
}

.hstack {
  flex-direction: row;
  align-self: stretch;
  align-items: center;
  display: flex;
}

.vstack {
  flex-direction: column;
  flex: auto;
  align-self: stretch;
  display: flex;
}

.visually-hidden, .visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.stretched-link:after {
  z-index: 1;
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.text-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.vr {
  width: 1px;
  min-height: 1em;
  opacity: .9;
  background-color: currentColor;
  align-self: stretch;
  display: inline-block;
}

.form-label {
  color: #16192c;
  margin-bottom: .5rem;
  font-size: .875rem;
  font-weight: 500;
}

.col-form-label {
  font-size: inherit;
  color: #16192c;
  margin-bottom: 0;
  padding-top: calc(.75rem + 1px);
  padding-bottom: calc(.75rem + 1px);
  font-weight: 500;
  line-height: 1.3;
}

.col-form-label-lg {
  padding-top: calc(1rem + 1px);
  padding-bottom: calc(1rem + 1px);
  font-size: 1rem;
}

.col-form-label-sm {
  padding-top: calc(.5rem + 1px);
  padding-bottom: calc(.5rem + 1px);
  font-size: .875rem;
}

.form-text {
  color: #6b7b93;
  margin-top: .25rem;
  font-size: .875em;
}

.form-control {
  width: 100%;
  color: #16192c;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e7eaf0;
  border-radius: .375rem;
  padding: .75rem 1.25rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.3;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
  box-shadow: 0 1px 2px rgba(50, 50, 71, .08);
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control[type="file"] {
  overflow: hidden;
}

.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control:focus {
  color: #16192c;
  background-color: #fff;
  border-color: #5c60f5;
  outline: 0;
  box-shadow: 0 1px 2px rgba(50, 50, 71, .08), 0 0 0 3px rgba(92, 96, 245, .25);
}

.form-control::-webkit-date-and-time-value {
  height: 1.3em;
}

.form-control::placeholder {
  color: #8898a9;
  opacity: 1;
}

.form-control:disabled {
  opacity: 1;
  background-color: #e7eaf0;
}

.form-control::-webkit-file-upload-button {
  margin: -.75rem -1.25rem;
  color: #16192c;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  background-color: #f5f9fc;
  border-radius: 0;
  padding: .75rem 1.25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.form-control::file-selector-button {
  margin: -.75rem -1.25rem;
  color: #16192c;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  background-color: #f5f9fc;
  border-radius: 0;
  padding: .75rem 1.25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.form-control:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi))::-webkit-file-upload-button {
  border-right-width: 1px;
  margin-right: 1.25rem;
}

.form-control:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi))::file-selector-button {
  border-right-width: 1px;
  margin-right: 1.25rem;
}

.form-control:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::-webkit-file-upload-button {
  border-left-width: 1px;
  margin-left: 1.25rem;
}

.form-control:-moz-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::-moz-file-selector-button {
  border-left-width: 1px;
  margin-left: 1.25rem;
}

.form-control:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::file-selector-button {
  border-left-width: 1px;
  margin-left: 1.25rem;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    transition: none;
  }

  .form-control::file-selector-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #e9edef;
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #e9edef;
}

.form-control-plaintext {
  width: 100%;
  color: #525f7f;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid rgba(0, 0, 0, 0);
  border-width: 1px 0;
  margin-bottom: 0;
  padding: .75rem 0;
  line-height: 1.3;
  display: block;
}

.form-control-plaintext:focus {
  outline: 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-left: 0;
  padding-right: 0;
}

.form-control-sm {
  min-height: calc(1.3em + 1rem + 2px);
  border-radius: .375rem;
  padding: .5rem 1rem;
  font-size: .875rem;
}

.form-control-sm::-webkit-file-upload-button {
  margin: -.5rem -1rem;
  padding: .5rem 1rem;
}

.form-control-sm::file-selector-button {
  margin: -.5rem -1rem;
  padding: .5rem 1rem;
}

.form-control-sm:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi))::-webkit-file-upload-button {
  margin-right: 1rem;
}

.form-control-sm:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi))::file-selector-button {
  margin-right: 1rem;
}

.form-control-sm:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::-webkit-file-upload-button {
  margin-left: 1rem;
}

.form-control-sm:-moz-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::-moz-file-selector-button {
  margin-left: 1rem;
}

.form-control-sm:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::file-selector-button {
  margin-left: 1rem;
}

.form-control-lg {
  min-height: calc(1.3em + 2rem + 2px);
  border-radius: .5rem;
  padding: 1rem 1.5rem;
  font-size: 1rem;
}

.form-control-lg::-webkit-file-upload-button {
  margin: -1rem -1.5rem;
  padding: 1rem 1.5rem;
}

.form-control-lg::file-selector-button {
  margin: -1rem -1.5rem;
  padding: 1rem 1.5rem;
}

.form-control-lg:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi))::-webkit-file-upload-button {
  margin-right: 1.5rem;
}

.form-control-lg:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi))::file-selector-button {
  margin-right: 1.5rem;
}

.form-control-lg:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::-webkit-file-upload-button {
  margin-left: 1.5rem;
}

.form-control-lg:-moz-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::-moz-file-selector-button {
  margin-left: 1.5rem;
}

.form-control-lg:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))::file-selector-button {
  margin-left: 1.5rem;
}

textarea.form-control {
  min-height: calc(1.3em + 1.5rem + 2px);
}

textarea.form-control-sm {
  min-height: calc(1.3em + 1rem + 2px);
}

textarea.form-control-lg {
  min-height: calc(1.3em + 2rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: calc(1.3em + 1.5rem + 2px);
  padding: .75rem;
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control-color::-moz-color-swatch {
  border-radius: .375rem;
  border: 0 !important;
}

.form-control-color::-webkit-color-swatch {
  border-radius: .375rem;
}

.form-control-color.form-control-sm {
  height: calc(1.3em + 1rem + 2px);
}

.form-control-color.form-control-lg {
  height: calc(1.3em + 2rem + 2px);
}

.form-select {
  width: 100%;
  -moz-padding-start: calc(1.25rem - 3px);
  color: #16192c;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%232D3748' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-position: right 1.25rem center;
  background-repeat: no-repeat;
  background-size: 16px 12px;
  border: 1px solid #e7eaf0;
  border-radius: .375rem;
  padding: .75rem 3.75rem .75rem 1.25rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.3;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
  box-shadow: 0 1px 2px rgba(50, 50, 71, .08);
}

@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}

.form-select:focus {
  border-color: #5c60f5;
  outline: 0;
  box-shadow: 0 1px 2px rgba(50, 50, 71, .08), 0 0 0 3px rgba(92, 96, 245, .25);
}

.form-select[multiple], .form-select[size]:not([size="1"]) {
  background-image: none;
  padding-right: 1.25rem;
}

.form-select:disabled {
  color: #8898a9;
  background-color: #e7eaf0;
}

.form-select:-moz-focusring {
  color: rgba(0, 0, 0, 0);
  text-shadow: 0 0 #16192c;
}

.form-select-sm {
  border-radius: .375rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 1rem;
  font-size: .875rem;
}

.form-select-lg {
  border-radius: .5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1.5rem;
  font-size: 1rem;
}

.form-check {
  min-height: 1.625rem;
  margin-bottom: 0;
  padding-left: 1.5em;
  display: block;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  text-align: right;
  padding-left: 0;
  padding-right: 1.5em;
}

.form-check-reverse .form-check-input {
  float: right;
  margin-left: 0;
  margin-right: -1.5em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  vertical-align: top;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  print-color-adjust: exact;
  background-color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, .25);
  margin-top: .3125em;
}

.form-check-input[type="checkbox"] {
  border-radius: .25em;
}

.form-check-input[type="radio"] {
  border-radius: 50%;
}

.form-check-input:active {
  filter: brightness(90%);
}

.form-check-input:focus {
  border-color: #5c60f5;
  outline: 0;
  box-shadow: 0 0 0 3px rgba(92, 96, 245, .25);
}

.form-check-input:checked {
  background-color: #5c60f5;
  border-color: #5c60f5;
}

.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23FFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23FFF'/%3e%3c/svg%3e");
}

.form-check-input[type="checkbox"]:indeterminate {
  background-color: #5c60f5;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23FFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
  border-color: #5c60f5;
}

.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: .5;
}

.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: .5;
}

.form-switch {
  padding-left: 3.375em;
}

.form-switch .form-check-input {
  width: 2.875em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: 0;
  border-radius: 2.875em;
  margin-left: -3.375em;
  transition: background-position .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%235C60F5'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23FFF'/%3e%3c/svg%3e");
  background-position: 100%;
}

.form-switch.form-check-reverse {
  padding-left: 0;
  padding-right: 3.375em;
}

.form-switch.form-check-reverse .form-check-input {
  margin-left: 0;
  margin-right: -3.375em;
}

.form-check-inline {
  margin-right: 1rem;
  display: inline-block;
}

.btn-check {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute;
}

.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: .65;
}

.form-range {
  width: 100%;
  height: calc(1rem + 6px);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: rgba(0, 0, 0, 0);
  padding: 0;
}

.form-range:focus {
  outline: 0;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(92, 96, 245, .25);
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(92, 96, 245, .25);
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #5c60f5;
  border: 0;
  border-radius: 1rem;
  margin-top: -.25rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  box-shadow: 0 .1rem .25rem rgba(0, 0, 0, .1);
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: #cecffc;
}

.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: .5rem;
  color: rgba(0, 0, 0, 0);
  cursor: pointer;
  background-color: #cfd6df;
  border-color: rgba(0, 0, 0, 0);
  border-radius: 1rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, .075);
}

.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #5c60f5;
  border: 0;
  border-radius: 1rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  box-shadow: 0 .1rem .25rem rgba(0, 0, 0, .1);
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}

.form-range::-moz-range-thumb:active {
  background-color: #cecffc;
}

.form-range::-moz-range-track {
  width: 100%;
  height: .5rem;
  color: rgba(0, 0, 0, 0);
  cursor: pointer;
  background-color: #cfd6df;
  border-color: rgba(0, 0, 0, 0);
  border-radius: 1rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, .075);
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: #8898a9;
}

.form-range:disabled::-moz-range-thumb {
  background-color: #8898a9;
}

.form-floating {
  position: relative;
}

.form-floating > .form-control, .form-floating > .form-control-plaintext, .form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}

.form-floating > label {
  width: 100%;
  height: 100%;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  transform-origin: 0 0;
  border: 1px solid rgba(0, 0, 0, 0);
  padding: 1rem 1.25rem;
  transition: opacity .1s ease-in-out, transform .1s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}

.form-floating > .form-control, .form-floating > .form-control-plaintext {
  padding: 1rem 1.25rem;
}

.form-floating > .form-control::placeholder, .form-floating > .form-control-plaintext::placeholder {
  color: rgba(0, 0, 0, 0);
}

.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown), .form-floating > .form-control-plaintext:focus, .form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-control:-webkit-autofill, .form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .form-control-plaintext ~ label, .form-floating > .form-select ~ label, .form-floating > .form-control:-webkit-autofill ~ label {
  opacity: .65;
  transform: scale(.85)translateY(-.5rem)translateX(.15rem);
}

.form-floating > .form-control-plaintext ~ label {
  border-width: 1px 0;
}

.input-group {
  width: 100%;
  flex-wrap: wrap;
  align-items: stretch;
  display: flex;
  position: relative;
}

.input-group > .form-control, .input-group > .form-select, .input-group > .form-floating {
  width: 1%;
  min-width: 0;
  flex: auto;
  position: relative;
}

.input-group > .form-control:focus, .input-group > .form-select:focus, .input-group > .form-floating:focus-within {
  z-index: 5;
}

.input-group .btn {
  z-index: 2;
  position: relative;
}

.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  color: #6b7b93;
  text-align: center;
  white-space: nowrap;
  background-color: #f5f9fc;
  border: 1px solid #e7eaf0;
  border-radius: .375rem;
  align-items: center;
  padding: .75rem 1.25rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.3;
  display: flex;
}

.input-group-lg > .form-control, .input-group-lg > .form-select, .input-group-lg > .input-group-text, .input-group-lg > .btn {
  border-radius: .5rem;
  padding: 1rem 1.5rem;
  font-size: 1rem;
}

.input-group-sm > .form-control, .input-group-sm > .form-select, .input-group-sm > .input-group-text, .input-group-sm > .btn {
  border-radius: .375rem;
  padding: .5rem 1rem;
  font-size: .875rem;
}

.input-group-lg > .form-select, .input-group-sm > .form-select {
  padding-right: 5rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating), .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3), .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control, .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select, .input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating), .input-group.has-validation > .dropdown-toggle:nth-last-child(n+4), .input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control, .input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -1px;
}

.input-group > .form-floating:not(:first-child) > .form-control, .input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  width: 100%;
  color: #0c8;
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

.valid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: rgba(0, 204, 136, .9);
  border-radius: .375rem;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  display: none;
  position: absolute;
  top: 100%;
}

.was-validated :valid ~ .valid-feedback, .was-validated :valid ~ .valid-tooltip, .is-valid ~ .valid-feedback, .is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2300CC88' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right calc(.325em + .375rem) center;
  background-repeat: no-repeat;
  background-size: calc(.65em + .75rem) calc(.65em + .75rem);
  border-color: #0c8;
  padding-right: calc(1.3em + 1.5rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #0c8;
  box-shadow: 0 0 0 3px rgba(0, 204, 136, .25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  background-position: right calc(.325em + .375rem) top calc(.325em + .375rem);
  padding-right: calc(1.3em + 1.5rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #0c8;
}

.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%232D3748' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2300CC88' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 1.25rem center, right 3.75rem center;
  background-size: 16px 12px, calc(.65em + .75rem) calc(.65em + .75rem);
  padding-right: 6.875rem;
}

.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #0c8;
  box-shadow: 0 0 0 3px rgba(0, 204, 136, .25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(1.3em + 4.5rem);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #0c8;
}

.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #0c8;
}

.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 3px rgba(0, 204, 136, .25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #0c8;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid, .was-validated .input-group > .form-select:not(:focus):valid, .input-group > .form-select:not(:focus).is-valid, .was-validated .input-group > .form-floating:not(:focus-within):valid, .input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  width: 100%;
  color: #f36;
  margin-top: .25rem;
  font-size: .875em;
  display: none;
}

.invalid-tooltip {
  z-index: 5;
  max-width: 100%;
  color: #fff;
  background-color: rgba(255, 51, 102, .9);
  border-radius: .375rem;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  display: none;
  position: absolute;
  top: 100%;
}

.was-validated :invalid ~ .invalid-feedback, .was-validated :invalid ~ .invalid-tooltip, .is-invalid ~ .invalid-feedback, .is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23FF3366'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23FF3366' stroke='none'/%3e%3c/svg%3e");
  background-position: right calc(.325em + .375rem) center;
  background-repeat: no-repeat;
  background-size: calc(.65em + .75rem) calc(.65em + .75rem);
  border-color: #f36;
  padding-right: calc(1.3em + 1.5rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #f36;
  box-shadow: 0 0 0 3px rgba(255, 51, 102, .25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  background-position: right calc(.325em + .375rem) top calc(.325em + .375rem);
  padding-right: calc(1.3em + 1.5rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #f36;
}

.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%232D3748' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23FF3366'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23FF3366' stroke='none'/%3e%3c/svg%3e");
  background-position: right 1.25rem center, right 3.75rem center;
  background-size: 16px 12px, calc(.65em + .75rem) calc(.65em + .75rem);
  padding-right: 6.875rem;
}

.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #f36;
  box-shadow: 0 0 0 3px rgba(255, 51, 102, .25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(1.3em + 4.5rem);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #f36;
}

.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #f36;
}

.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 3px rgba(255, 51, 102, .25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #f36;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid, .was-validated .input-group > .form-select:not(:focus):invalid, .input-group > .form-select:not(:focus).is-invalid, .was-validated .input-group > .form-floating:not(:focus-within):invalid, .input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

form {
  margin-bottom: 0;
}

.form-control:focus::placeholder {
  color: #abb6c5;
}

.form-control-muted {
  background-color: #eef0f5;
  border-color: #eef0f5;
}

.form-control-muted:focus {
  background-color: #f1f2f7;
}

.form-control-alt {
  background-color: #fff;
  border-color: #fff;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, .1), 0 2px 4px rgba(0, 0, 0, .1);
}

.form-control-alt:focus {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 2px #5c60f5;
}

.form-control-flush {
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0);
  border-width: 0;
  padding: 0;
}

.form-control-flush:focus {
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0);
  border-width: 0;
}

.form-check-label {
  color: #16192c;
  font-size: .875rem;
  font-weight: 500;
}

textarea[resize="none"] {
  resize: none !important;
}

textarea[resize="both"] {
  resize: both !important;
}

textarea[resize="vertical"] {
  resize: vertical !important;
}

textarea[resize="horizontal"] {
  resize: horizontal !important;
}

.textarea-autosize {
  display: grid;
}

.textarea-autosize:after {
  content: attr(data-replicated-value) " ";
  white-space: pre-wrap;
  visibility: hidden;
}

.textarea-autosize > textarea {
  resize: none;
  overflow: hidden;
}

.textarea-autosize > textarea, .textarea-autosize:after {
  font: inherit;
  border: 1px solid #000;
  grid-area: 1 / 1 / 2 / 2;
  padding: .5rem;
}

.form-group-stacked .form-control, .form-group-stacked .form-select {
  box-sizing: border-box;
  height: auto;
  position: relative;
}

.form-group-stacked .form-control:focus, .form-group-stacked .form-select:focus {
  z-index: 2;
}

.form-group-stacked > :first-child .form-control, .form-group-stacked > :first-child .form-select {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  margin-bottom: -1px;
}

.form-group-stacked > :last-child .form-control, .form-group-stacked > :last-child .form-select {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.form-group-stacked > .row {
  margin: 0;
}

.form-group-stacked > .row > :first-child, .form-group-stacked > .row > :last-child {
  padding: 0;
}

.form-group-stacked > .row > :first-child .form-control, .form-group-stacked > .row > :first-child .form-select {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.form-group-stacked > .row > :last-child .form-control, .form-group-stacked > .row > :last-child .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.form-dark .form-control {
  color: rgba(255, 255, 255, .9);
  background-color: #2d3041;
  border: 1px solid #2d3041;
}

.form-dark .form-control::placeholder {
  color: rgba(255, 255, 255, .8);
}

.form-dark .form-control:focus {
  background-color: #222537;
  border: 1px solid #222537;
}

.form-dark .form-control:focus::placeholder {
  color: rgba(255, 255, 255, .6);
}

.form-dark .input-group .input-group-text {
  color: rgba(255, 255, 255, .9);
  background-color: #2d3041;
  border-color: #2d3041;
}

.form-dark .input-group:focus-within .input-group-text {
  background-color: #222537;
  border-color: #222537;
}

.form-stacked .form-control {
  box-sizing: border-box;
  position: relative;
}

.form-stacked .form-control:first-of-type {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  margin-bottom: -1px;
}

.form-stacked .form-control:not(:first-of-type):not(:last-of-type) {
  border-radius: 0;
  margin-bottom: -1px;
}

.form-stacked .form-control:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.form-stacked .form-control:focus {
  z-index: 2;
}

.input-group {
  border-radius: .375rem;
  box-shadow: 0 1px 2px rgba(50, 50, 71, .08);
}

.input-group .form-control {
  box-shadow: none;
}

.input-group:focus-within {
  box-shadow: 0 1px 2px rgba(50, 50, 71, .08), 0 0 0 3px rgba(92, 96, 245, .25);
}

.input-group:focus-within .input-group-text {
  border-color: #5c60f5;
}

.input-group:focus-within .form-control {
  box-shadow: none;
  border-color: #5c60f5;
}

.input-group-text {
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.input-group-flush .form-control, .input-group-flush .input-group-text {
  border: 0 !important;
}

.input-group-inline {
  border-radius: .375rem;
  position: relative;
}

.input-group-inline.input-group-sm {
  border-radius: .375rem;
}

.input-group-inline.input-group-lg {
  border-radius: .5rem;
}

.input-group-inline .input-group-text {
  background: #fff;
}

.input-group-inline > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-left: 0;
  padding-left: .25rem;
  margin-left: 0 !important;
}

.input-group-inline > :not(:last-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-right: 0;
  padding-right: .25rem;
  margin-right: 0 !important;
}

.form-switch {
  min-height: 1.5rem;
}

.form-switch > .form-check-input {
  height: 1.5rem;
  margin-top: 0;
}

.form-switch > .form-check-label {
  margin-top: calc(.75rem - .8125em);
}

.form-check-linethrough .form-check-input:checked + .form-check-label {
  text-decoration: line-through;
}

.form-item-check {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  display: none;
  position: absolute;
}

.form-item-check:hover + .form-item .form-item-click, .form-item-check:checked + .form-item .form-item-click {
  border-color: #5c60f5;
}

.form-item-check[disabled] + .form-item .form-item-click, .form-item-check:disabled + .form-item .form-item-click {
  pointer-events: none;
  filter: none;
  opacity: .5;
}

.form-color {
  padding: 0;
  list-style: none;
}

.form-color:after, .form-color:before {
  content: "";
  display: table;
}

.form-color:after {
  clear: both;
}

.form-color label {
  width: 2.25rem;
  height: 2.25rem;
  float: left;
  opacity: .7;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: .375rem;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  margin-right: .375rem;
  padding: .375rem;
  transition: all .2s ease-in-out;
  display: flex;
  transform: scale(.95);
}

@media (prefers-reduced-motion: reduce) {
  .form-color label {
    transition: none;
  }
}

.form-color label:hover {
  cursor: pointer;
  opacity: 1;
}

.form-color label i, .form-color label svg {
  opacity: 0;
  color: rgba(255, 255, 255, .9);
}

.form-color input {
  position: absolute;
  left: -9999px;
}

.form-color input:checked + label {
  opacity: 1;
  transform: scale(1.1);
}

.form-color input:checked + label i, .form-color input:checked + label svg {
  opacity: 1;
}

.form-color-wide label {
  height: 1.5rem;
}

.form-color-xl label {
  width: 5rem;
  height: 5rem;
}

.form-color-xl.form-color-wide label {
  width: 5rem;
  height: 3.5rem;
}

.form-color-lg label {
  width: 3.25rem;
  height: 3.25rem;
}

.form-color-sm label {
  width: 1.25rem;
  height: 1.25rem;
}

.was-validated .input-group:valid .form-control, .was-validated .input-group:valid .form-select, .was-validated .input-group:valid .input-group-text, .input-group.is-valid .form-control, .input-group.is-valid .form-select, .input-group.is-valid .input-group-text {
  border-color: #0c8;
}

.was-validated .input-group:valid:focus-within, .input-group.is-valid:focus-within {
  border-color: #0c8;
  box-shadow: 0 0 0 3px rgba(0, 204, 136, .25);
}

.was-validated .input-group:invalid .form-control, .was-validated .input-group:invalid .form-select, .was-validated .input-group:invalid .input-group-text, .input-group.is-invalid .form-control, .input-group.is-invalid .form-select, .input-group.is-invalid .input-group-text {
  border-color: #f36;
}

.was-validated .input-group:invalid:focus-within, .input-group.is-invalid:focus-within {
  border-color: #f36;
  box-shadow: 0 0 0 3px rgba(255, 51, 102, .25);
}

.accordion {
  --x-accordion-color: #525f7f;
  --x-accordion-bg: transparent;
  --x-accordion-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;
  --x-accordion-border-color: #e7eaf0;
  --x-accordion-border-width: 1px;
  --x-accordion-border-radius: .375rem;
  --x-accordion-inner-border-radius: calc(.375rem - 1px);
  --x-accordion-btn-padding-x: 1.25rem;
  --x-accordion-btn-padding-y: 1.5rem;
  --x-accordion-btn-color: #525f7f;
  --x-accordion-btn-bg: transparent;
  --x-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23525F7F'%3e%3cpath fill-rule='evenodd' d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z'/%3e%3c/svg%3e");
  --x-accordion-btn-icon-width: 1.25rem;
  --x-accordion-btn-icon-transform: rotate(180deg);
  --x-accordion-btn-icon-transition: transform .2s ease-in-out;
  --x-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2316192C'%3e%3cpath fill-rule='evenodd' d='M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z'/%3e%3c/svg%3e");
  --x-accordion-btn-focus-border-color: #e7eaf0;
  --x-accordion-btn-focus-box-shadow: 0 0 0 3px rgba(92, 96, 245, .25);
  --x-accordion-body-padding-x: 1.25rem;
  --x-accordion-body-padding-y: 1.5rem;
  --x-accordion-active-color: #16192c;
  --x-accordion-active-bg: transparent;
}

.accordion-button {
  width: 100%;
  padding: var(--x-accordion-btn-padding-y) var(--x-accordion-btn-padding-x);
  color: var(--x-accordion-btn-color);
  text-align: left;
  background-color: var(--x-accordion-btn-bg);
  overflow-anchor: none;
  transition: var(--x-accordion-transition);
  border: 0;
  border-radius: 0;
  align-items: center;
  font-size: 1rem;
  display: flex;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}

.accordion-button:not(.collapsed) {
  color: var(--x-accordion-active-color);
  background-color: var(--x-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--x-accordion-border-width)) 0 var(--x-accordion-border-color);
}

.accordion-button:not(.collapsed):after {
  background-image: var(--x-accordion-btn-active-icon);
  transform: var(--x-accordion-btn-icon-transform);
}

.accordion-button:after {
  width: var(--x-accordion-btn-icon-width);
  height: var(--x-accordion-btn-icon-width);
  content: "";
  background-image: var(--x-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--x-accordion-btn-icon-width);
  transition: var(--x-accordion-btn-icon-transition);
  flex-shrink: 0;
  margin-left: auto;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button:after {
    transition: none;
  }
}

.accordion-button:hover {
  z-index: 2;
}

.accordion-button:focus {
  z-index: 3;
  border-color: var(--x-accordion-btn-focus-border-color);
  box-shadow: var(--x-accordion-btn-focus-box-shadow);
  outline: 0;
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--x-accordion-color);
  background-color: var(--x-accordion-bg);
  border: var(--x-accordion-border-width) solid var(--x-accordion-border-color);
}

.accordion-item:first-of-type {
  border-top-left-radius: var(--x-accordion-border-radius);
  border-top-right-radius: var(--x-accordion-border-radius);
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--x-accordion-inner-border-radius);
  border-top-right-radius: var(--x-accordion-inner-border-radius);
}

.accordion-item:not(:first-of-type) {
  border-top: 0;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: var(--x-accordion-border-radius);
  border-bottom-left-radius: var(--x-accordion-border-radius);
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--x-accordion-inner-border-radius);
  border-bottom-left-radius: var(--x-accordion-inner-border-radius);
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--x-accordion-border-radius);
  border-bottom-left-radius: var(--x-accordion-border-radius);
}

.accordion-body {
  padding: var(--x-accordion-body-padding-y) var(--x-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}

.accordion-flush .accordion-item {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
}

.accordion-flush .accordion-item:first-child {
  border-top: 0;
}

.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}

.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}

.accordion-card .card-header {
  cursor: pointer;
  position: relative;
}

.accordion-card .card-header:after {
  content: "+";
  font-family: var(--x-font-sans-serif);
  font-weight: 700;
  position: absolute;
  top: 50%;
  right: 1.5rem;
  transform: translateY(-50%);
}

.accordion-card .card-header[aria-expanded="false"]:after {
  content: "+";
}

.accordion-card .card-header[aria-expanded="true"]:after {
  content: "-";
}

.accordion-card .card-header[aria-expanded="true"] .heading {
  color: theme-color("primary");
}

.accordion-spaced > .card {
  border-radius: .75rem !important;
}

.accordion-spaced > .card:not(:last-of-type) {
  border: 1px solid #e7eaf0;
  margin-bottom: 1.5rem;
}

.alert {
  --x-alert-bg: transparent;
  --x-alert-padding-x: 1rem;
  --x-alert-padding-y: .875rem;
  --x-alert-margin-bottom: 0;
  --x-alert-color: inherit;
  --x-alert-border-color: transparent;
  --x-alert-border: 1px solid var(--x-alert-border-color);
  --x-alert-border-radius: .375rem;
  padding: var(--x-alert-padding-y) var(--x-alert-padding-x);
  margin-bottom: var(--x-alert-margin-bottom);
  color: var(--x-alert-color);
  background-color: var(--x-alert-bg);
  border: var(--x-alert-border);
  border-radius: var(--x-alert-border-radius);
  position: relative;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 600;
}

.alert-dismissible {
  padding-right: 3rem;
}

.alert-dismissible .btn-close {
  z-index: 2;
  padding: 1.09375rem 1rem;
  position: absolute;
  top: 0;
  right: 0;
}

.alert-primary {
  --x-alert-color: #5356dd;
  --x-alert-bg: #efeffe;
  --x-alert-border-color: #dedffd;
}

.alert-primary .alert-link {
  color: #4245b1;
}

.alert-secondary {
  --x-alert-color: #151516;
  --x-alert-bg: #fafbfc;
  --x-alert-border-color: #f5f7f9;
}

.alert-secondary .alert-link {
  color: #111112;
}

.alert-tertiary {
  --x-alert-color: #e64e8b;
  --x-alert-bg: #ffeef5;
  --x-alert-border-color: #ffddeb;
}

.alert-tertiary .alert-link {
  color: #b83e6f;
}

.alert-success {
  --x-alert-color: #00b87a;
  --x-alert-bg: #e6faf3;
  --x-alert-border-color: #ccf5e7;
}

.alert-success .alert-link {
  color: #009362;
}

.alert-info {
  --x-alert-color: #00bfe6;
  --x-alert-bg: #e6fbff;
  --x-alert-border-color: #ccf6ff;
}

.alert-info .alert-link {
  color: #0099b8;
}

.alert-warning {
  --x-alert-color: #e67e00;
  --x-alert-bg: #fff4e6;
  --x-alert-border-color: #ffe8cc;
}

.alert-warning .alert-link {
  color: #b86500;
}

.alert-danger {
  --x-alert-color: #e62e5c;
  --x-alert-bg: #ffebf0;
  --x-alert-border-color: #ffd6e0;
}

.alert-danger .alert-link {
  color: #b8254a;
}

.alert-white {
  --x-alert-color: #1a1a1a;
  --x-alert-bg: white;
  --x-alert-border-color: white;
}

.alert-white .alert-link {
  color: #151515;
}

.alert-light {
  --x-alert-color: #1a1a1a;
  --x-alert-bg: white;
  --x-alert-border-color: white;
}

.alert-light .alert-link {
  color: #151515;
}

.alert-dark {
  --x-alert-color: #141728;
  --x-alert-bg: #e8e8ea;
  --x-alert-border-color: #d0d1d5;
}

.alert-dark .alert-link {
  color: #101220;
}

.avatar {
  vertical-align: middle;
  height: 2.875rem;
  width: 2.875rem;
  border-radius: .375rem;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
  display: inline-flex;
  position: relative;
}

.avatar img {
  width: 100%;
  border-radius: inherit;
}

.avatar + .avatar {
  margin-left: .25rem;
}

.avatar + .avatar-content {
  margin-left: .75rem;
  display: inline-block;
}

.avatar-2xl {
  width: 7.5rem;
  height: 7.5rem;
  font-size: calc(1.375rem + 1.5vw);
}

@media (min-width: 1200px) {
  .avatar-2xl {
    font-size: 2.5rem;
  }
}

.avatar-xl {
  width: 6rem;
  height: 6rem;
  font-size: calc(1.2625rem + .15vw);
}

@media (min-width: 1200px) {
  .avatar-xl {
    font-size: 1.375rem;
  }
}

.avatar-lg {
  width: 3.5rem;
  height: 3.5rem;
  font-size: 1.25rem;
}

.avatar-sm {
  width: 2.25rem;
  height: 2.25rem;
  border-radius: .25rem;
  font-size: .75rem;
}

.avatar-xs {
  width: 1.75rem;
  height: 1.75rem;
  border-radius: .25rem;
  font-size: .675rem;
}

.avatar-group {
  line-height: 1;
  display: inline-block;
}

.avatar-group .avatar {
  z-index: 1;
  transition: margin .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .avatar-group .avatar {
    transition: none;
  }
}

.avatar-group .avatar:hover {
  z-index: 2;
}

.avatar-group .avatar + .avatar {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -1.25rem;
}

.avatar-group .avatar-sm + .avatar-sm, .avatar-group .avatar-xs + .avatar-xs {
  margin-left: -1rem;
}

.avatar-group:hover .avatar {
  border-top-left-radius: .375rem;
  border-bottom-left-radius: .375rem;
}

.avatar-group:hover .avatar-sm {
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.avatar-ungroup-hover:hover .avatar:not(:first-child) {
  margin-left: 0;
}

.badge {
  --x-badge-padding-x: .6rem;
  --x-badge-padding-y: .2rem;
  --x-badge-font-size: .75em;
  --x-badge-font-weight: 600;
  --x-badge-color: #fff;
  --x-badge-border-radius: .375rem;
  padding: var(--x-badge-padding-y) var(--x-badge-padding-x);
  font-size: var(--x-badge-font-size);
  font-weight: var(--x-badge-font-weight);
  color: var(--x-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--x-badge-border-radius);
  line-height: 1;
  display: inline-block;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge {
  line-height: 1rem;
}

.badge-xs {
  padding: .1rem .5rem;
  font-size: 60%;
}

.badge-md {
  padding: .4rem 1rem;
}

.badge-lg {
  padding: .6rem 1rem;
  font-size: 1em;
}

.badge-count {
  text-align: center;
  width: 1.25rem;
  height: 1.25rem;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  font-size: .75rem;
  font-weight: 600;
  display: inline-flex;
  padding: 0 !important;
}

.badge-count.badge-xs {
  width: 1rem;
  height: 1rem;
  font-size: .5rem;
}

.badge-count.badge-md {
  width: 1.5rem;
  height: 1.5rem;
}

.badge-count.badge-lg {
  width: 2rem;
  height: 2rem;
}

.badge-count svg {
  margin: 0;
}

.badge-dot {
  color: #525f7f;
  background: none;
  align-items: center;
  padding: 0;
  font-weight: 400;
  display: inline-flex;
}

.badge-dot i {
  vertical-align: middle;
  width: .375rem;
  height: .375rem;
  border-radius: 50%;
  margin-right: .5rem;
  display: inline-block;
}

.badge-dot.badge-md i {
  width: .5rem;
  height: .5rem;
}

.badge-dot.badge-lg i {
  width: .625rem;
  height: .625rem;
}

.breadcrumb {
  --x-breadcrumb-padding-x: 0;
  --x-breadcrumb-padding-y: 0;
  --x-breadcrumb-margin-bottom: 0;
  --x-breadcrumb-font-size: .875rem;
  --x-breadcrumb-bg: ;
  --x-breadcrumb-border-radius: ;
  --x-breadcrumb-divider-color: #abb6c5;
  --x-breadcrumb-item-padding-x: .375rem;
  --x-breadcrumb-item-active-color: #16192c;
  padding: var(--x-breadcrumb-padding-y) var(--x-breadcrumb-padding-x);
  margin-bottom: var(--x-breadcrumb-margin-bottom);
  font-size: var(--x-breadcrumb-font-size);
  background-color: var(--x-breadcrumb-bg);
  border-radius: var(--x-breadcrumb-border-radius);
  flex-wrap: wrap;
  list-style: none;
  display: flex;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--x-breadcrumb-item-padding-x);
}

.breadcrumb-item + .breadcrumb-item:before {
  float: left;
  padding-right: var(--x-breadcrumb-item-padding-x);
  color: var(--x-breadcrumb-divider-color);
  content: var(--x-breadcrumb-divider, "/");
}

.breadcrumb-item.active {
  color: var(--x-breadcrumb-item-active-color);
}

.btn {
  --x-btn-padding-x: 1.25rem;
  --x-btn-padding-y: .75rem;
  --x-btn-font-family: ;
  --x-btn-font-size: 1rem;
  --x-btn-font-weight: 500;
  --x-btn-line-height: 1.3;
  --x-btn-color: #525f7f;
  --x-btn-bg: transparent;
  --x-btn-border-width: 1px;
  --x-btn-border-color: transparent;
  --x-btn-border-radius: .375rem;
  --x-btn-hover-border-color: transparent;
  --x-btn-box-shadow: 0 1px 1px rgba(0, 0, 0, .075);
  --x-btn-disabled-opacity: .65;
  --x-btn-focus-box-shadow: 0 0 0 3px rgba(var(--x-btn-focus-shadow-rgb), .5);
  padding: var(--x-btn-padding-y) var(--x-btn-padding-x);
  font-family: var(--x-btn-font-family);
  font-size: var(--x-btn-font-size);
  font-weight: var(--x-btn-font-weight);
  line-height: var(--x-btn-line-height);
  color: var(--x-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: var(--x-btn-border-width) solid var(--x-btn-border-color);
  border-radius: var(--x-btn-border-radius);
  background-color: var(--x-btn-bg);
  box-shadow: var(--x-btn-box-shadow);
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: inline-block;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: var(--x-btn-hover-color);
  background-color: var(--x-btn-hover-bg);
  border-color: var(--x-btn-hover-border-color);
}

.btn-check + .btn:hover {
  color: var(--x-btn-color);
  background-color: var(--x-btn-bg);
  border-color: var(--x-btn-border-color);
}

.btn:focus-visible {
  color: var(--x-btn-hover-color);
  background-color: var(--x-btn-hover-bg);
  border-color: var(--x-btn-hover-border-color);
  box-shadow: var(--x-btn-box-shadow), var(--x-btn-focus-box-shadow);
  outline: 0;
}

.btn-check:focus-visible + .btn {
  border-color: var(--x-btn-hover-border-color);
  box-shadow: var(--x-btn-box-shadow), var(--x-btn-focus-box-shadow);
  outline: 0;
}

.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--x-btn-active-color);
  background-color: var(--x-btn-active-bg);
  border-color: var(--x-btn-active-border-color);
  box-shadow: var(--x-btn-active-shadow);
}

.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--x-btn-active-shadow), var(--x-btn-focus-box-shadow);
}

.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--x-btn-disabled-color);
  pointer-events: none;
  background-color: var(--x-btn-disabled-bg);
  border-color: var(--x-btn-disabled-border-color);
  opacity: var(--x-btn-disabled-opacity);
  box-shadow: none;
}

.btn-primary {
  --x-btn-color: #fff;
  --x-btn-bg: #5c60f5;
  --x-btn-border-color: #5c60f5;
  --x-btn-hover-color: #fff;
  --x-btn-hover-bg: #4a4dc4;
  --x-btn-hover-border-color: #4a4dc4;
  --x-btn-focus-shadow-rgb: 116, 120, 247;
  --x-btn-active-color: #fff;
  --x-btn-active-bg: #4a4dc4;
  --x-btn-active-border-color: #4548b8;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #fff;
  --x-btn-disabled-bg: #5c60f5;
  --x-btn-disabled-border-color: #5c60f5;
}

.btn-secondary {
  --x-btn-color: #000;
  --x-btn-bg: #cfd6df;
  --x-btn-border-color: #cfd6df;
  --x-btn-hover-color: #000;
  --x-btn-hover-bg: #d9dee5;
  --x-btn-hover-border-color: #d4dae2;
  --x-btn-focus-shadow-rgb: 176, 182, 190;
  --x-btn-active-color: #000;
  --x-btn-active-bg: #d9dee5;
  --x-btn-active-border-color: #d4dae2;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #000;
  --x-btn-disabled-bg: #cfd6df;
  --x-btn-disabled-border-color: #cfd6df;
}

.btn-tertiary {
  --x-btn-color: #fff;
  --x-btn-bg: #ff579a;
  --x-btn-border-color: #ff579a;
  --x-btn-hover-color: #fff;
  --x-btn-hover-bg: #cc467b;
  --x-btn-hover-border-color: #cc467b;
  --x-btn-focus-shadow-rgb: 255, 112, 169;
  --x-btn-active-color: #fff;
  --x-btn-active-bg: #cc467b;
  --x-btn-active-border-color: #bf4174;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #fff;
  --x-btn-disabled-bg: #ff579a;
  --x-btn-disabled-border-color: #ff579a;
}

.btn-success {
  --x-btn-color: #fff;
  --x-btn-bg: #0c8;
  --x-btn-border-color: #0c8;
  --x-btn-hover-color: #fff;
  --x-btn-hover-bg: #00a36d;
  --x-btn-hover-border-color: #00a36d;
  --x-btn-focus-shadow-rgb: 38, 212, 154;
  --x-btn-active-color: #fff;
  --x-btn-active-bg: #00a36d;
  --x-btn-active-border-color: #096;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #fff;
  --x-btn-disabled-bg: #0c8;
  --x-btn-disabled-border-color: #0c8;
}

.btn-info {
  --x-btn-color: #000;
  --x-btn-bg: #00d4ff;
  --x-btn-border-color: #00d4ff;
  --x-btn-hover-color: #000;
  --x-btn-hover-bg: #3df;
  --x-btn-hover-border-color: #1ad8ff;
  --x-btn-focus-shadow-rgb: 0, 180, 217;
  --x-btn-active-color: #000;
  --x-btn-active-bg: #3df;
  --x-btn-active-border-color: #1ad8ff;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #000;
  --x-btn-disabled-bg: #00d4ff;
  --x-btn-disabled-border-color: #00d4ff;
}

.btn-warning {
  --x-btn-color: #fff;
  --x-btn-bg: #ff8c00;
  --x-btn-border-color: #ff8c00;
  --x-btn-hover-color: #fff;
  --x-btn-hover-bg: #cc7000;
  --x-btn-hover-border-color: #cc7000;
  --x-btn-focus-shadow-rgb: 255, 157, 38;
  --x-btn-active-color: #fff;
  --x-btn-active-bg: #cc7000;
  --x-btn-active-border-color: #bf6900;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #fff;
  --x-btn-disabled-bg: #ff8c00;
  --x-btn-disabled-border-color: #ff8c00;
}

.btn-danger {
  --x-btn-color: #fff;
  --x-btn-bg: #f36;
  --x-btn-border-color: #f36;
  --x-btn-hover-color: #fff;
  --x-btn-hover-bg: #cc2952;
  --x-btn-hover-border-color: #cc2952;
  --x-btn-focus-shadow-rgb: 255, 82, 125;
  --x-btn-active-color: #fff;
  --x-btn-active-bg: #cc2952;
  --x-btn-active-border-color: #bf264d;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #fff;
  --x-btn-disabled-bg: #f36;
  --x-btn-disabled-border-color: #f36;
}

.btn-white {
  --x-btn-color: #000;
  --x-btn-bg: #fff;
  --x-btn-border-color: #fff;
  --x-btn-hover-color: #000;
  --x-btn-hover-bg: white;
  --x-btn-hover-border-color: white;
  --x-btn-focus-shadow-rgb: 217, 217, 217;
  --x-btn-active-color: #000;
  --x-btn-active-bg: white;
  --x-btn-active-border-color: white;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #000;
  --x-btn-disabled-bg: #fff;
  --x-btn-disabled-border-color: #fff;
}

.btn-light {
  --x-btn-color: #000;
  --x-btn-bg: #fff;
  --x-btn-border-color: #fff;
  --x-btn-hover-color: #000;
  --x-btn-hover-bg: #ccc;
  --x-btn-hover-border-color: #ccc;
  --x-btn-focus-shadow-rgb: 217, 217, 217;
  --x-btn-active-color: #000;
  --x-btn-active-bg: #ccc;
  --x-btn-active-border-color: #bfbfbf;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #000;
  --x-btn-disabled-bg: #fff;
  --x-btn-disabled-border-color: #fff;
}

.btn-dark {
  --x-btn-color: #fff;
  --x-btn-bg: #16192c;
  --x-btn-border-color: #16192c;
  --x-btn-hover-color: #fff;
  --x-btn-hover-bg: #454756;
  --x-btn-hover-border-color: #2d3041;
  --x-btn-focus-shadow-rgb: 57, 60, 76;
  --x-btn-active-color: #fff;
  --x-btn-active-bg: #454756;
  --x-btn-active-border-color: #2d3041;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #fff;
  --x-btn-disabled-bg: #16192c;
  --x-btn-disabled-border-color: #16192c;
}

.btn-outline-primary {
  --x-btn-color: #5c60f5;
  --x-btn-border-color: #5c60f5;
  --x-btn-hover-color: #fff;
  --x-btn-hover-bg: #5c60f5;
  --x-btn-hover-border-color: #5c60f5;
  --x-btn-focus-shadow-rgb: 92, 96, 245;
  --x-btn-active-color: #fff;
  --x-btn-active-bg: #5c60f5;
  --x-btn-active-border-color: #5c60f5;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #5c60f5;
  --x-btn-disabled-bg: transparent;
  --x-btn-disabled-border-color: #5c60f5;
  --x-gradient: none;
}

.btn-outline-secondary {
  --x-btn-color: #cfd6df;
  --x-btn-border-color: #cfd6df;
  --x-btn-hover-color: #000;
  --x-btn-hover-bg: #cfd6df;
  --x-btn-hover-border-color: #cfd6df;
  --x-btn-focus-shadow-rgb: 207, 214, 223;
  --x-btn-active-color: #000;
  --x-btn-active-bg: #cfd6df;
  --x-btn-active-border-color: #cfd6df;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #cfd6df;
  --x-btn-disabled-bg: transparent;
  --x-btn-disabled-border-color: #cfd6df;
  --x-gradient: none;
}

.btn-outline-tertiary {
  --x-btn-color: #ff579a;
  --x-btn-border-color: #ff579a;
  --x-btn-hover-color: #fff;
  --x-btn-hover-bg: #ff579a;
  --x-btn-hover-border-color: #ff579a;
  --x-btn-focus-shadow-rgb: 255, 87, 154;
  --x-btn-active-color: #fff;
  --x-btn-active-bg: #ff579a;
  --x-btn-active-border-color: #ff579a;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #ff579a;
  --x-btn-disabled-bg: transparent;
  --x-btn-disabled-border-color: #ff579a;
  --x-gradient: none;
}

.btn-outline-success {
  --x-btn-color: #0c8;
  --x-btn-border-color: #0c8;
  --x-btn-hover-color: #fff;
  --x-btn-hover-bg: #0c8;
  --x-btn-hover-border-color: #0c8;
  --x-btn-focus-shadow-rgb: 0, 204, 136;
  --x-btn-active-color: #fff;
  --x-btn-active-bg: #0c8;
  --x-btn-active-border-color: #0c8;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #0c8;
  --x-btn-disabled-bg: transparent;
  --x-btn-disabled-border-color: #0c8;
  --x-gradient: none;
}

.btn-outline-info {
  --x-btn-color: #00d4ff;
  --x-btn-border-color: #00d4ff;
  --x-btn-hover-color: #000;
  --x-btn-hover-bg: #00d4ff;
  --x-btn-hover-border-color: #00d4ff;
  --x-btn-focus-shadow-rgb: 0, 212, 255;
  --x-btn-active-color: #000;
  --x-btn-active-bg: #00d4ff;
  --x-btn-active-border-color: #00d4ff;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #00d4ff;
  --x-btn-disabled-bg: transparent;
  --x-btn-disabled-border-color: #00d4ff;
  --x-gradient: none;
}

.btn-outline-warning {
  --x-btn-color: #ff8c00;
  --x-btn-border-color: #ff8c00;
  --x-btn-hover-color: #fff;
  --x-btn-hover-bg: #ff8c00;
  --x-btn-hover-border-color: #ff8c00;
  --x-btn-focus-shadow-rgb: 255, 140, 0;
  --x-btn-active-color: #fff;
  --x-btn-active-bg: #ff8c00;
  --x-btn-active-border-color: #ff8c00;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #ff8c00;
  --x-btn-disabled-bg: transparent;
  --x-btn-disabled-border-color: #ff8c00;
  --x-gradient: none;
}

.btn-outline-danger {
  --x-btn-color: #f36;
  --x-btn-border-color: #f36;
  --x-btn-hover-color: #fff;
  --x-btn-hover-bg: #f36;
  --x-btn-hover-border-color: #f36;
  --x-btn-focus-shadow-rgb: 255, 51, 102;
  --x-btn-active-color: #fff;
  --x-btn-active-bg: #f36;
  --x-btn-active-border-color: #f36;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #f36;
  --x-btn-disabled-bg: transparent;
  --x-btn-disabled-border-color: #f36;
  --x-gradient: none;
}

.btn-outline-white, .btn-outline-light {
  --x-btn-color: #fff;
  --x-btn-border-color: #fff;
  --x-btn-hover-color: #000;
  --x-btn-hover-bg: #fff;
  --x-btn-hover-border-color: #fff;
  --x-btn-focus-shadow-rgb: 255, 255, 255;
  --x-btn-active-color: #000;
  --x-btn-active-bg: #fff;
  --x-btn-active-border-color: #fff;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #fff;
  --x-btn-disabled-bg: transparent;
  --x-btn-disabled-border-color: #fff;
  --x-gradient: none;
}

.btn-outline-dark {
  --x-btn-color: #16192c;
  --x-btn-border-color: #16192c;
  --x-btn-hover-color: #fff;
  --x-btn-hover-bg: #16192c;
  --x-btn-hover-border-color: #16192c;
  --x-btn-focus-shadow-rgb: 22, 25, 44;
  --x-btn-active-color: #fff;
  --x-btn-active-bg: #16192c;
  --x-btn-active-border-color: #16192c;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #16192c;
  --x-btn-disabled-bg: transparent;
  --x-btn-disabled-border-color: #16192c;
  --x-gradient: none;
}

.btn-link {
  --x-btn-font-weight: 400;
  --x-btn-color: var(--x-link-color);
  --x-btn-bg: transparent;
  --x-btn-border-color: transparent;
  --x-btn-hover-color: var(--x-link-hover-color);
  --x-btn-hover-border-color: transparent;
  --x-btn-active-color: var(--x-link-hover-color);
  --x-btn-active-border-color: transparent;
  --x-btn-disabled-color: #6b7b93;
  --x-btn-disabled-border-color: transparent;
  --x-btn-box-shadow: none;
  --x-btn-focus-shadow-rgb: 116, 120, 247;
  text-decoration: none;
}

.btn-link:hover, .btn-link:focus-visible {
  text-decoration: none;
}

.btn-link:focus-visible {
  color: var(--x-btn-color);
}

.btn-link:hover {
  color: var(--x-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --x-btn-padding-y: 1rem;
  --x-btn-padding-x: 1.5rem;
  --x-btn-font-size: 1rem;
  --x-btn-border-radius: .5rem;
}

.btn-sm, .btn-group-sm > .btn {
  --x-btn-padding-y: .5rem;
  --x-btn-padding-x: 1rem;
  --x-btn-font-size: .875rem;
  --x-btn-border-radius: .375rem;
}

.btn-soft-primary {
  --x-btn-color: #5c60f5;
  --x-btn-bg: #cecffc;
  --x-btn-border-color: #cecffc;
  --x-btn-hover-color: #fff;
  --x-btn-hover-bg: #5c60f5;
  --x-btn-hover-border-color: #fff;
  --x-btn-focus-shadow-rgb: 189, 190, 251;
  --x-btn-active-color: #000;
  --x-btn-active-bg: #d8d9fd;
  --x-btn-active-border-color: #d3d4fc;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #000;
  --x-btn-disabled-bg: #cecffc;
  --x-btn-disabled-border-color: #cecffc;
}

.btn-soft-secondary {
  --x-btn-color: #cfd6df;
  --x-btn-bg: #f1f3f5;
  --x-btn-border-color: #f1f3f5;
  --x-btn-hover-color: #000;
  --x-btn-hover-bg: #cfd6df;
  --x-btn-hover-border-color: #000;
  --x-btn-focus-shadow-rgb: 236, 239, 242;
  --x-btn-active-color: #000;
  --x-btn-active-bg: #f4f5f7;
  --x-btn-active-border-color: #f2f4f6;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #000;
  --x-btn-disabled-bg: #f1f3f5;
  --x-btn-disabled-border-color: #f1f3f5;
}

.btn-soft-tertiary {
  --x-btn-color: #ff579a;
  --x-btn-bg: #ffcde1;
  --x-btn-border-color: #ffcde1;
  --x-btn-hover-color: #fff;
  --x-btn-hover-bg: #ff579a;
  --x-btn-hover-border-color: #fff;
  --x-btn-focus-shadow-rgb: 255, 187, 214;
  --x-btn-active-color: #000;
  --x-btn-active-bg: #ffd7e7;
  --x-btn-active-border-color: #ffd2e4;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #000;
  --x-btn-disabled-bg: #ffcde1;
  --x-btn-disabled-border-color: #ffcde1;
}

.btn-soft-success {
  --x-btn-color: #0c8;
  --x-btn-bg: #b3f0db;
  --x-btn-border-color: #b3f0db;
  --x-btn-hover-color: #fff;
  --x-btn-hover-bg: #0c8;
  --x-btn-hover-border-color: #fff;
  --x-btn-focus-shadow-rgb: 152, 235, 207;
  --x-btn-active-color: #000;
  --x-btn-active-bg: #c2f3e2;
  --x-btn-active-border-color: #bbf2df;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #000;
  --x-btn-disabled-bg: #b3f0db;
  --x-btn-disabled-border-color: #b3f0db;
}

.btn-soft-info {
  --x-btn-color: #00d4ff;
  --x-btn-bg: #b3f2ff;
  --x-btn-border-color: #b3f2ff;
  --x-btn-hover-color: #000;
  --x-btn-hover-bg: #00d4ff;
  --x-btn-hover-border-color: #000;
  --x-btn-focus-shadow-rgb: 152, 238, 255;
  --x-btn-active-color: #000;
  --x-btn-active-bg: #c2f5ff;
  --x-btn-active-border-color: #bbf3ff;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #000;
  --x-btn-disabled-bg: #b3f2ff;
  --x-btn-disabled-border-color: #b3f2ff;
}

.btn-soft-warning {
  --x-btn-color: #ff8c00;
  --x-btn-bg: #ffddb3;
  --x-btn-border-color: #ffddb3;
  --x-btn-hover-color: #fff;
  --x-btn-hover-bg: #ff8c00;
  --x-btn-hover-border-color: #fff;
  --x-btn-focus-shadow-rgb: 255, 209, 152;
  --x-btn-active-color: #000;
  --x-btn-active-bg: #ffe4c2;
  --x-btn-active-border-color: #ffe0bb;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #000;
  --x-btn-disabled-bg: #ffddb3;
  --x-btn-disabled-border-color: #ffddb3;
}

.btn-soft-danger {
  --x-btn-color: #f36;
  --x-btn-bg: #ffc2d1;
  --x-btn-border-color: #ffc2d1;
  --x-btn-hover-color: #fff;
  --x-btn-hover-bg: #f36;
  --x-btn-hover-border-color: #fff;
  --x-btn-focus-shadow-rgb: 255, 173, 193;
  --x-btn-active-color: #000;
  --x-btn-active-bg: #ffceda;
  --x-btn-active-border-color: #ffc8d6;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #000;
  --x-btn-disabled-bg: #ffc2d1;
  --x-btn-disabled-border-color: #ffc2d1;
}

.btn-soft-white, .btn-soft-light {
  --x-btn-color: #fff;
  --x-btn-bg: white;
  --x-btn-border-color: white;
  --x-btn-hover-color: #000;
  --x-btn-hover-bg: #fff;
  --x-btn-hover-border-color: #000;
  --x-btn-focus-shadow-rgb: 255, 255, 255;
  --x-btn-active-color: #000;
  --x-btn-active-bg: #ccc;
  --x-btn-active-border-color: #bfbfbf;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #000;
  --x-btn-disabled-bg: white;
  --x-btn-disabled-border-color: white;
}

.btn-soft-dark {
  --x-btn-color: #16192c;
  --x-btn-bg: #b9bac0;
  --x-btn-border-color: #b9bac0;
  --x-btn-hover-color: #fff;
  --x-btn-hover-bg: #16192c;
  --x-btn-hover-border-color: #fff;
  --x-btn-focus-shadow-rgb: 161, 162, 170;
  --x-btn-active-color: #000;
  --x-btn-active-bg: #c7c8cd;
  --x-btn-active-border-color: #c0c1c6;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #000;
  --x-btn-disabled-bg: #b9bac0;
  --x-btn-disabled-border-color: #b9bac0;
}

.btn-neutral {
  --x-btn-color: #16192c;
  --x-btn-bg: #fff;
  --x-btn-border-color: #e7eaf0;
  --x-btn-hover-color: #16192c;
  --x-btn-hover-bg: #e7eaf0;
  --x-btn-hover-border-color: #e7eaf0;
  --x-btn-focus-shadow-rgb: 200, 203, 211;
  --x-btn-active-color: #16192c;
  --x-btn-active-bg: #e7eaf0;
  --x-btn-active-border-color: #e7eaf0;
  --x-btn-active-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-btn-disabled-color: #000;
  --x-btn-disabled-bg: #fff;
  --x-btn-disabled-border-color: #e7eaf0;
}

.btn-square {
  width: 2.875rem;
  height: 2.875rem;
  justify-content: center;
  align-items: center;
  padding: 0;
  display: inline-flex;
}

.btn-square.btn-xs {
  width: 1.75rem;
  height: 1.75rem;
}

.btn-square.btn-sm, .btn-group-sm > .btn-square.btn {
  width: 2.25rem;
  height: 2.25rem;
}

.btn-square.btn-lg, .btn-group-lg > .btn-square.btn {
  width: 3.5rem;
  height: 3.5rem;
}

.btn-square.btn-xl {
  width: 6rem;
  height: 6rem;
}

.btn-square.btn-2xl {
  width: 7.5rem;
  height: 7.5rem;
}

.btn-app {
  color: #fff;
  text-align: left;
  background: #000;
  padding-left: 4.5rem;
  padding-right: 1.5rem;
  position: relative;
}

.btn-app i, .btn-app svg {
  width: 1em;
  font-size: 2.5rem;
  position: absolute;
  top: 50%;
  left: 1.25rem;
  transform: translateY(-50%);
}

.btn-app .btn-text {
  margin-bottom: .125rem;
  font-size: .75rem;
  line-height: .75rem;
  display: block;
}

.btn-app .btn-brand {
  text-align: left;
  font-size: 21px;
  line-height: 21px;
  display: block;
}

.btn-app:hover {
  color: #fff;
  background-color: #5659e4;
}

.btn-animated {
  position: relative;
  overflow: hidden;
}

.btn-animated .btn-inner-visible {
  position: relative;
}

.btn-animated .btn-inner-hidden {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.btn-animated-x .btn-inner-visible {
  transition: right .3s;
  right: 0;
}

@media (prefers-reduced-motion: reduce) {
  .btn-animated-x .btn-inner-visible {
    transition: none;
  }
}

.btn-animated-x .btn-inner-hidden {
  transition: right .3s;
  right: -100%;
}

@media (prefers-reduced-motion: reduce) {
  .btn-animated-x .btn-inner-hidden {
    transition: none;
  }
}

.btn-animated-x:hover .btn-inner-hidden {
  right: 0;
}

.btn-animated-x:hover .btn-inner-visible {
  right: 150%;
}

.btn-animated-y .btn-inner-visible {
  transition: top .3s;
  top: 0;
}

@media (prefers-reduced-motion: reduce) {
  .btn-animated-y .btn-inner-visible {
    transition: none;
  }
}

.btn-animated-y .btn-inner-hidden {
  transition: top .3s;
  top: -100%;
  left: 0;
}

@media (prefers-reduced-motion: reduce) {
  .btn-animated-y .btn-inner-hidden {
    transition: none;
  }
}

.btn-animated-y:hover .btn-inner-hidden {
  top: 50%;
}

.btn-animated-y:hover .btn-inner-visible {
  top: 100px;
}

.btn-group, .btn-group-vertical {
  vertical-align: middle;
  display: inline-flex;
  position: relative;
}

.btn-group > .btn, .btn-group-vertical > .btn {
  flex: auto;
  position: relative;
}

.btn-group > .btn-check:checked + .btn, .btn-group > .btn-check:focus + .btn, .btn-group > .btn:hover, .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active, .btn-group-vertical > .btn-check:checked + .btn, .btn-group-vertical > .btn-check:focus + .btn, .btn-group-vertical > .btn:hover, .btn-group-vertical > .btn:focus, .btn-group-vertical > .btn:active, .btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  flex-wrap: wrap;
  justify-content: flex-start;
  display: flex;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: .375rem;
}

.btn-group > :not(.btn-check:first-child) + .btn, .btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .btn.dropdown-toggle-split:first-child, .btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:nth-child(n+3), .btn-group > :not(.btn-check) + .btn, .btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-left: .9375rem;
  padding-right: .9375rem;
}

.dropdown-toggle-split:after, .dropup .dropdown-toggle-split:after, .dropend .dropdown-toggle-split:after {
  margin-left: 0;
}

.dropstart .dropdown-toggle-split:before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-left: .75rem;
  padding-right: .75rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-left: 1.125rem;
  padding-right: 1.125rem;
}

.btn-group.show .dropdown-toggle {
  box-shadow: 0 0 rgba(0, 0, 0, 0);
}

.btn-group.show .dropdown-toggle.btn-link {
  box-shadow: none;
}

.btn-group-vertical {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.btn-group-vertical > .btn, .btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child), .btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle), .btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn ~ .btn, .btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.carousel-inner:after {
  clear: both;
  content: "";
  display: block;
}

.carousel-item {
  float: left;
  width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  margin-right: -100%;
  transition: transform .6s ease-in-out;
  display: none;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active, .carousel-item-next, .carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start), .active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end), .active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active, .carousel-fade .carousel-item-next.carousel-item-start, .carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-start, .carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s .6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start, .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev, .carousel-control-next {
  z-index: 1;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: .5;
  background: none;
  border: 0;
  justify-content: center;
  align-items: center;
  padding: 0;
  transition: opacity .15s;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev, .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus, .carousel-control-next:hover, .carousel-control-next:focus {
  color: #fff;
  opacity: .9;
  outline: 0;
  text-decoration: none;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon, .carousel-control-next-icon {
  width: 2rem;
  height: 2rem;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: inline-block;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFF'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFF'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  z-index: 2;
  justify-content: center;
  margin-bottom: 1rem;
  margin-left: 15%;
  margin-right: 15%;
  padding: 0;
  list-style: none;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  width: 30px;
  height: 3px;
  text-indent: -999px;
  cursor: pointer;
  opacity: .5;
  background-color: #fff;
  background-clip: padding-box;
  border: 10px solid rgba(0, 0, 0, 0);
  border-left: 0;
  border-right: 0;
  flex: 0 auto;
  margin-left: 3px;
  margin-right: 3px;
  padding: 0;
  transition: opacity .6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  color: #fff;
  text-align: center;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  position: absolute;
  bottom: 1.25rem;
  left: 15%;
  right: 15%;
}

.carousel-dark .carousel-control-prev-icon, .carousel-dark .carousel-control-next-icon {
  filter: invert() grayscale(100);
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}

.carousel-dark .carousel-caption {
  color: #000;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  color: currentColor;
  opacity: .5;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='currentColor'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center / 1em no-repeat;
  border: 0;
  border-radius: .375rem;
  padding: .25em;
}

.btn-close:hover {
  color: currentColor;
  opacity: .75;
  text-decoration: none;
}

.btn-close:focus {
  opacity: 1;
  outline: 0;
  box-shadow: 0 0 0 3px rgba(92, 96, 245, .25);
}

.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  opacity: .25;
}

.btn-close-white {
  filter: invert() grayscale() brightness(200%);
}

.dropup, .dropend, .dropdown, .dropstart, .dropup-center, .dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid rgba(0, 0, 0, 0);
  border-top-color: currentColor;
  border-bottom: 0;
  margin-left: .255em;
  display: inline-block;
}

.dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropdown-menu {
  --x-dropdown-zindex: 1000;
  --x-dropdown-min-width: 12rem;
  --x-dropdown-padding-x: 0rem;
  --x-dropdown-padding-y: .5rem;
  --x-dropdown-spacer: .125rem;
  --x-dropdown-font-size: .875rem;
  --x-dropdown-color: #525f7f;
  --x-dropdown-bg: #fff;
  --x-dropdown-border-color: #f0f2f6;
  --x-dropdown-border-radius: .75rem;
  --x-dropdown-border-width: 1px;
  --x-dropdown-inner-border-radius: calc(.75rem - 1px);
  --x-dropdown-divider-bg: #f2f3f7;
  --x-dropdown-divider-margin-y: .5rem;
  --x-dropdown-box-shadow: 0px 16px 16px -1px rgba(10, 22, 70, .1), 0px 0px 1px 0px rgba(10, 22, 70, .06);
  --x-dropdown-link-color: #16192c;
  --x-dropdown-link-hover-color: theme-color("primary");
  --x-dropdown-link-hover-bg: transparent;
  --x-dropdown-link-active-color: theme-color("primary");
  --x-dropdown-link-active-bg: transparent;
  --x-dropdown-link-disabled-color: #8898a9;
  --x-dropdown-item-padding-x: 1rem;
  --x-dropdown-item-padding-y: .5rem;
  --x-dropdown-header-color: #6b7b93;
  --x-dropdown-header-padding-x: 1rem;
  --x-dropdown-header-padding-y: .5rem;
  z-index: var(--x-dropdown-zindex);
  min-width: var(--x-dropdown-min-width);
  padding: var(--x-dropdown-padding-y) var(--x-dropdown-padding-x);
  font-size: var(--x-dropdown-font-size);
  color: var(--x-dropdown-color);
  text-align: left;
  background-color: var(--x-dropdown-bg);
  border: var(--x-dropdown-border-width) solid var(--x-dropdown-border-color);
  border-radius: var(--x-dropdown-border-radius);
  box-shadow: var(--x-dropdown-box-shadow);
  background-clip: padding-box;
  margin: 0;
  list-style: none;
  display: none;
  position: absolute;
}

.dropdown-menu[data-bs-popper] {
  margin-top: var(--x-dropdown-spacer);
  top: 100%;
  left: 0;
}

.dropdown-menu-start {
  --bs-position: start;
}

.dropdown-menu-start[data-bs-popper] {
  left: 0;
  right: auto;
}

.dropdown-menu-end {
  --bs-position: end;
}

.dropdown-menu-end[data-bs-popper] {
  left: auto;
  right: 0;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }

  .dropdown-menu-sm-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }

  .dropdown-menu-sm-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }

  .dropdown-menu-md-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }

  .dropdown-menu-md-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }

  .dropdown-menu-lg-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }

  .dropdown-menu-lg-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }

  .dropdown-menu-xl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }

  .dropdown-menu-xl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }

  .dropdown-menu-xxl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }

  .dropdown-menu-xxl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

.dropup .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-bottom: var(--x-dropdown-spacer);
  top: auto;
  bottom: 100%;
}

.dropup .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid rgba(0, 0, 0, 0);
  border-top: 0;
  border-bottom-color: currentColor;
  margin-left: .255em;
  display: inline-block;
}

.dropup .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-left: var(--x-dropdown-spacer);
  top: 0;
  left: 100%;
  right: auto;
}

.dropend .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid rgba(0, 0, 0, 0);
  border-left-color: currentColor;
  border-right: 0;
  margin-left: .255em;
  display: inline-block;
}

.dropend .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropend .dropdown-toggle:after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-right: var(--x-dropdown-spacer);
  top: 0;
  left: auto;
  right: 100%;
}

.dropstart .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  margin-left: .255em;
  display: none;
}

.dropstart .dropdown-toggle:before {
  vertical-align: .255em;
  content: "";
  border-top: .3em solid rgba(0, 0, 0, 0);
  border-bottom: .3em solid rgba(0, 0, 0, 0);
  border-right: .3em solid;
  margin-right: .255em;
  display: inline-block;
}

.dropstart .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropstart .dropdown-toggle:before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--x-dropdown-divider-margin-y) 0;
  border-top: 1px solid var(--x-dropdown-divider-bg);
  opacity: 1;
  overflow: hidden;
}

.dropdown-item {
  width: 100%;
  padding: var(--x-dropdown-item-padding-y) var(--x-dropdown-item-padding-x);
  clear: both;
  color: var(--x-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  font-weight: 400;
  display: block;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: var(--x-dropdown-link-hover-color);
  background-color: var(--x-dropdown-link-hover-bg);
}

.dropdown-item.active, .dropdown-item:active {
  color: var(--x-dropdown-link-active-color);
  background-color: var(--x-dropdown-link-active-bg);
  text-decoration: none;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--x-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0);
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  padding: var(--x-dropdown-header-padding-y) var(--x-dropdown-header-padding-x);
  color: var(--x-dropdown-header-color);
  white-space: nowrap;
  margin-bottom: 0;
  font-size: .875rem;
  display: block;
}

.dropdown-item-text {
  padding: var(--x-dropdown-item-padding-y) var(--x-dropdown-item-padding-x);
  color: var(--x-dropdown-link-color);
  display: block;
}

.dropdown-menu-dark {
  --x-dropdown-color: #cfd6df;
  --x-dropdown-bg: #2d3748;
  --x-dropdown-border-color: #f0f2f6;
  --x-dropdown-box-shadow: ;
  --x-dropdown-link-color: #cfd6df;
  --x-dropdown-link-hover-color: #fff;
  --x-dropdown-divider-bg: #f2f3f7;
  --x-dropdown-link-hover-bg: rgba(255, 255, 255, .15);
  --x-dropdown-link-active-color: theme-color("primary");
  --x-dropdown-link-active-bg: transparent;
  --x-dropdown-link-disabled-color: #8898a9;
  --x-dropdown-header-color: #8898a9;
}

.dropdown, .dropup, .dropright, .dropleft {
  display: inline-block;
}

.dropdown-heading {
  color: #16192c;
  font-size: 1rem;
  font-weight: 500;
}

.dropdown-helper {
  color: #6b7b93;
}

.dropdown-group {
  display: block;
}

.dropdown-group:hover .dropdown-heading {
  color: #5c60f5;
}

.dropdown-img-left {
  min-height: 200px;
  height: 100%;
  background: center / cover no-repeat;
  border-top-left-radius: .75rem;
  border-bottom-left-radius: .75rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.625rem 1.75rem;
  display: flex;
  position: relative;
}

.dropdown-img-left.dropdown-img-mask:before {
  content: "";
  border-radius: inherit;
  background-color: rgba(92, 96, 245, .7);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.dropdown-secondary {
  background-color: #f5f9fc;
}

.dropdown-body-left {
  border-top-left-radius: .75rem;
  border-bottom-left-radius: .75rem;
}

.dropdown-body-right {
  border-top-right-radius: .75rem;
  border-bottom-right-radius: .75rem;
}

.dropdown-menu-fluid {
  width: calc(100% - var(--x-gutter-x, 1.5rem) * 2);
  left: var(--x-gutter-x, 1.5rem) !important;
}

.dropdown-menu-fluid[data-bs-popper] {
  left: var(--x-gutter-x, 1.5rem);
}

@media (min-width: 576px) {
  .dropdown-menu-sm-fluid {
    width: calc(100% - var(--x-gutter-x, 1.5rem) * 2);
    left: var(--x-gutter-x, 1.5rem) !important;
  }

  .dropdown-menu-sm-fluid[data-bs-popper] {
    left: var(--x-gutter-x, 1.5rem);
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-fluid {
    width: calc(100% - var(--x-gutter-x, 1.5rem) * 2);
    left: var(--x-gutter-x, 1.5rem) !important;
  }

  .dropdown-menu-md-fluid[data-bs-popper] {
    left: var(--x-gutter-x, 1.5rem);
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-fluid {
    width: calc(100% - var(--x-gutter-x, 1.5rem) * 2);
    left: var(--x-gutter-x, 1.5rem) !important;
  }

  .dropdown-menu-lg-fluid[data-bs-popper] {
    left: var(--x-gutter-x, 1.5rem);
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-fluid {
    width: calc(100% - var(--x-gutter-x, 1.5rem) * 2);
    left: var(--x-gutter-x, 1.5rem) !important;
  }

  .dropdown-menu-xl-fluid[data-bs-popper] {
    left: var(--x-gutter-x, 1.5rem);
  }
}

@media (min-width: 1400px) {
  .dropdown-menu-xxl-fluid {
    width: calc(100% - var(--x-gutter-x, 1.5rem) * 2);
    left: var(--x-gutter-x, 1.5rem) !important;
  }

  .dropdown-menu-xxl-fluid[data-bs-popper] {
    left: var(--x-gutter-x, 1.5rem);
  }
}

.dropdown-submenu .dropdown-menu {
  min-width: 16rem;
  margin-left: 1rem;
}

.dropdown-submenu .dropdown-menu:before {
  content: "";
  height: 100%;
  border-left: 1.5rem solid rgba(0, 0, 0, 0);
  position: absolute;
  top: 0;
  left: -1rem;
}

.dropdown-body {
  padding: 2rem 2.5rem;
}

.delimiter {
  border-right: 1px solid #e7eaf0;
}

@media (min-width: 768px) {
  .dropdown-menu-sm {
    min-width: 10rem !important;
  }

  .dropdown-menu-sm.dropdown-menu-arrow.dropdown-menu-right:before {
    left: auto;
    right: 2rem;
  }

  .dropdown-menu-md {
    min-width: 25rem !important;
  }

  .dropdown-menu-lg {
    min-width: 30rem !important;
  }

  .dropdown-menu-xl {
    min-width: 40rem !important;
  }

  .dropdown-menu-2xl {
    min-width: 50rem !important;
  }

  .dropdown-menu-centered {
    transform: translateX(-50%)translateY(-3px)scale(.96);
    left: 50% !important;
  }
}

.dropdown-animate > .dropdown-menu {
  opacity: 0;
  pointer-events: none;
  display: block;
  position: absolute;
}

.dropdown-animate:not([data-toggle="hover"]) .dropdown-menu.show {
  pointer-events: auto;
  animation: .3s forwards show-dropdown;
}

.dropdown-animate:not([data-toggle="hover"]) .dropdown-menu:not(.show) {
  animation: .3s backwards hide-dropdown;
  display: block;
}

.dropdown-animate[data-toggle="hover"] > .dropdown-menu {
  margin: 0;
  transition: all .2s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .dropdown-animate[data-toggle="hover"] > .dropdown-menu {
    transition: none;
  }
}

.dropdown-animate[data-toggle="hover"]:hover > .dropdown-menu {
  opacity: 1;
  pointer-events: auto;
  visibility: visible;
  display: block;
  transform: translateX(0)translateY(-6px)scale(1);
}

@media (min-width: 576px) {
  .dropdown-sm-animate > .dropdown-menu {
    opacity: 0;
    pointer-events: none;
    display: block;
    position: absolute;
  }

  .dropdown-sm-animate:not([data-toggle="hover"]) .dropdown-menu.show {
    pointer-events: auto;
    animation: .3s forwards show-dropdown;
  }

  .dropdown-sm-animate:not([data-toggle="hover"]) .dropdown-menu:not(.show) {
    animation: .3s backwards hide-dropdown;
    display: block;
  }

  .dropdown-sm-animate[data-toggle="hover"] > .dropdown-menu {
    margin: 0;
    transition: all .2s ease-in-out;
    display: block;
  }
}

@media (min-width: 576px) and (prefers-reduced-motion: reduce) {
  .dropdown-sm-animate[data-toggle="hover"] > .dropdown-menu {
    transition: none;
  }
}

@media (min-width: 576px) {
  .dropdown-sm-animate[data-toggle="hover"]:hover > .dropdown-menu {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    display: block;
    transform: translateX(0)translateY(-6px)scale(1);
  }
}

@media (min-width: 768px) {
  .dropdown-md-animate > .dropdown-menu {
    opacity: 0;
    pointer-events: none;
    display: block;
    position: absolute;
  }

  .dropdown-md-animate:not([data-toggle="hover"]) .dropdown-menu.show {
    pointer-events: auto;
    animation: .3s forwards show-dropdown;
  }

  .dropdown-md-animate:not([data-toggle="hover"]) .dropdown-menu:not(.show) {
    animation: .3s backwards hide-dropdown;
    display: block;
  }

  .dropdown-md-animate[data-toggle="hover"] > .dropdown-menu {
    margin: 0;
    transition: all .2s ease-in-out;
    display: block;
  }
}

@media (min-width: 768px) and (prefers-reduced-motion: reduce) {
  .dropdown-md-animate[data-toggle="hover"] > .dropdown-menu {
    transition: none;
  }
}

@media (min-width: 768px) {
  .dropdown-md-animate[data-toggle="hover"]:hover > .dropdown-menu {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    display: block;
    transform: translateX(0)translateY(-6px)scale(1);
  }
}

@media (min-width: 992px) {
  .dropdown-lg-animate > .dropdown-menu {
    opacity: 0;
    pointer-events: none;
    display: block;
    position: absolute;
  }

  .dropdown-lg-animate:not([data-toggle="hover"]) .dropdown-menu.show {
    pointer-events: auto;
    animation: .3s forwards show-dropdown;
  }

  .dropdown-lg-animate:not([data-toggle="hover"]) .dropdown-menu:not(.show) {
    animation: .3s backwards hide-dropdown;
    display: block;
  }

  .dropdown-lg-animate[data-toggle="hover"] > .dropdown-menu {
    margin: 0;
    transition: all .2s ease-in-out;
    display: block;
  }
}

@media (min-width: 992px) and (prefers-reduced-motion: reduce) {
  .dropdown-lg-animate[data-toggle="hover"] > .dropdown-menu {
    transition: none;
  }
}

@media (min-width: 992px) {
  .dropdown-lg-animate[data-toggle="hover"]:hover > .dropdown-menu {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    display: block;
    transform: translateX(0)translateY(-6px)scale(1);
  }
}

@media (min-width: 1200px) {
  .dropdown-xl-animate > .dropdown-menu {
    opacity: 0;
    pointer-events: none;
    display: block;
    position: absolute;
  }

  .dropdown-xl-animate:not([data-toggle="hover"]) .dropdown-menu.show {
    pointer-events: auto;
    animation: .3s forwards show-dropdown;
  }

  .dropdown-xl-animate:not([data-toggle="hover"]) .dropdown-menu:not(.show) {
    animation: .3s backwards hide-dropdown;
    display: block;
  }

  .dropdown-xl-animate[data-toggle="hover"] > .dropdown-menu {
    margin: 0;
    transition: all .2s ease-in-out;
    display: block;
  }
}

@media (min-width: 1200px) and (prefers-reduced-motion: reduce) {
  .dropdown-xl-animate[data-toggle="hover"] > .dropdown-menu {
    transition: none;
  }
}

@media (min-width: 1200px) {
  .dropdown-xl-animate[data-toggle="hover"]:hover > .dropdown-menu {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    display: block;
    transform: translateX(0)translateY(-6px)scale(1);
  }
}

@media (min-width: 1400px) {
  .dropdown-xxl-animate > .dropdown-menu {
    opacity: 0;
    pointer-events: none;
    display: block;
    position: absolute;
  }

  .dropdown-xxl-animate:not([data-toggle="hover"]) .dropdown-menu.show {
    pointer-events: auto;
    animation: .3s forwards show-dropdown;
  }

  .dropdown-xxl-animate:not([data-toggle="hover"]) .dropdown-menu:not(.show) {
    animation: .3s backwards hide-dropdown;
    display: block;
  }

  .dropdown-xxl-animate[data-toggle="hover"] > .dropdown-menu {
    margin: 0;
    transition: all .2s ease-in-out;
    display: block;
  }
}

@media (min-width: 1400px) and (prefers-reduced-motion: reduce) {
  .dropdown-xxl-animate[data-toggle="hover"] > .dropdown-menu {
    transition: none;
  }
}

@media (min-width: 1400px) {
  .dropdown-xxl-animate[data-toggle="hover"]:hover > .dropdown-menu {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    display: block;
    transform: translateX(0)translateY(-6px)scale(1);
  }
}

@keyframes show-dropdown {
  0% {
    opacity: 0;
    transition: all .3s;
    transform: translateX(0)translateY(-3px)scale(.97);
  }

  100% {
    opacity: 1;
    transform: translateX(0)translateY(-3px)scale(1);
  }
}

@keyframes hide-dropdown {
  from {
    opacity: 1;
    transition: all .3s;
    transform: translateX(0)translateY(-3px)scale(1);
  }

  to {
    opacity: 0;
    transform: translateX(0)translateY(-3px)scale(.97);
  }
}

.dropdown-menu .dropdown-menu {
  margin: 0;
  top: 0;
  left: 100%;
  right: auto;
}

.dropdown-submenu {
  display: block;
  position: relative;
}

.dropdown-submenu > .dropdown-menu:after {
  border: 0 !important;
}

.dropdown-submenu > .dropdown-item:after, .dropdown-submenu > .list-group-item:after {
  vertical-align: .255em;
  content: "";
  margin-left: .255em;
  display: inline-block;
}

.dropdown-submenu > .dropdown-item:empty:after, .dropdown-submenu > .list-group-item:empty:after {
  margin-left: 0;
}

.dropdown-submenu > .dropdown-item:after, .dropdown-submenu > .list-group-item:after {
  margin-left: 0;
  font-size: .75rem;
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
}

.dropdown-submenu[data-toggle="hover"] > .dropdown-menu {
  left: 100%;
  transform: translateX(0)translateY(-3px)scale(.97);
}

.dropdown-submenu[data-toggle="hover"]:hover > .dropdown-menu {
  transform: translateX(0)translateY(-3px)scale(1);
}

@media (max-width: 767.98px) {
  .dropdown-submenu .dropdown-menu {
    box-shadow: none;
    padding: 0;
    top: 0;
  }

  .dropdown-submenu .dropdown-item {
    padding-left: 3rem;
  }
}

.frame-laptop {
  position: relative;
}

.frame-laptop .frame-inner {
  width: 70%;
  height: 78%;
  margin: 2.5% 15% 0;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.frame-iphone {
  z-index: 100;
  position: relative;
}

.frame-iphone .frame-inner {
  z-index: -1;
  width: 92%;
  height: 96%;
  border-radius: 5%;
  margin: 4% 4% 0;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.frame-stack {
  position: relative;
}

.frame-stack .frame {
  position: absolute;
}

.frame-stack-iphone-iphone {
  padding-bottom: 130.25%;
}

.frame-stack-iphone-iphone > .frame-iphone:first-child {
  width: 65.526%;
  z-index: 1;
  bottom: 0;
  left: 0;
}

.frame-stack-iphone-iphone > .frame-iphone:last-child {
  width: 72.8324%;
  top: 0;
  right: 0;
}

.frame-stack-iphone-laptop, .frame-stack-laptop-iphone {
  padding-bottom: 62.426%;
}

.frame-stack-iphone-laptop > .frame-laptop, .frame-stack-laptop-iphone > .frame-laptop {
  width: 91.716%;
}

.frame-stack-iphone-laptop > .frame-iphone, .frame-stack-laptop-iphone > .frame-iphone {
  width: 27.9586%;
  z-index: 1;
}

.frame-stack-laptop-iphone > .frame-laptop {
  top: 0;
  left: 0;
}

.frame-stack-iphone-laptop > .frame-laptop {
  top: 0;
  right: 0;
}

.frame-stack-laptop-iphone > .frame-iphone {
  bottom: 0;
  right: 0;
}

.frame-stack-iphone-laptop > .frame-iphone {
  bottom: 0;
  left: 0;
}

.icon {
  font-size: 1.71429rem;
  line-height: 1;
}

.icon svg {
  width: 1em;
  height: 1em;
}

.icon-xl {
  font-size: 2.85714rem;
}

.icon-lg {
  font-size: 2.28571rem;
}

.icon-sm {
  font-size: 1.14286rem;
}

.icon-xs {
  font-size: .714286rem;
}

.icon-shape {
  text-align: center;
  vertical-align: middle;
  width: 3rem;
  height: 3rem;
  border-radius: .375rem;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.icon-shape.icon-xl {
  width: 5rem;
  height: 5rem;
}

.icon-shape.icon-lg {
  width: 4rem;
  height: 4rem;
}

.icon-shape.icon-sm {
  width: 2rem;
  height: 2rem;
}

.icon-shape.icon-xs {
  width: 1.25rem;
  height: 1.25rem;
}

.list-group {
  --x-list-group-color: #16192c;
  --x-list-group-bg: transparent;
  --x-list-group-border-color: #e7eaf0;
  --x-list-group-border-width: 1px;
  --x-list-group-border-radius: .375rem;
  --x-list-group-item-padding-x: 1.5rem;
  --x-list-group-item-padding-y: 1.125rem;
  --x-list-group-action-color: #525f7f;
  --x-list-group-action-hover-color: #525f7f;
  --x-list-group-action-hover-bg: #f5f9fc;
  --x-list-group-action-active-color: #525f7f;
  --x-list-group-action-active-bg: #e7eaf0;
  --x-list-group-disabled-color: #6b7b93;
  --x-list-group-disabled-bg: transparent;
  --x-list-group-active-color: #525f7f;
  --x-list-group-active-bg: #f5f9fc;
  --x-list-group-active-border-color: #e7eaf0;
  border-radius: var(--x-list-group-border-radius);
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
}

.list-group-numbered {
  counter-reset: section;
  list-style-type: none;
}

.list-group-numbered > .list-group-item:before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--x-list-group-action-color);
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--x-list-group-action-hover-color);
  background-color: var(--x-list-group-action-hover-bg);
  text-decoration: none;
}

.list-group-item-action:active {
  color: var(--x-list-group-action-active-color);
  background-color: var(--x-list-group-action-active-bg);
}

.list-group-item {
  padding: var(--x-list-group-item-padding-y) var(--x-list-group-item-padding-x);
  color: var(--x-list-group-color);
  background-color: var(--x-list-group-bg);
  border: var(--x-list-group-border-width) solid var(--x-list-group-border-color);
  display: block;
  position: relative;
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: var(--x-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--x-list-group-disabled-bg);
}

.list-group-item.active {
  z-index: 2;
  color: var(--x-list-group-active-color);
  background-color: var(--x-list-group-active-bg);
  border-color: var(--x-list-group-active-border-color);
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--x-list-group-border-width));
  border-top-width: var(--x-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--x-list-group-border-radius);
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--x-list-group-border-radius);
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--x-list-group-border-width);
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--x-list-group-border-width));
  border-left-width: var(--x-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }

  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--x-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--x-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--x-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--x-list-group-border-width));
    border-left-width: var(--x-list-group-border-width);
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }

  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--x-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--x-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--x-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--x-list-group-border-width));
    border-left-width: var(--x-list-group-border-width);
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }

  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--x-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--x-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--x-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--x-list-group-border-width));
    border-left-width: var(--x-list-group-border-width);
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }

  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--x-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--x-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--x-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--x-list-group-border-width));
    border-left-width: var(--x-list-group-border-width);
  }
}

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }

  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--x-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--x-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--x-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--x-list-group-border-width));
    border-left-width: var(--x-list-group-border-width);
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 var(--x-list-group-border-width);
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #373a93;
  background-color: #dedffd;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #373a93;
  background-color: #c8c9e4;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #373a93;
  border-color: #373a93;
}

.list-group-item-secondary {
  color: #7c8086;
  background-color: #f5f7f9;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #7c8086;
  background-color: #dddee0;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #7c8086;
  border-color: #7c8086;
}

.list-group-item-tertiary {
  color: #99345c;
  background-color: #ffddeb;
}

.list-group-item-tertiary.list-group-item-action:hover, .list-group-item-tertiary.list-group-item-action:focus {
  color: #99345c;
  background-color: #e6c7d4;
}

.list-group-item-tertiary.list-group-item-action.active {
  color: #fff;
  background-color: #99345c;
  border-color: #99345c;
}

.list-group-item-success {
  color: #007a52;
  background-color: #ccf5e7;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #007a52;
  background-color: #b8ddd0;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #007a52;
  border-color: #007a52;
}

.list-group-item-info {
  color: #007f99;
  background-color: #ccf6ff;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #007f99;
  background-color: #b8dde6;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #007f99;
  border-color: #007f99;
}

.list-group-item-warning {
  color: #995400;
  background-color: #ffe8cc;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #995400;
  background-color: #e6d1b8;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #995400;
  border-color: #995400;
}

.list-group-item-danger {
  color: #991f3d;
  background-color: #ffd6e0;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #991f3d;
  background-color: #e6c1ca;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #991f3d;
  border-color: #991f3d;
}

.list-group-item-white {
  color: #999;
  background-color: #fff;
}

.list-group-item-white.list-group-item-action:hover, .list-group-item-white.list-group-item-action:focus {
  color: #999;
  background-color: #e6e6e6;
}

.list-group-item-white.list-group-item-action.active {
  color: #fff;
  background-color: #999;
  border-color: #999;
}

.list-group-item-light {
  color: #999;
  background-color: #fff;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #999;
  background-color: #e6e6e6;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #999;
  border-color: #999;
}

.list-group-item-dark {
  color: #0d0f1a;
  background-color: #d0d1d5;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #0d0f1a;
  background-color: #bbbcc0;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #0d0f1a;
  border-color: #0d0f1a;
}

.list-group {
  list-style-type: none;
}

.list-group-flush > .list-group-item {
  padding-left: 0;
  padding-right: 0;
}

.list-group-borderless > .list-group-item {
  border: 0;
}

.list-group-space .list-group-item {
  border-radius: .375rem;
  margin-bottom: 1.5rem;
}

.list-group-item {
  font-size: .875rem;
}

.list-group-img {
  width: 3rem;
  height: 3rem;
  vertical-align: top;
  border-radius: 50%;
  margin: -.1rem 1.2rem 0 -.2rem;
}

.list-group-content {
  min-width: 0;
  flex: 1;
}

.list-group-content p {
  margin: .2rem 0 0;
  line-height: 1.5;
}

.list-group-heading {
  color: #2d3748;
}

.list-group-heading > small, .list-group-heading > .small {
  float: right;
  color: #8898a9;
  font-weight: 500;
}

.list-group-sm .list-group-item {
  padding: .675rem 1.25rem;
  font-size: .875rem;
}

.list-group-emphasized .list-group-item {
  transition: all .2s ease-in-out;
  transform: scale(1);
}

@media (prefers-reduced-motion: reduce) {
  .list-group-emphasized .list-group-item {
    transition: none;
  }
}

.list-group-emphasized .list-group-item.active {
  z-index: 11;
  background-color: #f5f9fc;
  border-radius: .375rem;
  transform: scale(1.05);
  box-shadow: 0 3px 3px -1px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06);
}

.list-group-emphasized .list-group-item.active .media a {
  color: theme-color("primary");
}

.modal {
  --x-modal-zindex: 1055;
  --x-modal-width: 500px;
  --x-modal-padding: 1.5rem;
  --x-modal-margin: .5rem;
  --x-modal-color: ;
  --x-modal-bg: #fff;
  --x-modal-border-color: #e7eaf0;
  --x-modal-border-width: 1px;
  --x-modal-border-radius: .75rem;
  --x-modal-box-shadow: 0 .25rem .5rem rgba(0, 0, 0, .3);
  --x-modal-inner-border-radius: calc(.75rem - 1px);
  --x-modal-header-padding-x: 1.5rem;
  --x-modal-header-padding-y: 1.5rem;
  --x-modal-header-padding: 1.5rem 1.5rem;
  --x-modal-header-border-color: #e7eaf0;
  --x-modal-header-border-width: 1px;
  --x-modal-title-line-height: 1.625;
  --x-modal-footer-gap: 1rem;
  --x-modal-footer-bg: ;
  --x-modal-footer-border-color: #e7eaf0;
  --x-modal-footer-border-width: 1px;
  z-index: var(--x-modal-zindex);
  width: 100%;
  height: 100%;
  outline: 0;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  width: auto;
  margin: var(--x-modal-margin);
  pointer-events: none;
  position: relative;
}

.modal.fade .modal-dialog {
  transition: transform .3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--x-modal-margin) * 2);
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  min-height: calc(100% - var(--x-modal-margin) * 2);
  align-items: center;
  display: flex;
}

.modal-content {
  width: 100%;
  color: var(--x-modal-color);
  pointer-events: auto;
  background-color: var(--x-modal-bg);
  border: var(--x-modal-border-width) solid var(--x-modal-border-color);
  border-radius: var(--x-modal-border-radius);
  box-shadow: var(--x-modal-box-shadow);
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  display: flex;
  position: relative;
}

.modal-backdrop {
  --x-backdrop-zindex: 1050;
  --x-backdrop-bg: #000;
  --x-backdrop-opacity: .4;
  z-index: var(--x-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--x-backdrop-bg);
  position: fixed;
  top: 0;
  left: 0;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: var(--x-backdrop-opacity);
}

.modal-header {
  padding: var(--x-modal-header-padding);
  border-bottom: var(--x-modal-header-border-width) solid var(--x-modal-header-border-color);
  border-top-left-radius: var(--x-modal-inner-border-radius);
  border-top-right-radius: var(--x-modal-inner-border-radius);
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.modal-header .btn-close {
  padding: calc(var(--x-modal-header-padding-y) * .5) calc(var(--x-modal-header-padding-x) * .5);
  margin: calc(-.5 * var(--x-modal-header-padding-y)) calc(-.5 * var(--x-modal-header-padding-x)) calc(-.5 * var(--x-modal-header-padding-y)) auto;
}

.modal-title {
  line-height: var(--x-modal-title-line-height);
  margin-bottom: 0;
}

.modal-body {
  padding: var(--x-modal-padding);
  flex: auto;
  position: relative;
}

.modal-footer {
  padding: calc(var(--x-modal-padding)  - var(--x-modal-footer-gap) * .5);
  background-color: var(--x-modal-footer-bg);
  border-top: var(--x-modal-footer-border-width) solid var(--x-modal-footer-border-color);
  border-bottom-right-radius: var(--x-modal-inner-border-radius);
  border-bottom-left-radius: var(--x-modal-inner-border-radius);
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.modal-footer > * {
  margin: calc(var(--x-modal-footer-gap) * .5);
}

@media (min-width: 576px) {
  .modal {
    --x-modal-margin: 1.75rem;
    --x-modal-box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .3);
  }

  .modal-dialog {
    max-width: var(--x-modal-width);
    margin-left: auto;
    margin-right: auto;
  }

  .modal-sm {
    --x-modal-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg, .modal-xl {
    --x-modal-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    --x-modal-width: 1140px;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.modal-fullscreen .modal-header, .modal-fullscreen .modal-footer {
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-header, .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-header, .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-header, .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-header, .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-header, .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}

.modal-content {
  border: 0;
  border-radius: .75rem;
}

.modal-fluid .modal-dialog {
  margin-top: 0;
  margin-bottom: 0;
}

.modal-fluid .modal-content {
  border-radius: 0;
}

.modal-open {
  max-height: 100vh;
}

.modal-dialog-aside {
  margin: 0;
  position: absolute;
}

.modal-primary .modal-title {
  color: #fff;
}

.modal-primary .modal-header, .modal-primary .modal-footer {
  border-color: rgba(255, 255, 255, .075);
}

.modal-primary .modal-content {
  color: #fff;
  background-color: #5c60f5;
}

.modal-primary .modal-content .heading, .modal-primary .close > span:not(.sr-only) {
  color: #fff;
}

.modal-secondary .modal-title {
  color: #000;
}

.modal-secondary .modal-header, .modal-secondary .modal-footer {
  border-color: rgba(0, 0, 0, .075);
}

.modal-secondary .modal-content {
  color: #000;
  background-color: #cfd6df;
}

.modal-secondary .modal-content .heading {
  color: #000;
}

.modal-secondary .close > span:not(.sr-only), .modal-tertiary .modal-title {
  color: #fff;
}

.modal-tertiary .modal-header, .modal-tertiary .modal-footer {
  border-color: rgba(255, 255, 255, .075);
}

.modal-tertiary .modal-content {
  color: #fff;
  background-color: #ff579a;
}

.modal-tertiary .modal-content .heading, .modal-tertiary .close > span:not(.sr-only), .modal-success .modal-title {
  color: #fff;
}

.modal-success .modal-header, .modal-success .modal-footer {
  border-color: rgba(255, 255, 255, .075);
}

.modal-success .modal-content {
  color: #fff;
  background-color: #0c8;
}

.modal-success .modal-content .heading, .modal-success .close > span:not(.sr-only) {
  color: #fff;
}

.modal-info .modal-title {
  color: #000;
}

.modal-info .modal-header, .modal-info .modal-footer {
  border-color: rgba(0, 0, 0, .075);
}

.modal-info .modal-content {
  color: #000;
  background-color: #00d4ff;
}

.modal-info .modal-content .heading {
  color: #000;
}

.modal-info .close > span:not(.sr-only), .modal-warning .modal-title {
  color: #fff;
}

.modal-warning .modal-header, .modal-warning .modal-footer {
  border-color: rgba(255, 255, 255, .075);
}

.modal-warning .modal-content {
  color: #fff;
  background-color: #ff8c00;
}

.modal-warning .modal-content .heading, .modal-warning .close > span:not(.sr-only), .modal-danger .modal-title {
  color: #fff;
}

.modal-danger .modal-header, .modal-danger .modal-footer {
  border-color: rgba(255, 255, 255, .075);
}

.modal-danger .modal-content {
  color: #fff;
  background-color: #f36;
}

.modal-danger .modal-content .heading, .modal-danger .close > span:not(.sr-only) {
  color: #fff;
}

.modal-white .modal-title {
  color: #000;
}

.modal-white .modal-header, .modal-white .modal-footer {
  border-color: rgba(0, 0, 0, .075);
}

.modal-white .modal-content {
  color: #000;
  background-color: #fff;
}

.modal-white .modal-content .heading {
  color: #000;
}

.modal-white .close > span:not(.sr-only) {
  color: #fff;
}

.modal-light .modal-title {
  color: #000;
}

.modal-light .modal-header, .modal-light .modal-footer {
  border-color: rgba(0, 0, 0, .075);
}

.modal-light .modal-content {
  color: #000;
  background-color: #fff;
}

.modal-light .modal-content .heading {
  color: #000;
}

.modal-light .close > span:not(.sr-only), .modal-dark .modal-title {
  color: #fff;
}

.modal-dark .modal-header, .modal-dark .modal-footer {
  border-color: rgba(255, 255, 255, .075);
}

.modal-dark .modal-content {
  color: #fff;
  background-color: #16192c;
}

.modal-dark .modal-content .heading, .modal-dark .close > span:not(.sr-only) {
  color: #fff;
}

.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --x-offcanvas-zindex: 1045;
  --x-offcanvas-width: 400px;
  --x-offcanvas-height: 30vh;
  --x-offcanvas-padding-x: 1.5rem;
  --x-offcanvas-padding-y: 1.5rem;
  --x-offcanvas-color: ;
  --x-offcanvas-bg: #fff;
  --x-offcanvas-border-width: 1px;
  --x-offcanvas-border-color: #e7eaf0;
  --x-offcanvas-box-shadow: 0 .25rem .5rem rgba(0, 0, 0, .3);
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    z-index: var(--x-offcanvas-zindex);
    max-width: 100%;
    color: var(--x-offcanvas-color);
    visibility: hidden;
    background-color: var(--x-offcanvas-bg);
    box-shadow: var(--x-offcanvas-box-shadow);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}

@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    width: var(--x-offcanvas-width);
    border-right: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-sm.offcanvas-end {
    width: var(--x-offcanvas-width);
    border-left: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-sm.offcanvas-top {
    height: var(--x-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-sm.offcanvas-bottom {
    height: var(--x-offcanvas-height);
    max-height: 100%;
    border-top: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}

@media (min-width: 576px) {
  .offcanvas-sm {
    --x-offcanvas-height: auto;
    --x-offcanvas-border-width: 0;
    background-color: rgba(0, 0, 0, 0) !important;
  }

  .offcanvas-sm .offcanvas-header {
    display: none;
  }

  .offcanvas-sm .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: rgba(0, 0, 0, 0) !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    z-index: var(--x-offcanvas-zindex);
    max-width: 100%;
    color: var(--x-offcanvas-color);
    visibility: hidden;
    background-color: var(--x-offcanvas-bg);
    box-shadow: var(--x-offcanvas-box-shadow);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    width: var(--x-offcanvas-width);
    border-right: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-md.offcanvas-end {
    width: var(--x-offcanvas-width);
    border-left: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-md.offcanvas-top {
    height: var(--x-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-md.offcanvas-bottom {
    height: var(--x-offcanvas-height);
    max-height: 100%;
    border-top: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}

@media (min-width: 768px) {
  .offcanvas-md {
    --x-offcanvas-height: auto;
    --x-offcanvas-border-width: 0;
    background-color: rgba(0, 0, 0, 0) !important;
  }

  .offcanvas-md .offcanvas-header {
    display: none;
  }

  .offcanvas-md .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: rgba(0, 0, 0, 0) !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    z-index: var(--x-offcanvas-zindex);
    max-width: 100%;
    color: var(--x-offcanvas-color);
    visibility: hidden;
    background-color: var(--x-offcanvas-bg);
    box-shadow: var(--x-offcanvas-box-shadow);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    width: var(--x-offcanvas-width);
    border-right: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-lg.offcanvas-end {
    width: var(--x-offcanvas-width);
    border-left: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-lg.offcanvas-top {
    height: var(--x-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-lg.offcanvas-bottom {
    height: var(--x-offcanvas-height);
    max-height: 100%;
    border-top: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}

@media (min-width: 992px) {
  .offcanvas-lg {
    --x-offcanvas-height: auto;
    --x-offcanvas-border-width: 0;
    background-color: rgba(0, 0, 0, 0) !important;
  }

  .offcanvas-lg .offcanvas-header {
    display: none;
  }

  .offcanvas-lg .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: rgba(0, 0, 0, 0) !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    z-index: var(--x-offcanvas-zindex);
    max-width: 100%;
    color: var(--x-offcanvas-color);
    visibility: hidden;
    background-color: var(--x-offcanvas-bg);
    box-shadow: var(--x-offcanvas-box-shadow);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    width: var(--x-offcanvas-width);
    border-right: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-xl.offcanvas-end {
    width: var(--x-offcanvas-width);
    border-left: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-xl.offcanvas-top {
    height: var(--x-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-xl.offcanvas-bottom {
    height: var(--x-offcanvas-height);
    max-height: 100%;
    border-top: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}

@media (min-width: 1200px) {
  .offcanvas-xl {
    --x-offcanvas-height: auto;
    --x-offcanvas-border-width: 0;
    background-color: rgba(0, 0, 0, 0) !important;
  }

  .offcanvas-xl .offcanvas-header {
    display: none;
  }

  .offcanvas-xl .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: rgba(0, 0, 0, 0) !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    z-index: var(--x-offcanvas-zindex);
    max-width: 100%;
    color: var(--x-offcanvas-color);
    visibility: hidden;
    background-color: var(--x-offcanvas-bg);
    box-shadow: var(--x-offcanvas-box-shadow);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    width: var(--x-offcanvas-width);
    border-right: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .offcanvas-xxl.offcanvas-end {
    width: var(--x-offcanvas-width);
    border-left: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translateX(100%);
  }

  .offcanvas-xxl.offcanvas-top {
    height: var(--x-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-xxl.offcanvas-bottom {
    height: var(--x-offcanvas-height);
    max-height: 100%;
    border-top: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}

@media (min-width: 1400px) {
  .offcanvas-xxl {
    --x-offcanvas-height: auto;
    --x-offcanvas-border-width: 0;
    background-color: rgba(0, 0, 0, 0) !important;
  }

  .offcanvas-xxl .offcanvas-header {
    display: none;
  }

  .offcanvas-xxl .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: rgba(0, 0, 0, 0) !important;
  }
}

.offcanvas {
  z-index: var(--x-offcanvas-zindex);
  max-width: 100%;
  color: var(--x-offcanvas-color);
  visibility: hidden;
  background-color: var(--x-offcanvas-bg);
  box-shadow: var(--x-offcanvas-box-shadow);
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  transition: transform .3s ease-in-out;
  display: flex;
  position: fixed;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas.offcanvas-start {
  width: var(--x-offcanvas-width);
  border-right: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
  top: 0;
  left: 0;
  transform: translateX(-100%);
}

.offcanvas.offcanvas-end {
  width: var(--x-offcanvas-width);
  border-left: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
  top: 0;
  right: 0;
  transform: translateX(100%);
}

.offcanvas.offcanvas-top {
  height: var(--x-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(-100%);
}

.offcanvas.offcanvas-bottom {
  height: var(--x-offcanvas-height);
  max-height: 100%;
  border-top: var(--x-offcanvas-border-width) solid var(--x-offcanvas-border-color);
  left: 0;
  right: 0;
  transform: translateY(100%);
}

.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}

.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
}

.offcanvas-backdrop.fade {
  opacity: 0;
}

.offcanvas-backdrop.show {
  opacity: .4;
}

.offcanvas-header {
  padding: var(--x-offcanvas-padding-y) var(--x-offcanvas-padding-x);
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.offcanvas-header .btn-close {
  padding: calc(var(--x-offcanvas-padding-y) * .5) calc(var(--x-offcanvas-padding-x) * .5);
  margin-top: calc(-.5 * var(--x-offcanvas-padding-y));
  margin-right: calc(-.5 * var(--x-offcanvas-padding-x));
  margin-bottom: calc(-.5 * var(--x-offcanvas-padding-y));
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.625;
}

.offcanvas-body {
  padding: var(--x-offcanvas-padding-y) var(--x-offcanvas-padding-x);
  flex-grow: 1;
  overflow-y: auto;
}

.navbar {
  --x-navbar-padding-x: 1rem;
  --x-navbar-padding-y: 1rem;
  --x-navbar-color: rgba(0, 0, 0, .55);
  --x-navbar-hover-color: rgba(0, 0, 0, .7);
  --x-navbar-disabled-color: rgba(0, 0, 0, .3);
  --x-navbar-active-color: rgba(0, 0, 0, .9);
  --x-navbar-brand-padding-y: .5rem;
  --x-navbar-brand-margin-end: 0;
  --x-navbar-brand-font-size: 1rem;
  --x-navbar-brand-color: rgba(0, 0, 0, .9);
  --x-navbar-brand-hover-color: rgba(0, 0, 0, .9);
  --x-navbar-nav-link-padding-x: 1.25rem;
  --x-navbar-toggler-padding-y: .25rem;
  --x-navbar-toggler-padding-x: .375rem;
  --x-navbar-toggler-font-size: 1.25rem;
  --x-navbar-toggler-icon-bg: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%236B7B93' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  --x-navbar-toggler-border-color: transparent;
  --x-navbar-toggler-border-radius: .375rem;
  --x-navbar-toggler-focus-width: 3px;
  --x-navbar-toggler-transition: box-shadow .15s ease-in-out;
  padding: var(--x-navbar-padding-y) var(--x-navbar-padding-x);
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.navbar > .container, .navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl, .navbar > .container-xxl {
  flex-wrap: inherit;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.navbar-brand {
  padding-top: var(--x-navbar-brand-padding-y);
  padding-bottom: var(--x-navbar-brand-padding-y);
  margin-right: var(--x-navbar-brand-margin-end);
  font-size: var(--x-navbar-brand-font-size);
  color: var(--x-navbar-brand-color);
  white-space: nowrap;
}

.navbar-brand:hover, .navbar-brand:focus {
  color: var(--x-navbar-brand-hover-color);
}

.navbar-nav {
  --x-nav-link-padding-x: 0;
  --x-nav-link-padding-y: .5rem;
  --x-nav-link-font-size: .875rem;
  --x-nav-link-font-weight: 500;
  --x-nav-link-color: var(--x-navbar-color);
  --x-nav-link-hover-color: var(--x-navbar-hover-color);
  --x-nav-link-disabled-color: var(--x-navbar-disabled-color);
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.navbar-nav .show > .nav-link, .navbar-nav .nav-link.active {
  color: var(--x-navbar-active-color);
}

.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  color: var(--x-navbar-color);
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.navbar-text a, .navbar-text a:hover, .navbar-text a:focus {
  color: var(--x-navbar-active-color);
}

.navbar-collapse {
  flex-grow: 1;
  flex-basis: 100%;
  align-items: center;
}

.navbar-toggler {
  padding: var(--x-navbar-toggler-padding-y) var(--x-navbar-toggler-padding-x);
  font-size: var(--x-navbar-toggler-font-size);
  color: var(--x-navbar-color);
  border: var(--x-border-width) solid var(--x-navbar-toggler-border-color);
  border-radius: var(--x-navbar-toggler-border-radius);
  transition: var(--x-navbar-toggler-transition);
  background-color: rgba(0, 0, 0, 0);
  line-height: 1;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}

.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  box-shadow: 0 0 0 var(--x-navbar-toggler-focus-width);
  outline: 0;
  text-decoration: none;
}

.navbar-toggler-icon {
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--x-navbar-toggler-icon-bg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
}

.navbar-nav-scroll {
  max-height: var(--x-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--x-navbar-nav-link-padding-x);
    padding-left: var(--x-navbar-nav-link-padding-x);
  }

  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-sm .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }

  .navbar-expand-sm .offcanvas {
    z-index: auto;
    box-shadow: none;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0 !important;
    transform: none !important;
  }

  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-sm .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--x-navbar-nav-link-padding-x);
    padding-left: var(--x-navbar-nav-link-padding-x);
  }

  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-md .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }

  .navbar-expand-md .offcanvas {
    z-index: auto;
    box-shadow: none;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0 !important;
    transform: none !important;
  }

  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-md .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--x-navbar-nav-link-padding-x);
    padding-left: var(--x-navbar-nav-link-padding-x);
  }

  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-lg .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }

  .navbar-expand-lg .offcanvas {
    z-index: auto;
    box-shadow: none;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0 !important;
    transform: none !important;
  }

  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-lg .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--x-navbar-nav-link-padding-x);
    padding-left: var(--x-navbar-nav-link-padding-x);
  }

  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xl .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }

  .navbar-expand-xl .offcanvas {
    z-index: auto;
    box-shadow: none;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0 !important;
    transform: none !important;
  }

  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-xl .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--x-navbar-nav-link-padding-x);
    padding-left: var(--x-navbar-nav-link-padding-x);
  }

  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xxl .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }

  .navbar-expand-xxl .offcanvas {
    z-index: auto;
    box-shadow: none;
    flex-grow: 1;
    transition: none;
    position: static;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: rgba(0, 0, 0, 0) !important;
    border: 0 !important;
    transform: none !important;
  }

  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-xxl .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--x-navbar-nav-link-padding-x);
  padding-left: var(--x-navbar-nav-link-padding-x);
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  flex-basis: auto;
  display: flex !important;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-expand .offcanvas {
  z-index: auto;
  box-shadow: none;
  flex-grow: 1;
  transition: none;
  position: static;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: rgba(0, 0, 0, 0) !important;
  border: 0 !important;
  transform: none !important;
}

.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}

.navbar-expand .offcanvas .offcanvas-body {
  flex-grow: 0;
  padding: 0;
  display: flex;
  overflow-y: visible;
}

.navbar-dark {
  --x-navbar-color: rgba(255, 255, 255, .55);
  --x-navbar-hover-color: rgba(255, 255, 255, .75);
  --x-navbar-disabled-color: #8898a9;
  --x-navbar-active-color: #fff;
  --x-navbar-brand-color: #fff;
  --x-navbar-brand-hover-color: #fff;
  --x-navbar-toggler-border-color: transparent;
  --x-navbar-toggler-icon-bg: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23E7EAF0' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar {
  --x-navbar-bg: #fff;
  --x-navbar-color: #16192c;
  --x-navbar-icon-color: #6b7b93;
  --x-navbar-hover-bg: #f5f9fc;
  --x-navbar-hover-color: #495392;
  --x-navbar-active-bg: #f5f9fc;
  --x-navbar-active-color: #495392;
  --x-navbar-nav-link-padding-y: 1rem;
  z-index: 100;
  position: relative;
}

.navbar [class^="container"] {
  position: relative;
}

.navbar-nav {
  --x-nav-link-padding-x: 1.25rem;
  --x-nav-link-padding-y: 1rem;
}

.navbar-nav > .nav-item > .nav-link {
  background-color: var(--x-navbar-bg);
  color: var(--x-navbar-color);
  padding-top: var(--x-navbar-nav-link-padding-y);
  padding-bottom: var(--x-navbar-nav-link-padding-y);
  transition: all .15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-nav > .nav-item > .nav-link {
    transition: none;
  }
}

.navbar-nav > .nav-item > .nav-link:hover, .navbar-nav > .nav-item > .nav-link:focus {
  background-color: var(--x-navbar-hover-bg);
  color: var(--x-navbar-hover-color);
}

.navbar-nav > .nav-item > .nav-link.active {
  background-color: var(--x-navbar-active-bg);
  color: var(--x-navbar-active-color);
}

.navbar-user {
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  display: flex;
}

.navbar-img-brand {
  font-size: initial;
}

.navbar-top {
  align-items: center;
  display: flex;
  position: relative;
}

.navbar-top .navbar-nav {
  flex-direction: row;
}

.navbar-top .navbar-nav .nav-item:last-child {
  margin-right: 0;
}

.navbar-top .navbar-nav .nav-link {
  padding: .5rem;
}

.navbar-collapse:before {
  content: "";
  opacity: 0;
  border-top-style: solid;
  border-top-width: 1px;
  margin: .75rem -1rem;
  transition: all .2s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-collapse:before {
    transition: none;
  }
}

.navbar-collapse.show:before, .navbar-collapse.collapsing:before {
  opacity: 1;
}

.navbar-light .navbar-collapse:before {
  border-top-color: rgba(22, 25, 44, .1);
}

.navbar-light .collapse .nav:before {
  border-color: rgba(22, 25, 44, .1);
}

.navbar-dark {
  --x-navbar-bg: transparent;
  --x-navbar-color: rgba(255, 255, 255, .85);
  --x-navbar-hover-bg: rgba(255, 255, 255, .1);
  --x-navbar-hover-color: #fff;
  --x-navbar-active-bg: rgba(255, 255, 255, .1);
  --x-navbar-active-color: #fff;
}

.navbar-dark .navbar-collapse:before {
  border-top-color: rgba(255, 255, 255, .2);
}

.navbar-dark .collapse .nav:before {
  border-color: rgba(255, 255, 255, .2);
}

@media (min-width: 576px) {
  .navbar-expand-sm .navbar-brand img {
    height: 40px;
  }

  .navbar-expand-sm .navbar-brand.dropdown-toggle:after, .navbar-expand-sm .navbar-collapse .collapse-header, .navbar-expand-sm .navbar-collapse:before {
    display: none;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md .navbar-brand img {
    height: 40px;
  }

  .navbar-expand-md .navbar-brand.dropdown-toggle:after, .navbar-expand-md .navbar-collapse .collapse-header, .navbar-expand-md .navbar-collapse:before {
    display: none;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-brand img {
    height: 40px;
  }

  .navbar-expand-lg .navbar-brand.dropdown-toggle:after, .navbar-expand-lg .navbar-collapse .collapse-header, .navbar-expand-lg .navbar-collapse:before {
    display: none;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl .navbar-brand img {
    height: 40px;
  }

  .navbar-expand-xl .navbar-brand.dropdown-toggle:after, .navbar-expand-xl .navbar-collapse .collapse-header, .navbar-expand-xl .navbar-collapse:before {
    display: none;
  }
}

@media (min-width: 1400px) {
  .navbar-expand-xxl .navbar-brand img {
    height: 40px;
  }

  .navbar-expand-xxl .navbar-brand.dropdown-toggle:after, .navbar-expand-xxl .navbar-collapse .collapse-header, .navbar-expand-xxl .navbar-collapse:before {
    display: none;
  }
}

.navbar-expand .navbar-brand img {
  height: 40px;
}

.navbar-expand .navbar-brand.dropdown-toggle:after, .navbar-expand .navbar-collapse .collapse-header, .navbar-expand .navbar-collapse:before {
  display: none;
}

.navbar-toggler-icon {
  width: 1.25em;
  height: 1.25em;
}

.navbar-vertical .navbar-nav .nav-link {
  white-space: nowrap;
  align-items: center;
  padding: .75rem 1.5rem;
  font-size: .875rem;
  display: flex;
  position: relative;
}

.navbar-vertical .navbar-nav .nav-link i, .navbar-vertical .navbar-nav .nav-link svg {
  min-width: 1.875rem;
}

.navbar-vertical .navbar-nav .nav-link .dropdown-menu {
  border: none;
}

.navbar-vertical .navbar-nav .nav-link .dropdown-menu .dropdown-menu {
  margin-left: .5rem;
}

.navbar-vertical .navbar-nav .nav-link[data-bs-toggle="collapse"]:after {
  content: "";
  width: .875rem;
  height: .875rem;
  background-repeat: no-repeat;
  background-size: .875rem .875rem;
  margin-left: auto;
  transition: all .2s ease-in-out;
  display: inline-block;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-vertical .navbar-nav .nav-link[data-bs-toggle="collapse"]:after {
    transition: none;
  }
}

.navbar-vertical .navbar-nav .nav-link[data-bs-toggle="collapse"][aria-expanded="true"]:after {
  color: #5c60f5;
  transform: rotate(90deg);
}

.navbar-vertical .navbar-nav > .nav-item {
  margin-top: 2px;
}

.navbar-vertical .navbar-nav .nav-sm .nav-link {
  font-size: .875rem;
}

.navbar-vertical .navbar-nav .nav .nav-link {
  padding-top: .428571rem;
  padding-bottom: .428571rem;
  padding-left: 3.375rem;
  font-weight: 400;
}

.navbar-vertical .navbar-nav .nav .nav .nav-link {
  padding-left: 2.8125rem;
}

.navbar-vertical.navbar-light .navbar-nav .nav-link[data-bs-toggle="collapse"]:after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 16 16' fill='%2316192C' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
}

.navbar-vertical.navbar-light .nav-link-text, .navbar-vertical.navbar-light .navbar-heading {
  color: var(--x-navbar-color);
}

.navbar-vertical.navbar-dark .navbar-nav .nav-link[data-bs-toggle="collapse"]:after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 16 16' fill='rgba(255, 255, 255, 0.85)' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
}

.navbar-vertical.navbar-dark .nav-link-text, .navbar-vertical.navbar-dark .navbar-heading {
  color: rgba(255, 255, 255, .55);
}

@media (min-width: 576px) {
  .navbar-vertical.navbar-expand-sm {
    width: 100%;
    max-width: 270px;
    z-index: 1000;
    border-radius: 0;
    transition: all .2s ease-in-out;
    display: block;
    overflow-y: auto;
    box-shadow: 0 0 rgba(0, 0, 0, 0) !important;
  }
}

@media (min-width: 576px) and (prefers-reduced-motion: reduce) {
  .navbar-vertical.navbar-expand-sm {
    transition: none;
  }
}

@media (min-width: 576px) {
  .navbar-vertical.navbar-expand-sm + .main-content {
    margin-left: 270px;
  }

  .navbar-vertical.navbar-expand-sm .nav-link-text, .navbar-vertical.navbar-expand-sm .navbar-heading {
    opacity: 0;
    pointer-events: none;
    transition: all .2s ease-in-out;
  }
}

@media (min-width: 576px) and (prefers-reduced-motion: reduce) {
  .navbar-vertical.navbar-expand-sm .nav-link-text, .navbar-vertical.navbar-expand-sm .navbar-heading {
    transition: none;
  }
}

@media (min-width: 576px) {
  .navbar-vertical.navbar-expand-sm.show {
    max-width: 270px;
    border-radius: 0;
  }

  .navbar-vertical.navbar-expand-sm.show.navbar-lg {
    max-width: 320px;
  }

  .navbar-vertical.navbar-expand-sm.show.navbar-xl {
    max-width: 370px;
  }

  .navbar-vertical.navbar-expand-sm.show .nav-link-text, .navbar-vertical.navbar-expand-sm.show .navbar-heading {
    opacity: 1;
    pointer-events: auto;
  }

  .navbar-vertical.navbar-expand-sm.show + .main-content {
    margin-left: 270px;
  }

  .navbar-vertical.navbar-expand-sm.hide {
    max-width: 270px;
  }

  .navbar-vertical.navbar-expand-sm.hide .nav-link-text {
    opacity: 0;
    pointer-events: none;
  }

  .navbar-vertical.navbar-expand-sm.hide + .main-content {
    margin-left: 270px;
  }

  .navbar-vertical.navbar-expand-sm .navbar-brand {
    margin-right: 0;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .navbar-vertical.navbar-expand-sm > [class*="container"] {
    min-height: 100%;
    flex-direction: column;
    align-items: stretch;
    padding-left: 0;
    padding-right: 0;
  }

  .navbar-vertical.navbar-expand-sm .navbar-collapse {
    opacity: 1;
    flex-direction: column;
    flex: 1;
    align-items: stretch;
    display: flex;
  }

  .navbar-vertical.navbar-expand-sm .navbar-nav {
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
  }
}

@media (min-width: 768px) {
  .navbar-vertical.navbar-expand-md {
    width: 100%;
    max-width: 270px;
    z-index: 1000;
    border-radius: 0;
    transition: all .2s ease-in-out;
    display: block;
    overflow-y: auto;
    box-shadow: 0 0 rgba(0, 0, 0, 0) !important;
  }
}

@media (min-width: 768px) and (prefers-reduced-motion: reduce) {
  .navbar-vertical.navbar-expand-md {
    transition: none;
  }
}

@media (min-width: 768px) {
  .navbar-vertical.navbar-expand-md + .main-content {
    margin-left: 270px;
  }

  .navbar-vertical.navbar-expand-md .nav-link-text, .navbar-vertical.navbar-expand-md .navbar-heading {
    opacity: 0;
    pointer-events: none;
    transition: all .2s ease-in-out;
  }
}

@media (min-width: 768px) and (prefers-reduced-motion: reduce) {
  .navbar-vertical.navbar-expand-md .nav-link-text, .navbar-vertical.navbar-expand-md .navbar-heading {
    transition: none;
  }
}

@media (min-width: 768px) {
  .navbar-vertical.navbar-expand-md.show {
    max-width: 270px;
    border-radius: 0;
  }

  .navbar-vertical.navbar-expand-md.show.navbar-lg {
    max-width: 320px;
  }

  .navbar-vertical.navbar-expand-md.show.navbar-xl {
    max-width: 370px;
  }

  .navbar-vertical.navbar-expand-md.show .nav-link-text, .navbar-vertical.navbar-expand-md.show .navbar-heading {
    opacity: 1;
    pointer-events: auto;
  }

  .navbar-vertical.navbar-expand-md.show + .main-content {
    margin-left: 270px;
  }

  .navbar-vertical.navbar-expand-md.hide {
    max-width: 270px;
  }

  .navbar-vertical.navbar-expand-md.hide .nav-link-text {
    opacity: 0;
    pointer-events: none;
  }

  .navbar-vertical.navbar-expand-md.hide + .main-content {
    margin-left: 270px;
  }

  .navbar-vertical.navbar-expand-md .navbar-brand {
    margin-right: 0;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .navbar-vertical.navbar-expand-md > [class*="container"] {
    min-height: 100%;
    flex-direction: column;
    align-items: stretch;
    padding-left: 0;
    padding-right: 0;
  }

  .navbar-vertical.navbar-expand-md .navbar-collapse {
    opacity: 1;
    flex-direction: column;
    flex: 1;
    align-items: stretch;
    display: flex;
  }

  .navbar-vertical.navbar-expand-md .navbar-nav {
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
  }
}

@media (min-width: 992px) {
  .navbar-vertical.navbar-expand-lg {
    width: 100%;
    max-width: 270px;
    z-index: 1000;
    border-radius: 0;
    transition: all .2s ease-in-out;
    display: block;
    overflow-y: auto;
    box-shadow: 0 0 rgba(0, 0, 0, 0) !important;
  }
}

@media (min-width: 992px) and (prefers-reduced-motion: reduce) {
  .navbar-vertical.navbar-expand-lg {
    transition: none;
  }
}

@media (min-width: 992px) {
  .navbar-vertical.navbar-expand-lg + .main-content {
    margin-left: 270px;
  }

  .navbar-vertical.navbar-expand-lg .nav-link-text, .navbar-vertical.navbar-expand-lg .navbar-heading {
    opacity: 0;
    pointer-events: none;
    transition: all .2s ease-in-out;
  }
}

@media (min-width: 992px) and (prefers-reduced-motion: reduce) {
  .navbar-vertical.navbar-expand-lg .nav-link-text, .navbar-vertical.navbar-expand-lg .navbar-heading {
    transition: none;
  }
}

@media (min-width: 992px) {
  .navbar-vertical.navbar-expand-lg.show {
    max-width: 270px;
    border-radius: 0;
  }

  .navbar-vertical.navbar-expand-lg.show.navbar-lg {
    max-width: 320px;
  }

  .navbar-vertical.navbar-expand-lg.show.navbar-xl {
    max-width: 370px;
  }

  .navbar-vertical.navbar-expand-lg.show .nav-link-text, .navbar-vertical.navbar-expand-lg.show .navbar-heading {
    opacity: 1;
    pointer-events: auto;
  }

  .navbar-vertical.navbar-expand-lg.show + .main-content {
    margin-left: 270px;
  }

  .navbar-vertical.navbar-expand-lg.hide {
    max-width: 270px;
  }

  .navbar-vertical.navbar-expand-lg.hide .nav-link-text {
    opacity: 0;
    pointer-events: none;
  }

  .navbar-vertical.navbar-expand-lg.hide + .main-content {
    margin-left: 270px;
  }

  .navbar-vertical.navbar-expand-lg .navbar-brand {
    margin-right: 0;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .navbar-vertical.navbar-expand-lg > [class*="container"] {
    min-height: 100%;
    flex-direction: column;
    align-items: stretch;
    padding-left: 0;
    padding-right: 0;
  }

  .navbar-vertical.navbar-expand-lg .navbar-collapse {
    opacity: 1;
    flex-direction: column;
    flex: 1;
    align-items: stretch;
    display: flex;
  }

  .navbar-vertical.navbar-expand-lg .navbar-nav {
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-vertical.navbar-expand-xl {
    width: 100%;
    max-width: 270px;
    z-index: 1000;
    border-radius: 0;
    transition: all .2s ease-in-out;
    display: block;
    overflow-y: auto;
    box-shadow: 0 0 rgba(0, 0, 0, 0) !important;
  }
}

@media (min-width: 1200px) and (prefers-reduced-motion: reduce) {
  .navbar-vertical.navbar-expand-xl {
    transition: none;
  }
}

@media (min-width: 1200px) {
  .navbar-vertical.navbar-expand-xl + .main-content {
    margin-left: 270px;
  }

  .navbar-vertical.navbar-expand-xl .nav-link-text, .navbar-vertical.navbar-expand-xl .navbar-heading {
    opacity: 0;
    pointer-events: none;
    transition: all .2s ease-in-out;
  }
}

@media (min-width: 1200px) and (prefers-reduced-motion: reduce) {
  .navbar-vertical.navbar-expand-xl .nav-link-text, .navbar-vertical.navbar-expand-xl .navbar-heading {
    transition: none;
  }
}

@media (min-width: 1200px) {
  .navbar-vertical.navbar-expand-xl.show {
    max-width: 270px;
    border-radius: 0;
  }

  .navbar-vertical.navbar-expand-xl.show.navbar-lg {
    max-width: 320px;
  }

  .navbar-vertical.navbar-expand-xl.show.navbar-xl {
    max-width: 370px;
  }

  .navbar-vertical.navbar-expand-xl.show .nav-link-text, .navbar-vertical.navbar-expand-xl.show .navbar-heading {
    opacity: 1;
    pointer-events: auto;
  }

  .navbar-vertical.navbar-expand-xl.show + .main-content {
    margin-left: 270px;
  }

  .navbar-vertical.navbar-expand-xl.hide {
    max-width: 270px;
  }

  .navbar-vertical.navbar-expand-xl.hide .nav-link-text {
    opacity: 0;
    pointer-events: none;
  }

  .navbar-vertical.navbar-expand-xl.hide + .main-content {
    margin-left: 270px;
  }

  .navbar-vertical.navbar-expand-xl .navbar-brand {
    margin-right: 0;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .navbar-vertical.navbar-expand-xl > [class*="container"] {
    min-height: 100%;
    flex-direction: column;
    align-items: stretch;
    padding-left: 0;
    padding-right: 0;
  }

  .navbar-vertical.navbar-expand-xl .navbar-collapse {
    opacity: 1;
    flex-direction: column;
    flex: 1;
    align-items: stretch;
    display: flex;
  }

  .navbar-vertical.navbar-expand-xl .navbar-nav {
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
  }
}

@media (min-width: 1400px) {
  .navbar-vertical.navbar-expand-xxl {
    width: 100%;
    max-width: 270px;
    z-index: 1000;
    border-radius: 0;
    transition: all .2s ease-in-out;
    display: block;
    overflow-y: auto;
    box-shadow: 0 0 rgba(0, 0, 0, 0) !important;
  }
}

@media (min-width: 1400px) and (prefers-reduced-motion: reduce) {
  .navbar-vertical.navbar-expand-xxl {
    transition: none;
  }
}

@media (min-width: 1400px) {
  .navbar-vertical.navbar-expand-xxl + .main-content {
    margin-left: 270px;
  }

  .navbar-vertical.navbar-expand-xxl .nav-link-text, .navbar-vertical.navbar-expand-xxl .navbar-heading {
    opacity: 0;
    pointer-events: none;
    transition: all .2s ease-in-out;
  }
}

@media (min-width: 1400px) and (prefers-reduced-motion: reduce) {
  .navbar-vertical.navbar-expand-xxl .nav-link-text, .navbar-vertical.navbar-expand-xxl .navbar-heading {
    transition: none;
  }
}

@media (min-width: 1400px) {
  .navbar-vertical.navbar-expand-xxl.show {
    max-width: 270px;
    border-radius: 0;
  }

  .navbar-vertical.navbar-expand-xxl.show.navbar-lg {
    max-width: 320px;
  }

  .navbar-vertical.navbar-expand-xxl.show.navbar-xl {
    max-width: 370px;
  }

  .navbar-vertical.navbar-expand-xxl.show .nav-link-text, .navbar-vertical.navbar-expand-xxl.show .navbar-heading {
    opacity: 1;
    pointer-events: auto;
  }

  .navbar-vertical.navbar-expand-xxl.show + .main-content {
    margin-left: 270px;
  }

  .navbar-vertical.navbar-expand-xxl.hide {
    max-width: 270px;
  }

  .navbar-vertical.navbar-expand-xxl.hide .nav-link-text {
    opacity: 0;
    pointer-events: none;
  }

  .navbar-vertical.navbar-expand-xxl.hide + .main-content {
    margin-left: 270px;
  }

  .navbar-vertical.navbar-expand-xxl .navbar-brand {
    margin-right: 0;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .navbar-vertical.navbar-expand-xxl > [class*="container"] {
    min-height: 100%;
    flex-direction: column;
    align-items: stretch;
    padding-left: 0;
    padding-right: 0;
  }

  .navbar-vertical.navbar-expand-xxl .navbar-collapse {
    opacity: 1;
    flex-direction: column;
    flex: 1;
    align-items: stretch;
    display: flex;
  }

  .navbar-vertical.navbar-expand-xxl .navbar-nav {
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
  }
}

.navbar-vertical.navbar-expand {
  width: 100%;
  max-width: 270px;
  z-index: 1000;
  border-radius: 0;
  transition: all .2s ease-in-out;
  display: block;
  overflow-y: auto;
  box-shadow: 0 0 rgba(0, 0, 0, 0) !important;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-vertical.navbar-expand {
    transition: none;
  }
}

.navbar-vertical.navbar-expand + .main-content {
  margin-left: 270px;
}

.navbar-vertical.navbar-expand .nav-link-text, .navbar-vertical.navbar-expand .navbar-heading {
  opacity: 0;
  pointer-events: none;
  transition: all .2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-vertical.navbar-expand .nav-link-text, .navbar-vertical.navbar-expand .navbar-heading {
    transition: none;
  }
}

.navbar-vertical.navbar-expand.show {
  max-width: 270px;
  border-radius: 0;
}

.navbar-vertical.navbar-expand.show.navbar-lg {
  max-width: 320px;
}

.navbar-vertical.navbar-expand.show.navbar-xl {
  max-width: 370px;
}

.navbar-vertical.navbar-expand.show .nav-link-text, .navbar-vertical.navbar-expand.show .navbar-heading {
  opacity: 1;
  pointer-events: auto;
}

.navbar-vertical.navbar-expand.show + .main-content {
  margin-left: 270px;
}

.navbar-vertical.navbar-expand.hide {
  max-width: 270px;
}

.navbar-vertical.navbar-expand.hide .nav-link-text {
  opacity: 0;
  pointer-events: none;
}

.navbar-vertical.navbar-expand.hide + .main-content {
  margin-left: 270px;
}

.navbar-vertical.navbar-expand .navbar-brand {
  margin-right: 0;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.navbar-vertical.navbar-expand > [class*="container"] {
  min-height: 100%;
  flex-direction: column;
  align-items: stretch;
  padding-left: 0;
  padding-right: 0;
}

.navbar-vertical.navbar-expand .navbar-collapse {
  opacity: 1;
  flex-direction: column;
  flex: 1;
  align-items: stretch;
  display: flex;
}

.navbar-vertical.navbar-expand .navbar-nav {
  flex-direction: column;
  margin-left: 0;
  margin-right: 0;
}

.navbar-toggler {
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: .375rem;
  padding: .25rem .375rem;
  font-size: 1.25rem;
  line-height: 1;
  transition: box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}

.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  outline: 0;
  text-decoration: none;
  box-shadow: 0 0 0 3px;
}

.navbar-light .navbar-toggler {
  color: #6b7b93;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
}

.navbar-light .navbar-toggler:focus {
  color: rgba(0, 0, 0, 0);
  background-color: #f5f9fc;
}

.navbar-dark .navbar-toggler {
  color: #e7eaf0;
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
}

.navbar-dark .navbar-toggler:focus {
  color: rgba(0, 0, 0, 0);
  background-color: #f5f9fc;
}

.nav {
  --x-nav-link-padding-x: 1rem;
  --x-nav-link-padding-y: .5rem;
  --x-nav-link-font-size: .875rem;
  --x-nav-link-font-weight: 500;
  --x-nav-link-color: #525f7f;
  --x-nav-link-hover-color: #5c60f5;
  --x-nav-link-disabled-color: #abb6c5;
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.nav-link {
  padding: var(--x-nav-link-padding-y) var(--x-nav-link-padding-x);
  font-size: var(--x-nav-link-font-size);
  font-weight: var(--x-nav-link-font-weight);
  color: var(--x-nav-link-color);
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}

.nav-link:hover, .nav-link:focus {
  color: var(--x-nav-link-hover-color);
}

.nav-link.disabled {
  color: var(--x-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --x-nav-tabs-border-width: 1px;
  --x-nav-tabs-border-color: #e7eaf0;
  --x-nav-tabs-border-radius: 0;
  --x-nav-tabs-link-hover-border-color: transparent transparent transparent;
  --x-nav-tabs-link-active-color: #5c60f5;
  --x-nav-tabs-link-active-bg: transparent;
  --x-nav-tabs-link-active-border-color: transparent transparent #5c60f5;
  border-bottom: var(--x-nav-tabs-border-width) solid var(--x-nav-tabs-border-color);
}

.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--x-nav-tabs-border-width));
  border: var(--x-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--x-nav-tabs-border-radius);
  border-top-right-radius: var(--x-nav-tabs-border-radius);
  background: none;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--x-nav-tabs-link-hover-border-color);
}

.nav-tabs .nav-link.disabled, .nav-tabs .nav-link:disabled {
  color: var(--x-nav-link-disabled-color);
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: var(--x-nav-tabs-link-active-color);
  background-color: var(--x-nav-tabs-link-active-bg);
  border-color: var(--x-nav-tabs-link-active-border-color);
}

.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--x-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --x-nav-pills-border-radius: .375rem;
  --x-nav-pills-link-active-color: #fff;
  --x-nav-pills-link-active-bg: #5c60f5;
}

.nav-pills .nav-link {
  border-radius: var(--x-nav-pills-border-radius);
  background: none;
  border: 0;
}

.nav-pills .nav-link:disabled {
  color: var(--x-nav-link-disabled-color);
  background-color: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: var(--x-nav-pills-link-active-color);
  background-color: var(--x-nav-pills-link-active-bg);
}

.nav-fill > .nav-link, .nav-fill .nav-item {
  text-align: center;
  flex: auto;
}

.nav-justified > .nav-link, .nav-justified .nav-item {
  text-align: center;
  flex-grow: 1;
  flex-basis: 0;
}

.nav-fill .nav-item .nav-link, .nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

@media (min-width: 992px) {
  .nav-link-badge {
    position: relative;
  }

  .nav-link-badge .badge {
    position: relative;
    top: -15px;
    left: -5px;
  }

  .nav-link-badge .nav-link-text {
    position: relative;
  }

  .nav-link-badge .nav-link-text:before {
    width: 20px;
    height: 20px;
    content: "";
    border-left: 1px dashed #fff;
    border-radius: 10px;
    margin: auto;
    display: block;
    position: absolute;
    top: 0;
    left: -7px;
    transform: translate(-50%, -50%)rotate(100deg);
  }
}

.nav.overflow-x {
  flex-wrap: nowrap;
  padding-bottom: 1px;
  display: flex;
  overflow-x: auto;
}

.nav.overflow-x .nav-item {
  width: auto;
  max-width: 100%;
  flex: none;
}

.nav.overflow-x::-webkit-scrollbar {
  display: none;
}

.nav-header {
  border-bottom-width: 0;
}

.nav-dots {
  z-index: 200;
  position: fixed;
  top: 50%;
  right: 2rem;
  transform: translateY(-50%);
}

.nav-dots .nav-link {
  margin: 5px;
  display: block;
  position: relative;
}

.nav-dots .nav-link:before {
  content: "";
  z-index: 1;
  height: 6px;
  width: 6px;
  background: #fff;
  border: 0;
  border-radius: 50%;
  margin: -2px 0 0 -2px;
  transition: all .1s ease-in-out;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.nav-dots .nav-link:hover:before, .nav-dots .nav-link.active:before {
  height: 12px;
  width: 12px;
}

.nav-menu .nav-link {
  padding: .35rem 0;
}

.nav-menu .nav-link:not(.active):hover {
  color: #2d3748;
}

.nav-menu .nav-link:not(.active).active {
  font-weight: bolder;
}

.nav-tabs .nav-item {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.nav-tabs .nav-item:first-child {
  margin-left: 0;
}

.nav-tabs .nav-item:last-child {
  margin-right: 0;
}

.nav-tabs .nav-item.show .nav-link {
  border-color: rgba(0, 0, 0, 0);
}

.nav-tabs .nav-link {
  border-width: 0 0 1px;
  border-bottom-style: solid;
  border-bottom-color: rgba(0, 0, 0, 0);
  padding: 1.25rem 0;
}

.card {
  --x-card-spacer-y: 1.5rem;
  --x-card-spacer-x: 1.5rem;
  --x-card-title-spacer-y: 1.5rem;
  --x-card-border-width: 1px;
  --x-card-border-color: #e7eaf0;
  --x-card-border-radius: .75rem;
  --x-card-box-shadow: ;
  --x-card-inner-border-radius: calc(.75rem - 1px);
  --x-card-cap-padding-y: 1.25rem;
  --x-card-cap-padding-x: 1.5rem;
  --x-card-cap-bg: rgba(0, 0, 0, 0);
  --x-card-cap-color: #16192c;
  --x-card-height: ;
  --x-card-color: #525f7f;
  --x-card-bg: #fff;
  --x-card-img-overlay-padding: 1rem;
  --x-card-group-margin: .75rem;
  min-width: 0;
  height: var(--x-card-height);
  word-wrap: break-word;
  background-color: var(--x-card-bg);
  border: var(--x-card-border-width) solid var(--x-card-border-color);
  border-radius: var(--x-card-border-radius);
  box-shadow: var(--x-card-box-shadow);
  background-clip: border-box;
  flex-direction: column;
  display: flex;
  position: relative;
}

.card > hr {
  margin-left: 0;
  margin-right: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-left-radius: var(--x-card-inner-border-radius);
  border-top-right-radius: var(--x-card-inner-border-radius);
  border-top-width: 0;
}

.card > .list-group:last-child {
  border-bottom-right-radius: var(--x-card-inner-border-radius);
  border-bottom-left-radius: var(--x-card-inner-border-radius);
  border-bottom-width: 0;
}

.card > .card-header + .list-group, .card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  padding: var(--x-card-spacer-y) var(--x-card-spacer-x);
  color: var(--x-card-color);
  flex: auto;
}

.card-title {
  margin-bottom: var(--x-card-title-spacer-y);
}

.card-subtitle {
  margin-top: calc(-.5 * var(--x-card-title-spacer-y));
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--x-card-spacer-x);
}

.card-header {
  padding: var(--x-card-cap-padding-y) var(--x-card-cap-padding-x);
  color: var(--x-card-cap-color);
  background-color: var(--x-card-cap-bg);
  border-bottom: var(--x-card-border-width) solid var(--x-card-border-color);
  margin-bottom: 0;
}

.card-header:first-child {
  border-radius: var(--x-card-inner-border-radius) var(--x-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--x-card-cap-padding-y) var(--x-card-cap-padding-x);
  color: var(--x-card-cap-color);
  background-color: var(--x-card-cap-bg);
  border-top: var(--x-card-border-width) solid var(--x-card-border-color);
}

.card-footer:last-child {
  border-radius: 0 0 var(--x-card-inner-border-radius) var(--x-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-.5 * var(--x-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--x-card-cap-padding-y));
  margin-left: calc(-.5 * var(--x-card-cap-padding-x));
  border-bottom: 0;
}

.card-header-tabs .nav-link.active {
  background-color: var(--x-card-bg);
  border-bottom-color: var(--x-card-bg);
}

.card-header-pills {
  margin-right: calc(-.5 * var(--x-card-cap-padding-x));
  margin-left: calc(-.5 * var(--x-card-cap-padding-x));
}

.card-img-overlay {
  padding: var(--x-card-img-overlay-padding);
  border-radius: var(--x-card-inner-border-radius);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.card-img, .card-img-top, .card-img-bottom {
  width: 100%;
}

.card-img, .card-img-top {
  border-top-left-radius: var(--x-card-inner-border-radius);
  border-top-right-radius: var(--x-card-inner-border-radius);
}

.card-img, .card-img-bottom {
  border-bottom-right-radius: var(--x-card-inner-border-radius);
  border-bottom-left-radius: var(--x-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--x-card-group-margin);
}

@media (min-width: 576px) {
  .card-group {
    flex-flow: wrap;
    display: flex;
  }

  .card-group > .card {
    flex: 1 0;
    margin-bottom: 0;
  }

  .card-group > .card + .card {
    border-left: 0;
    margin-left: 0;
  }

  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-top, .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-bottom, .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-top, .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-bottom, .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-header-tabs {
  margin: calc(-1.5rem - 1px) 0 calc(1px - 1.5rem);
}

.card-header-tabs .nav-item {
  margin-right: .75rem;
}

.card-header-tabs .nav-link.active {
  background-color: rgba(0, 0, 0, 0);
  border-bottom-color: #5c60f5;
}

.card-comment-box {
  width: 100%;
}

.card-comment-box input, .card-comment-box textarea {
  box-shadow: none;
  color: #2d3748;
  resize: none;
  background: none;
  border: 0;
  outline: none;
  margin: 0;
  padding: .3125rem 0;
}

.card-comment-box input:focus, .card-comment-box textarea:focus {
  box-shadow: none;
  background: none;
}

.card-list {
  background-color: #fff;
  border: 1px solid #e7eaf0;
  border-radius: .75rem;
  margin-bottom: 1.5rem;
  padding: 1rem;
}

.card-list .card {
  box-shadow: none !important;
}

.card-list-body .card:last-of-type {
  margin-bottom: 0 !important;
}

.empty-container:after {
  pointer-events: none;
  content: attr(data-placeholder);
  color: #caced0;
  height: 0;
  opacity: 0;
  border: 1px dashed #caced0;
  border-radius: .75rem;
  justify-content: center;
  align-items: center;
  font-size: .875rem;
  font-weight: 500;
  transition: opacity .35s;
  display: flex;
}

@media (prefers-reduced-motion: reduce) {
  .empty-container:after {
    transition: none;
  }
}

.empty-container:only-child:after {
  opacity: 1;
  height: 70px;
}

.card-list-flush {
  box-shadow: none;
  border: 0;
  border-radius: 0;
}

.card-placeholder {
  background-color: rgba(0, 0, 0, 0);
  border: 1px dashed #cfd6df;
  padding: 3rem 0;
}

.card-placeholder:hover {
  border-color: #c1c7cf;
}

.card-placeholder .card-body {
  text-align: center;
}

.card-progress {
  margin-bottom: .75rem;
}

.card-progress .card-body {
  padding: 1rem;
}

.card-progress .progress {
  height: 2px;
  margin: 2px 4px;
}

.omnisearch {
  width: 100%;
  z-index: 1050;
  opacity: 0;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0);
  margin-top: 1rem;
  transition: transform .3s, opacity .15s;
  position: fixed;
  top: 0;
  left: 0;
  transform: translateY(0);
}

@media (prefers-reduced-motion: reduce) {
  .omnisearch {
    transition: none;
  }
}

.omnisearch-container {
  margin: auto;
}

@media (max-width: 767.98px) {
  .omnisearch-container {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .omnisearch-container {
    width: 580px;
  }
}

.omnisearch.show {
  opacity: 1;
  transform: translateY(10%);
}

.omnisearch .container {
  height: 100%;
  position: relative;
}

.omnisearch .omnisearch-form {
  z-index: 700;
  background: #fff;
  border-radius: .5rem;
  display: block;
  position: relative;
}

.omnisearch .omnisearch-form .input-group-text {
  background: none;
  font-size: 1.25rem;
}

.omnisearch .omnisearch-form .form-control {
  height: 58px;
  color: #6b7b93;
  background-color: rgba(0, 0, 0, 0);
  background-image: none;
  font-size: 1.25rem;
  display: block;
}

.omnisearch .omnisearch-form .form-control::placeholder {
  color: #8898a9;
}

.omnisearch .omnisearch-suggestions {
  min-height: 150px;
  opacity: 0;
  background: #fff;
  border-radius: .5rem;
  padding: 1.5rem;
  transition: opacity .3s .21s;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .omnisearch .omnisearch-suggestions {
    transition: none;
  }
}

.omnisearch .omnisearch-suggestions:before {
  box-shadow: none;
  content: "";
  height: 16px;
  width: 16px;
  z-index: -5;
  background: #fff;
  border-radius: .2rem;
  display: block;
  position: absolute;
  bottom: 100%;
  left: 20px;
  transform: rotate(-45deg)translateY(1rem);
}

.omnisearch .omnisearch-suggestions .heading {
  color: #525f7f;
}

.omnisearch .omnisearch-suggestions .list-link span {
  color: #2d3748;
  font-weight: 600;
}

.omnisearch .omnisearch-suggestions .list-link:hover, .omnisearch .omnisearch-suggestions .list-link:hover span {
  color: theme-color("primary");
}

.omnisearch.show .omnisearch-form, .omnisearch.show .omnisearch-suggestions {
  pointer-events: auto;
}

.omnisearch.show .omnisearch-suggestions {
  opacity: 1;
}

.omnisearch-open {
  overflow: hidden;
}

.pagination {
  --x-pagination-padding-x: 1rem;
  --x-pagination-padding-y: .375rem;
  --x-pagination-font-size: .875rem;
  --x-pagination-color: #525f7f;
  --x-pagination-bg: #fff;
  --x-pagination-border-width: 1px;
  --x-pagination-border-color: #cfd6df;
  --x-pagination-border-radius: .375rem;
  --x-pagination-hover-color: #16192c;
  --x-pagination-hover-bg: #fafafa;
  --x-pagination-hover-border-color: #cfd6df;
  --x-pagination-focus-color: #4e52d0;
  --x-pagination-focus-bg: #f5f9fc;
  --x-pagination-focus-box-shadow: none;
  --x-pagination-active-color: #fff;
  --x-pagination-active-bg: #5c60f5;
  --x-pagination-active-border-color: #5c60f5;
  --x-pagination-disabled-color: #6b7b93;
  --x-pagination-disabled-bg: #fff;
  --x-pagination-disabled-border-color: #cfd6df;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.page-link {
  padding: var(--x-pagination-padding-y) var(--x-pagination-padding-x);
  font-size: var(--x-pagination-font-size);
  color: var(--x-pagination-color);
  background-color: var(--x-pagination-bg);
  border: var(--x-pagination-border-width) solid var(--x-pagination-border-color);
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}

.page-link:hover {
  z-index: 2;
  color: var(--x-pagination-hover-color);
  background-color: var(--x-pagination-hover-bg);
  border-color: var(--x-pagination-hover-border-color);
}

.page-link:focus {
  z-index: 3;
  color: var(--x-pagination-focus-color);
  background-color: var(--x-pagination-focus-bg);
  box-shadow: var(--x-pagination-focus-box-shadow);
  outline: 0;
}

.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--x-pagination-active-color);
  background-color: var(--x-pagination-active-bg);
  border-color: var(--x-pagination-active-border-color);
}

.page-link.disabled, .disabled > .page-link {
  color: var(--x-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--x-pagination-disabled-bg);
  border-color: var(--x-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}

.page-item:first-child .page-link {
  border-top-left-radius: var(--x-pagination-border-radius);
  border-bottom-left-radius: var(--x-pagination-border-radius);
}

.page-item:last-child .page-link {
  border-top-right-radius: var(--x-pagination-border-radius);
  border-bottom-right-radius: var(--x-pagination-border-radius);
}

.pagination-lg {
  --x-pagination-padding-x: 1.5rem;
  --x-pagination-padding-y: .75rem;
  --x-pagination-font-size: 1.25rem;
  --x-pagination-border-radius: .5rem;
}

.pagination-sm {
  --x-pagination-padding-x: .75rem;
  --x-pagination-padding-y: .25rem;
  --x-pagination-font-size: .875rem;
  --x-pagination-border-radius: .25rem;
}

.pagination {
  margin-bottom: 0;
}

.pagination .page-link {
  font-size: .875rem;
}

.pagination-lg .page-link {
  font-size: 1rem;
}

.pagination-sm .page-link {
  font-size: .875rem;
}

.pagination-tabs {
  border-radius: 0;
}

.pagination-tabs .page-link {
  background-color: rgba(0, 0, 0, 0);
  border-width: 1px 0 0;
  border-color: rgba(0, 0, 0, 0);
  margin-top: -1px;
  padding: 1.25rem .75rem;
  border-radius: 0 !important;
}

.pagination-tabs .page-link:hover {
  color: #5c60f5;
}

.pagination-tabs .page-item.active .page-link {
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  border-color: #5c60f5;
}

.pagination-spaced {
  border-radius: 0;
}

.pagination-spaced .page-link {
  width: 36px;
  height: 36px;
  border-radius: .375rem;
  justify-content: center;
  align-items: center;
  padding: 0;
  display: inline-flex;
}

.pagination-spaced.pagination-sm .page-link {
  width: 30px;
  height: 30px;
  border-radius: .25rem;
}

.pagination-spaced.pagination-lg .page-link {
  width: 52px;
  height: 52px;
}

.placeholder {
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  opacity: .5;
  background-color: currentColor;
  display: inline-block;
}

.placeholder.btn:before {
  content: "";
  display: inline-block;
}

.placeholder-xs {
  min-height: .6em;
}

.placeholder-sm {
  min-height: .8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: 2s ease-in-out infinite placeholder-glow;
}

@keyframes placeholder-glow {
  50% {
    opacity: .2;
  }
}

.placeholder-wave {
  animation: 2s linear infinite placeholder-wave;
  -webkit-mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, .8) 75%, #000 95%);
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, .8) 75%, #000 95%);
  -webkit-mask-size: 200% 100%;
  mask-size: 200% 100%;
}

@keyframes placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0;
    mask-position: -200% 0;
  }
}

.popover {
  --x-popover-zindex: 1070;
  --x-popover-max-width: 276px;
  --x-popover-font-size: .875rem;
  --x-popover-bg: #fff;
  --x-popover-border-width: 1px;
  --x-popover-border-color: rgba(0, 0, 0, .05);
  --x-popover-border-radius: .5rem;
  --x-popover-inner-border-radius: calc(.5rem - 1px);
  --x-popover-box-shadow: 0px .5rem 2rem 0px rgba(0, 0, 0, .2);
  --x-popover-header-padding-x: .75rem;
  --x-popover-header-padding-y: .75rem;
  --x-popover-header-font-size: 1rem;
  --x-popover-header-color: #16192c;
  --x-popover-header-bg: #fff;
  --x-popover-body-padding-x: .75rem;
  --x-popover-body-padding-y: .75rem;
  --x-popover-body-color: #525f7f;
  --x-popover-arrow-width: 1rem;
  --x-popover-arrow-height: .5rem;
  --x-popover-arrow-border: var(--x-popover-border-color);
  z-index: var(--x-popover-zindex);
  max-width: var(--x-popover-max-width);
  font-family: var(--x-font-sans-serif);
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-style: normal;
  font-weight: 400;
  line-height: 1.625;
  font-size: var(--x-popover-font-size);
  word-wrap: break-word;
  background-color: var(--x-popover-bg);
  border: var(--x-popover-border-width) solid var(--x-popover-border-color);
  border-radius: var(--x-popover-border-radius);
  box-shadow: var(--x-popover-box-shadow);
  background-clip: padding-box;
  text-decoration: none;
  display: block;
}

.popover .popover-arrow {
  width: var(--x-popover-arrow-width);
  height: var(--x-popover-arrow-height);
  display: block;
}

.popover .popover-arrow:before, .popover .popover-arrow:after {
  content: "";
  border: 0 solid rgba(0, 0, 0, 0);
  display: block;
  position: absolute;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(-1 * (var(--x-popover-arrow-height))  - var(--x-popover-border-width));
}

.bs-popover-top > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:before, .bs-popover-top > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:after {
  border-width: var(--x-popover-arrow-height) calc(var(--x-popover-arrow-width) * .5) 0;
}

.bs-popover-top > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:before {
  border-top-color: var(--x-popover-arrow-border);
  bottom: 0;
}

.bs-popover-top > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:after {
  bottom: var(--x-popover-border-width);
  border-top-color: var(--x-popover-bg);
}

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: calc(-1 * (var(--x-popover-arrow-height))  - var(--x-popover-border-width));
  width: var(--x-popover-arrow-height);
  height: var(--x-popover-arrow-width);
}

.bs-popover-end > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:before, .bs-popover-end > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:after {
  border-width: calc(var(--x-popover-arrow-width) * .5) var(--x-popover-arrow-height) calc(var(--x-popover-arrow-width) * .5) 0;
}

.bs-popover-end > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:before {
  border-right-color: var(--x-popover-arrow-border);
  left: 0;
}

.bs-popover-end > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:after {
  left: var(--x-popover-border-width);
  border-right-color: var(--x-popover-bg);
}

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc(-1 * (var(--x-popover-arrow-height))  - var(--x-popover-border-width));
}

.bs-popover-bottom > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:before, .bs-popover-bottom > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:after {
  border-width: 0 calc(var(--x-popover-arrow-width) * .5) var(--x-popover-arrow-height);
}

.bs-popover-bottom > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:before {
  border-bottom-color: var(--x-popover-arrow-border);
  top: 0;
}

.bs-popover-bottom > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:after {
  top: var(--x-popover-border-width);
  border-bottom-color: var(--x-popover-bg);
}

.bs-popover-bottom .popover-header:before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header:before {
  width: var(--x-popover-arrow-width);
  margin-left: calc(-.5 * var(--x-popover-arrow-width));
  content: "";
  border-bottom: var(--x-popover-border-width) solid var(--x-popover-header-bg);
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
}

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc(-1 * (var(--x-popover-arrow-height))  - var(--x-popover-border-width));
  width: var(--x-popover-arrow-height);
  height: var(--x-popover-arrow-width);
}

.bs-popover-start > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:before, .bs-popover-start > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:after {
  border-width: calc(var(--x-popover-arrow-width) * .5) 0 calc(var(--x-popover-arrow-width) * .5) var(--x-popover-arrow-height);
}

.bs-popover-start > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:before {
  border-left-color: var(--x-popover-arrow-border);
  right: 0;
}

.bs-popover-start > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:after {
  right: var(--x-popover-border-width);
  border-left-color: var(--x-popover-bg);
}

.popover-header {
  padding: var(--x-popover-header-padding-y) var(--x-popover-header-padding-x);
  font-size: var(--x-popover-header-font-size);
  color: var(--x-popover-header-color);
  background-color: var(--x-popover-header-bg);
  border-bottom: var(--x-popover-border-width) solid var(--x-popover-border-color);
  border-top-left-radius: var(--x-popover-inner-border-radius);
  border-top-right-radius: var(--x-popover-inner-border-radius);
  margin-bottom: 0;
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--x-popover-body-padding-y) var(--x-popover-body-padding-x);
  color: var(--x-popover-body-color);
}

.popover-header {
  font-weight: 600;
}

.popover-primary {
  background-color: #5c60f5;
}

.popover-primary .popover-header {
  color: #fff;
  background-color: #5c60f5;
}

.popover-primary .popover-body {
  color: #fff;
}

.popover-primary .popover-header {
  border-color: rgba(255, 255, 255, .2);
}

.popover-primary.bs-popover-top .arrow:after, .popover-primary.bs-popover-auto[data-popper-placement^="top"] .arrow:after {
  border-top-color: #5c60f5;
}

.popover-primary.bs-popover-right .arrow:after {
  border-right-color: #5c60f5;
}

.popover-primary.bs-popover-bottom .arrow:after, .popover-primary.bs-popover-auto[data-popper-placement^="bottom"] .arrow:after {
  border-bottom-color: #5c60f5;
}

.popover-primary.bs-popover-left .arrow:after {
  border-left-color: #5c60f5;
}

.popover-secondary {
  background-color: #cfd6df;
}

.popover-secondary .popover-header {
  color: #000;
  background-color: #cfd6df;
}

.popover-secondary .popover-body {
  color: #000;
}

.popover-secondary .popover-header {
  border-color: rgba(0, 0, 0, .2);
}

.popover-secondary.bs-popover-top .arrow:after, .popover-secondary.bs-popover-auto[data-popper-placement^="top"] .arrow:after {
  border-top-color: #cfd6df;
}

.popover-secondary.bs-popover-right .arrow:after {
  border-right-color: #cfd6df;
}

.popover-secondary.bs-popover-bottom .arrow:after, .popover-secondary.bs-popover-auto[data-popper-placement^="bottom"] .arrow:after {
  border-bottom-color: #cfd6df;
}

.popover-secondary.bs-popover-left .arrow:after {
  border-left-color: #cfd6df;
}

.popover-tertiary {
  background-color: #ff579a;
}

.popover-tertiary .popover-header {
  color: #fff;
  background-color: #ff579a;
}

.popover-tertiary .popover-body {
  color: #fff;
}

.popover-tertiary .popover-header {
  border-color: rgba(255, 255, 255, .2);
}

.popover-tertiary.bs-popover-top .arrow:after, .popover-tertiary.bs-popover-auto[data-popper-placement^="top"] .arrow:after {
  border-top-color: #ff579a;
}

.popover-tertiary.bs-popover-right .arrow:after {
  border-right-color: #ff579a;
}

.popover-tertiary.bs-popover-bottom .arrow:after, .popover-tertiary.bs-popover-auto[data-popper-placement^="bottom"] .arrow:after {
  border-bottom-color: #ff579a;
}

.popover-tertiary.bs-popover-left .arrow:after {
  border-left-color: #ff579a;
}

.popover-success {
  background-color: #0c8;
}

.popover-success .popover-header {
  color: #fff;
  background-color: #0c8;
}

.popover-success .popover-body {
  color: #fff;
}

.popover-success .popover-header {
  border-color: rgba(255, 255, 255, .2);
}

.popover-success.bs-popover-top .arrow:after, .popover-success.bs-popover-auto[data-popper-placement^="top"] .arrow:after {
  border-top-color: #0c8;
}

.popover-success.bs-popover-right .arrow:after {
  border-right-color: #0c8;
}

.popover-success.bs-popover-bottom .arrow:after, .popover-success.bs-popover-auto[data-popper-placement^="bottom"] .arrow:after {
  border-bottom-color: #0c8;
}

.popover-success.bs-popover-left .arrow:after {
  border-left-color: #0c8;
}

.popover-info {
  background-color: #00d4ff;
}

.popover-info .popover-header {
  color: #000;
  background-color: #00d4ff;
}

.popover-info .popover-body {
  color: #000;
}

.popover-info .popover-header {
  border-color: rgba(0, 0, 0, .2);
}

.popover-info.bs-popover-top .arrow:after, .popover-info.bs-popover-auto[data-popper-placement^="top"] .arrow:after {
  border-top-color: #00d4ff;
}

.popover-info.bs-popover-right .arrow:after {
  border-right-color: #00d4ff;
}

.popover-info.bs-popover-bottom .arrow:after, .popover-info.bs-popover-auto[data-popper-placement^="bottom"] .arrow:after {
  border-bottom-color: #00d4ff;
}

.popover-info.bs-popover-left .arrow:after {
  border-left-color: #00d4ff;
}

.popover-warning {
  background-color: #ff8c00;
}

.popover-warning .popover-header {
  color: #fff;
  background-color: #ff8c00;
}

.popover-warning .popover-body {
  color: #fff;
}

.popover-warning .popover-header {
  border-color: rgba(255, 255, 255, .2);
}

.popover-warning.bs-popover-top .arrow:after, .popover-warning.bs-popover-auto[data-popper-placement^="top"] .arrow:after {
  border-top-color: #ff8c00;
}

.popover-warning.bs-popover-right .arrow:after {
  border-right-color: #ff8c00;
}

.popover-warning.bs-popover-bottom .arrow:after, .popover-warning.bs-popover-auto[data-popper-placement^="bottom"] .arrow:after {
  border-bottom-color: #ff8c00;
}

.popover-warning.bs-popover-left .arrow:after {
  border-left-color: #ff8c00;
}

.popover-danger {
  background-color: #f36;
}

.popover-danger .popover-header {
  color: #fff;
  background-color: #f36;
}

.popover-danger .popover-body {
  color: #fff;
}

.popover-danger .popover-header {
  border-color: rgba(255, 255, 255, .2);
}

.popover-danger.bs-popover-top .arrow:after, .popover-danger.bs-popover-auto[data-popper-placement^="top"] .arrow:after {
  border-top-color: #f36;
}

.popover-danger.bs-popover-right .arrow:after {
  border-right-color: #f36;
}

.popover-danger.bs-popover-bottom .arrow:after, .popover-danger.bs-popover-auto[data-popper-placement^="bottom"] .arrow:after {
  border-bottom-color: #f36;
}

.popover-danger.bs-popover-left .arrow:after {
  border-left-color: #f36;
}

.popover-white {
  background-color: #fff;
}

.popover-white .popover-header {
  color: #000;
  background-color: #fff;
}

.popover-white .popover-body {
  color: #000;
}

.popover-white .popover-header {
  border-color: rgba(0, 0, 0, .2);
}

.popover-white.bs-popover-top .arrow:after, .popover-white.bs-popover-auto[data-popper-placement^="top"] .arrow:after {
  border-top-color: #fff;
}

.popover-white.bs-popover-right .arrow:after {
  border-right-color: #fff;
}

.popover-white.bs-popover-bottom .arrow:after, .popover-white.bs-popover-auto[data-popper-placement^="bottom"] .arrow:after {
  border-bottom-color: #fff;
}

.popover-white.bs-popover-left .arrow:after {
  border-left-color: #fff;
}

.popover-light {
  background-color: #fff;
}

.popover-light .popover-header {
  color: #000;
  background-color: #fff;
}

.popover-light .popover-body {
  color: #000;
}

.popover-light .popover-header {
  border-color: rgba(0, 0, 0, .2);
}

.popover-light.bs-popover-top .arrow:after, .popover-light.bs-popover-auto[data-popper-placement^="top"] .arrow:after {
  border-top-color: #fff;
}

.popover-light.bs-popover-right .arrow:after {
  border-right-color: #fff;
}

.popover-light.bs-popover-bottom .arrow:after, .popover-light.bs-popover-auto[data-popper-placement^="bottom"] .arrow:after {
  border-bottom-color: #fff;
}

.popover-light.bs-popover-left .arrow:after {
  border-left-color: #fff;
}

.popover-dark {
  background-color: #16192c;
}

.popover-dark .popover-header {
  color: #fff;
  background-color: #16192c;
}

.popover-dark .popover-body {
  color: #fff;
}

.popover-dark .popover-header {
  border-color: rgba(255, 255, 255, .2);
}

.popover-dark.bs-popover-top .arrow:after, .popover-dark.bs-popover-auto[data-popper-placement^="top"] .arrow:after {
  border-top-color: #16192c;
}

.popover-dark.bs-popover-right .arrow:after {
  border-right-color: #16192c;
}

.popover-dark.bs-popover-bottom .arrow:after, .popover-dark.bs-popover-auto[data-popper-placement^="bottom"] .arrow:after {
  border-bottom-color: #16192c;
}

.popover-dark.bs-popover-left .arrow:after {
  border-left-color: #16192c;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: .5rem;
  }
}

.progress {
  --x-progress-height: .5rem;
  --x-progress-font-size: .75rem;
  --x-progress-bg: #e7eaf0;
  --x-progress-border-radius: 50rem;
  --x-progress-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  --x-progress-bar-color: #fff;
  --x-progress-bar-bg: #5c60f5;
  --x-progress-bar-transition: width .6s ease;
  height: var(--x-progress-height);
  font-size: var(--x-progress-font-size);
  background-color: var(--x-progress-bg);
  border-radius: var(--x-progress-border-radius);
  box-shadow: var(--x-progress-box-shadow);
  display: flex;
  overflow: hidden;
}

.progress-bar {
  color: var(--x-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--x-progress-bar-bg);
  transition: var(--x-progress-bar-transition);
  flex-direction: column;
  justify-content: center;
  display: flex;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0) 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0));
  background-size: var(--x-progress-height) var(--x-progress-height);
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.progress-wrapper {
  padding-top: 1.5rem;
  position: relative;
}

.progress-wrapper .progress {
  margin-bottom: 1rem;
}

.progress-inverse {
  background-color: inverse(#e7eaf0);
}

.progress-heading {
  margin: 0 0 2px;
  padding: 0;
  font-size: .875rem;
  font-weight: 600;
}

.progress-text {
  margin-bottom: 0;
}

.progress-lg {
  height: 1rem;
}

.progress-md {
  height: .75rem;
}

.progress-sm {
  height: .375rem;
}

.progress-xs {
  height: .125rem;
}

.progress-group {
  position: relative;
}

.progress-prepend-icon {
  font-size: 20px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.progress-prepend-icon:not(:first-child) {
  right: -17px;
}

.progress-prepend-icon:not(:last-child) {
  left: -17px;
}

@keyframes progressCircleGrow {
  0%, 33% {
    --x-progress-circle-percentage: 0;
  }

  100% {
    --x-progress-circle-percentage: var(--value);
  }
}

@property --x-progress-circle-percentage {
  syntax: "<number>";
  inherits: false;
  initial-value: 0;
}

.progress-circle {
  --x-progress-circle-size: 2.875rem;
  --x-progress-circle-bg: #e7eaf0;
  --x-progress-circle-color: #5c60f5;
  --x-progress-circle-bar-bg: #5c60f5;
  --x-progress-circle-percentage: var(--value);
  width: var(--x-progress-circle-size);
  height: var(--x-progress-circle-size);
  background: radial-gradient(closest-side, currentColor 80%, transparent 0 99.9%, currentColor 0), conic-gradient(var(--x-progress-circle-bar-bg) calc(var(--x-progress-circle-percentage) * 1%), var(--x-progress-circle-bg) 0);
  font-size: calc(var(--x-progress-circle-size) / 5);
  border-radius: 50%;
  place-items: center;
  animation: 3s forwards progressCircleGrow;
  display: grid;
}

.progress-circle-counter:before {
  counter-reset: percentage var(--value);
  content: counter(percentage) "%";
  color: var(--x-progress-circle-color);
}

.progress-circle-2xl {
  --x-progress-circle-size: 7.5rem;
}

.progress-circle-xl {
  --x-progress-circle-size: 6rem;
}

.progress-circle-lg {
  --x-progress-circle-size: 3.5rem;
}

.progress-circle-sm {
  --x-progress-circle-size: 2.25rem;
}

.progress-circle-primary {
  --x-progress-circle-color: var(--x-primary);
  --x-progress-circle-bar-bg: var(--x-primary);
}

.progress-circle-secondary {
  --x-progress-circle-color: var(--x-secondary);
  --x-progress-circle-bar-bg: var(--x-secondary);
}

.progress-circle-tertiary {
  --x-progress-circle-color: var(--x-tertiary);
  --x-progress-circle-bar-bg: var(--x-tertiary);
}

.progress-circle-success {
  --x-progress-circle-color: var(--x-success);
  --x-progress-circle-bar-bg: var(--x-success);
}

.progress-circle-info {
  --x-progress-circle-color: var(--x-info);
  --x-progress-circle-bar-bg: var(--x-info);
}

.progress-circle-warning {
  --x-progress-circle-color: var(--x-warning);
  --x-progress-circle-bar-bg: var(--x-warning);
}

.progress-circle-danger {
  --x-progress-circle-color: var(--x-danger);
  --x-progress-circle-bar-bg: var(--x-danger);
}

.progress-circle-white {
  --x-progress-circle-color: var(--x-white);
  --x-progress-circle-bar-bg: var(--x-white);
}

.progress-circle-light {
  --x-progress-circle-color: var(--x-light);
  --x-progress-circle-bar-bg: var(--x-light);
}

.progress-circle-dark {
  --x-progress-circle-color: var(--x-dark);
  --x-progress-circle-bar-bg: var(--x-dark);
}

.progress-label {
  color: #16192c;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}

.progress-percentage {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
}

.progress-tooltip {
  color: #fff;
  background: rgba(0, 0, 0, .8);
  border-radius: 3px;
  margin-left: -15px;
  padding: .25rem .375rem;
  font-size: .7rem;
  line-height: 1;
  display: inline-block;
  position: relative;
  bottom: 8px;
}

.progress-tooltip:after {
  content: " ";
  height: 0;
  width: 0;
  pointer-events: none;
  border: 5px solid rgba(51, 51, 51, 0);
  border-top-color: rgba(0, 0, 0, .8);
  position: absolute;
  top: 100%;
  left: 10px;
}

.shape-container {
  width: 100%;
  z-index: 2;
  color: #fff;
  position: absolute;
  left: 0;
  right: 0;
  overflow: hidden;
  transform: translateZ(0);
}

.shape-container svg {
  pointer-events: none;
  vertical-align: baseline;
  display: block;
}

.shape-position-top {
  margin-top: -1px;
  top: 0;
}

.shape-position-bottom {
  margin-bottom: -1px;
  bottom: 0;
}

.shape-orientation-inverse {
  transform: rotate(180deg);
}

.shape-line {
  min-height: 19px;
}

.spinner-grow, .spinner-border {
  width: var(--x-spinner-width);
  height: var(--x-spinner-height);
  vertical-align: var(--x-spinner-vertical-align);
  animation: var(--x-spinner-animation-speed) linear infinite var(--x-spinner-animation-name);
  border-radius: 50%;
  display: inline-block;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  --x-spinner-width: 2rem;
  --x-spinner-height: 2rem;
  --x-spinner-vertical-align: -.125em;
  --x-spinner-border-width: .25em;
  --x-spinner-animation-speed: .75s;
  --x-spinner-animation-name: spinner-border;
  border: var(--x-spinner-border-width) solid currentcolor;
  border-right-color: rgba(0, 0, 0, 0);
}

.spinner-border-sm {
  --x-spinner-width: 1rem;
  --x-spinner-height: 1rem;
  --x-spinner-border-width: .2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  --x-spinner-width: 2rem;
  --x-spinner-height: 2rem;
  --x-spinner-vertical-align: -.125em;
  --x-spinner-animation-speed: .75s;
  --x-spinner-animation-name: spinner-grow;
  opacity: 0;
  background-color: currentColor;
}

.spinner-grow-sm {
  --x-spinner-width: 1rem;
  --x-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border, .spinner-grow {
    --x-spinner-animation-speed: 1.5s;
  }
}

.table {
  --x-table-color: #525f7f;
  --x-table-bg: transparent;
  --x-table-border-color: #e7eaf0;
  --x-table-accent-bg: transparent;
  --x-table-striped-color: #525f7f;
  --x-table-striped-bg: rgba(245, 249, 252, .4);
  --x-table-active-color: #525f7f;
  --x-table-active-bg: rgba(245, 249, 252, .8);
  --x-table-hover-color: #525f7f;
  --x-table-hover-bg: rgba(245, 249, 252, .8);
  width: 100%;
  color: var(--x-table-color);
  vertical-align: middle;
  border-color: var(--x-table-border-color);
  margin-bottom: 1rem;
}

.table > :not(caption) > * > * {
  background-color: var(--x-table-bg);
  box-shadow: inset 0 0 0 9999px var(--x-table-accent-bg);
  border-bottom-width: 1px;
  padding: 1rem 1.5rem;
}

.table > tbody {
  vertical-align: inherit;
}

.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: 2px solid #e7eaf0;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: .375rem 1.5rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}

.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(2n+1) > *, .table-striped-columns > :not(caption) > tr > :nth-child(2n) {
  --x-table-accent-bg: var(--x-table-striped-bg);
  color: var(--x-table-striped-color);
}

.table-active {
  --x-table-accent-bg: var(--x-table-active-bg);
  color: var(--x-table-active-color);
}

.table-hover > tbody > tr:hover > * {
  --x-table-accent-bg: var(--x-table-hover-bg);
  color: var(--x-table-hover-color);
}

.table-primary {
  --x-table-color: #000;
  --x-table-bg: #dedffd;
  --x-table-border-color: #c8c9e4;
  --x-table-striped-bg: #858698;
  --x-table-striped-color: #fff;
  --x-table-active-bg: #2c2d33;
  --x-table-active-color: #fff;
  --x-table-hover-bg: #2c2d33;
  --x-table-hover-color: #fff;
  color: var(--x-table-color);
  border-color: var(--x-table-border-color);
}

.table-secondary {
  --x-table-color: #000;
  --x-table-bg: #f5f7f9;
  --x-table-border-color: #dddee0;
  --x-table-striped-bg: #939495;
  --x-table-striped-color: #fff;
  --x-table-active-bg: #313132;
  --x-table-active-color: #fff;
  --x-table-hover-bg: #313132;
  --x-table-hover-color: #fff;
  color: var(--x-table-color);
  border-color: var(--x-table-border-color);
}

.table-success {
  --x-table-color: #000;
  --x-table-bg: #ccf5e7;
  --x-table-border-color: #b8ddd0;
  --x-table-striped-bg: #7a938b;
  --x-table-striped-color: #fff;
  --x-table-active-bg: #29312e;
  --x-table-active-color: #fff;
  --x-table-hover-bg: #29312e;
  --x-table-hover-color: #fff;
  color: var(--x-table-color);
  border-color: var(--x-table-border-color);
}

.table-info {
  --x-table-color: #000;
  --x-table-bg: #ccf6ff;
  --x-table-border-color: #b8dde6;
  --x-table-striped-bg: #7a9499;
  --x-table-striped-color: #fff;
  --x-table-active-bg: #293133;
  --x-table-active-color: #fff;
  --x-table-hover-bg: #293133;
  --x-table-hover-color: #fff;
  color: var(--x-table-color);
  border-color: var(--x-table-border-color);
}

.table-warning {
  --x-table-color: #000;
  --x-table-bg: #ffe8cc;
  --x-table-border-color: #e6d1b8;
  --x-table-striped-bg: #998b7a;
  --x-table-striped-color: #fff;
  --x-table-active-bg: #332e29;
  --x-table-active-color: #fff;
  --x-table-hover-bg: #332e29;
  --x-table-hover-color: #fff;
  color: var(--x-table-color);
  border-color: var(--x-table-border-color);
}

.table-danger {
  --x-table-color: #000;
  --x-table-bg: #ffd6e0;
  --x-table-border-color: #e6c1ca;
  --x-table-striped-bg: #998086;
  --x-table-striped-color: #fff;
  --x-table-active-bg: #332b2d;
  --x-table-active-color: #fff;
  --x-table-hover-bg: #332b2d;
  --x-table-hover-color: #fff;
  color: var(--x-table-color);
  border-color: var(--x-table-border-color);
}

.table-light {
  --x-table-color: #000;
  --x-table-bg: #fff;
  --x-table-border-color: #e6e6e6;
  --x-table-striped-bg: #999;
  --x-table-striped-color: #fff;
  --x-table-active-bg: #333;
  --x-table-active-color: #fff;
  --x-table-hover-bg: #333;
  --x-table-hover-color: #fff;
  color: var(--x-table-color);
  border-color: var(--x-table-border-color);
}

.table-dark {
  --x-table-color: #fff;
  --x-table-bg: #16192c;
  --x-table-border-color: #2d3041;
  --x-table-striped-bg: #737580;
  --x-table-striped-color: #fff;
  --x-table-active-bg: #d0d1d5;
  --x-table-active-color: #000;
  --x-table-hover-bg: #d0d1d5;
  --x-table-hover-color: #000;
  color: var(--x-table-color);
  border-color: var(--x-table-border-color);
}

.table-responsive {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

.table {
  margin-bottom: 0;
}

.table thead th {
  text-transform: uppercase;
  letter-spacing: .025em;
  white-space: nowrap;
  vertical-align: middle;
  border-bottom-width: 1px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: .675rem;
  font-weight: 500;
}

.table tbody th {
  font-size: .8125rem;
}

.table td {
  white-space: nowrap;
  font-size: .8125rem;
}

.table td .progress {
  height: 3px;
  width: 120px;
  margin: 0;
}

.table.table-dark th, .table .table-dark th {
  color: #686a76;
  background-color: #1f2234;
}

.table.table-dark th a, .table .table-dark th a {
  color: #686a76;
}

.table.table-light th, .table .table-light th {
  color: #525f7f;
  background-color: #f5f9fc;
}

.table.table-light th a, .table .table-light th a {
  color: #525f7f;
}

.table-spaced {
  border-collapse: separate;
  border-spacing: 0 1rem;
}

.table-spaced thead th {
  border: 0;
}

.table-spaced thead tr th, .table-spaced thead tr td, .table-spaced tbody tr th, .table-spaced tbody tr td {
  background-color: rgba(0, 0, 0, 0);
  position: relative;
  border-top: 1px solid #e7eaf0 !important;
  border-bottom: 1px solid #e7eaf0 !important;
}

.table-spaced thead tr th:first-child, .table-spaced thead tr td:first-child, .table-spaced tbody tr th:first-child, .table-spaced tbody tr td:first-child {
  border-radius: .375rem 0 0 .375rem;
  border-left: 1px solid #e7eaf0 !important;
}

.table-spaced thead tr th:first-child:after, .table-spaced thead tr td:first-child:after, .table-spaced tbody tr th:first-child:after, .table-spaced tbody tr td:first-child:after {
  border-left: 1px solid #e7eaf0;
  border-radius: .375rem 0 0 .375rem;
}

.table-spaced thead tr th:last-child, .table-spaced thead tr td:last-child, .table-spaced tbody tr th:last-child, .table-spaced tbody tr td:last-child {
  border-radius: 0 .375rem .375rem 0;
  border-right: 1px solid #e7eaf0 !important;
}

.table-spaced tbody tr {
  border-radius: .375rem;
}

.table-flush th, .table-flush td {
  border-left: 0;
  border-right: 0;
}

.table-flush thead > tr th {
  border-top: 0;
}

.table-flush thead > tr th:first-child {
  padding-left: 0;
}

.table-flush thead > tr th:last-child {
  padding-right: 0;
}

.table-flush tbody > tr:last-child th, .table-flush tbody > tr:last-child td {
  border-bottom: 0;
}

.table-flush tbody > tr th:first-child, .table-flush tbody > tr td:first-child {
  padding-left: 0;
}

.table-flush tbody > tr th:last-child, .table-flush tbody > tr td:last-child {
  padding-right: 0;
}

.toast {
  --x-toast-zindex: 1090;
  --x-toast-padding-x: .75rem;
  --x-toast-padding-y: .5rem;
  --x-toast-spacing: 1.5rem;
  --x-toast-max-width: 350px;
  --x-toast-font-size: .875rem;
  --x-toast-color: ;
  --x-toast-bg: rgba(255, 255, 255, .85);
  --x-toast-border-width: 1px;
  --x-toast-border-color: var(--x-border-color-translucent);
  --x-toast-border-radius: .375rem;
  --x-toast-box-shadow: 0px 3px 3px -1px rgba(10, 22, 70, .1), 0px 0px 1px 0px rgba(10, 22, 70, .06);
  --x-toast-header-color: #6b7b93;
  --x-toast-header-bg: rgba(255, 255, 255, .85);
  --x-toast-header-border-color: rgba(0, 0, 0, .05);
  width: var(--x-toast-max-width);
  max-width: 100%;
  font-size: var(--x-toast-font-size);
  color: var(--x-toast-color);
  pointer-events: auto;
  background-color: var(--x-toast-bg);
  border: var(--x-toast-border-width) solid var(--x-toast-border-color);
  box-shadow: var(--x-toast-box-shadow);
  border-radius: var(--x-toast-border-radius);
  background-clip: padding-box;
}

.toast.showing {
  opacity: 0;
}

.toast:not(.show) {
  display: none;
}

.toast-container {
  --x-toast-zindex: 1090;
  z-index: var(--x-toast-zindex);
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 100%;
  pointer-events: none;
  position: absolute;
}

.toast-container > :not(:last-child) {
  margin-bottom: var(--x-toast-spacing);
}

.toast-header {
  padding: var(--x-toast-padding-y) var(--x-toast-padding-x);
  color: var(--x-toast-header-color);
  background-color: var(--x-toast-header-bg);
  border-bottom: var(--x-toast-border-width) solid var(--x-toast-header-border-color);
  border-top-left-radius: calc(var(--x-toast-border-radius)  - var(--x-toast-border-width));
  border-top-right-radius: calc(var(--x-toast-border-radius)  - var(--x-toast-border-width));
  background-clip: padding-box;
  align-items: center;
  display: flex;
}

.toast-header .btn-close {
  margin-right: calc(-.5 * var(--x-toast-padding-x));
  margin-left: var(--x-toast-padding-x);
}

.toast-body {
  padding: var(--x-toast-padding-x);
  word-wrap: break-word;
}

.tooltip {
  --x-tooltip-zindex: 1080;
  --x-tooltip-max-width: 200px;
  --x-tooltip-padding-x: .5rem;
  --x-tooltip-padding-y: .25rem;
  --x-tooltip-margin: ;
  --x-tooltip-font-size: .875rem;
  --x-tooltip-color: #fff;
  --x-tooltip-bg: #000;
  --x-tooltip-border-radius: .375rem;
  --x-tooltip-opacity: .9;
  --x-tooltip-arrow-width: .8rem;
  --x-tooltip-arrow-height: .4rem;
  z-index: var(--x-tooltip-zindex);
  padding: var(--x-tooltip-arrow-height);
  margin: var(--x-tooltip-margin);
  font-family: var(--x-font-sans-serif);
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-style: normal;
  font-weight: 400;
  line-height: 1.625;
  font-size: var(--x-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
  text-decoration: none;
  display: block;
}

.tooltip.show {
  opacity: var(--x-tooltip-opacity);
}

.tooltip .tooltip-arrow {
  width: var(--x-tooltip-arrow-width);
  height: var(--x-tooltip-arrow-height);
  display: block;
}

.tooltip .tooltip-arrow:before {
  content: "";
  border-style: solid;
  border-color: rgba(0, 0, 0, 0);
  position: absolute;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  bottom: 0;
}

.bs-tooltip-top .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow:before {
  border-width: var(--x-tooltip-arrow-height) calc(var(--x-tooltip-arrow-width) * .5) 0;
  border-top-color: var(--x-tooltip-bg);
  top: -1px;
}

.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  width: var(--x-tooltip-arrow-height);
  height: var(--x-tooltip-arrow-width);
  left: 0;
}

.bs-tooltip-end .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow:before {
  border-width: calc(var(--x-tooltip-arrow-width) * .5) var(--x-tooltip-arrow-height) calc(var(--x-tooltip-arrow-width) * .5) 0;
  border-right-color: var(--x-tooltip-bg);
  right: -1px;
}

.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  top: 0;
}

.bs-tooltip-bottom .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow:before {
  border-width: 0 calc(var(--x-tooltip-arrow-width) * .5) var(--x-tooltip-arrow-height);
  border-bottom-color: var(--x-tooltip-bg);
  bottom: -1px;
}

.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  width: var(--x-tooltip-arrow-height);
  height: var(--x-tooltip-arrow-width);
  right: 0;
}

.bs-tooltip-start .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow:before {
  border-width: calc(var(--x-tooltip-arrow-width) * .5) 0 calc(var(--x-tooltip-arrow-width) * .5) var(--x-tooltip-arrow-height);
  border-left-color: var(--x-tooltip-bg);
  left: -1px;
}

.tooltip-inner {
  max-width: var(--x-tooltip-max-width);
  padding: var(--x-tooltip-padding-y) var(--x-tooltip-padding-x);
  color: var(--x-tooltip-color);
  text-align: center;
  background-color: var(--x-tooltip-bg);
  border-radius: var(--x-tooltip-border-radius);
}

.article {
  color: #525f7f;
  font-size: 1rem;
  line-height: 1.9;
}

.article > p {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.article > h2, .article > .h2 {
  letter-spacing: -.025em;
  margin-top: 2em;
  margin-bottom: 1em;
  font-size: 1.5em;
  font-weight: bolder;
  line-height: 1.33333;
}

.article > h2:first-child, .article > .h2:first-child {
  margin-top: 1em;
}

.article > h3, .article > .h3 {
  margin-top: 1.6em;
  margin-bottom: .6em;
  font-size: 1.25em;
  font-weight: 600;
}

.article > h4, .article > .h4 {
  margin-top: 2em;
  font-size: 1em;
  font-weight: 500;
}

.article > img {
  border-radius: .375rem;
}

.article > figure, .article > img, .article > video {
  margin-top: 2em;
  margin-bottom: 2em;
}

.article pre {
  color: #fff;
  background-color: #16192c;
  border-radius: .375rem;
  margin-top: 1.75em;
  margin-bottom: 1.75em;
  padding: 1rem;
  font-size: .875em;
  line-height: 1.7;
  overflow-x: auto;
}

.article > :first-child, .article h2 + *, .article .h2 + *, .article h3 + *, .article .h3 + *, .article hr + * {
  margin-top: 0;
}

.article > hr {
  margin-top: 3em;
  margin-bottom: 3em;
}

.article > ul, .article > ol {
  padding-left: 1.5rem;
}

.article > ul li, .article > ol li {
  padding: .25rem 0;
}

.article > blockquote {
  color: #16192c;
  letter-spacing: -.012em;
  background-color: var(--x-surface-secondary);
  border-radius: .5rem;
  margin-top: 3em;
  margin-bottom: 3em;
  padding: 1.5rem 3.5rem;
  font-size: 1.25rem;
  font-weight: 500;
}

.article > blockquote > p {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

@media (max-width: 991.98px) {
  .section-step-lg {
    padding-bottom: 3rem;
  }

  .section-step-lg + .section-step-lg {
    padding-top: 3rem;
  }

  .section-step-lg:last-child {
    padding-bottom: 0;
  }
}

@media (min-width: 992px) {
  .section-step-lg {
    padding-bottom: 5rem;
    position: relative;
  }

  .section-step-lg + .section-step-lg {
    padding-top: 5rem;
  }

  .section-step-lg:last-child {
    padding-bottom: 0;
  }

  .section-step-lg:not(:last-child):before {
    content: "";
    width: 360px;
    height: 100px;
    background: url("data:image/svg+xml;charset=utf8,%3Csvg width='355px' height='103px' viewBox='0 0 355 103' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-dasharray='6,12' stroke-linecap='round'%3E%3Cpath stroke='%23E7EAF0' stroke-width='3' transform='translate(173.245679, 51.548257) scale(-1, 1) translate(-173.245679, -51.548257)' d='M-6.75432109,1.54825684 C113.245679,110.326533 233.245679,130.359459 353.245679,61.647035'/%3E%3C/g%3E%3C/svg%3E") center / 360px 100px no-repeat;
    margin: auto;
    display: block;
    position: absolute;
    bottom: -80px;
    left: 0;
    right: 0;
    transform: rotate(40deg);
  }
}

@media (min-width: 992px) and (max-width: 767.98px) {
  .section-step-lg:not(:last-child):before {
    bottom: 20px;
    transform: rotate(90deg)scale(.7);
  }
}

@media (min-width: 992px) {
  .section-step-lg:nth-child(2n+1) .row > div:first-child {
    order: 2;
  }

  .section-step-lg:nth-child(2n+1) .row > div:last-child {
    order: 1;
  }

  .section-step-lg:nth-child(2n):before {
    background: url("data:image/svg+xml;charset=utf8,%3Csvg width='355px' height='103px' viewBox='0 0 355 103' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-dasharray='6,12' stroke-linecap='round'%3E%3Cpath stroke='%23E7EAF0' stroke-width='3' d='M2.24567891,1.54825684 C122.245679,110.326533 242.245679,130.359459 362.245679,61.647035'/%3E%3C/g%3E%3C/svg%3E") center / 360px 100px no-repeat;
    transform: rotate(-40deg);
  }
}

@media (min-width: 992px) and (max-width: 767.98px) {
  .section-step-lg:nth-child(2n):before {
    bottom: 20px;
    transform: rotate(-90deg)scale(.7);
  }
}

.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  color: #3f4254;
  font-weight: 500;
  background: #fff !important;
  border-bottom: 1px solid #f5f8fa !important;
}

.apexcharts-tooltip .apexcharts-tooltip-title {
  padding: .5rem 1rem;
}

.apexcharts-xaxistooltip.apexcharts-theme-light {
  color: #3f4254;
  background: #fff !important;
  border: 0 !important;
  border-radius: .475rem !important;
  box-shadow: 0 0 50px rgba(82, 63, 105, .15) !important;
}

.apexcharts-xaxistooltip.apexcharts-theme-light:before {
  border-bottom: 0 !important;
}

.apexcharts-xaxistooltip.apexcharts-theme-light:after {
  border-bottom-color: #fff !important;
}

.card-rounded-bottom .apexcharts-canvas svg {
  border-bottom-right-radius: .475rem;
  border-bottom-left-radius: .475rem;
}

.rounded .apexcharts-canvas svg {
  border-radius: .475rem !important;
}

.rounded-sm .apexcharts-canvas svg {
  border-radius: .275rem !important;
}

.rounded-lg .apexcharts-canvas svg {
  border-radius: .775rem !important;
}

.rounded-xl .apexcharts-canvas svg {
  border-radius: 1rem !important;
}

.apexcharts-bar-hover-white .apexcharts-bar-area:hover {
  fill: #fff !important;
}

.flatpickr-calendar {
  opacity: 0;
  text-align: center;
  visibility: hidden;
  direction: ltr;
  width: 307.875px;
  box-sizing: border-box;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  background: #fff;
  border: 0;
  border-radius: 5px;
  padding: 0;
  font-size: 14px;
  line-height: 24px;
  animation: none;
  display: none;
  position: absolute;
  box-shadow: 1px 0 #e6e6e6, -1px 0 #e6e6e6, 0 1px #e6e6e6, 0 -1px #e6e6e6, 0 3px 13px rgba(0, 0, 0, .08);
}

.flatpickr-calendar.open, .flatpickr-calendar.inline {
  opacity: 1;
  max-height: 640px;
  visibility: visible;
}

.flatpickr-calendar.open {
  z-index: 99999;
  display: inline-block;
}

.flatpickr-calendar.animate.open {
  animation: .3s cubic-bezier(.23, 1, .32, 1) fpFadeInDown;
}

.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px;
}

.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px);
}

.flatpickr-calendar.static.open {
  z-index: 999;
  display: block;
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+1) .flatpickr-day.inRange:nth-child(7n+7) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
  box-shadow: -2px 0 #e6e6e6, 5px 0 #e6e6e6;
}

.flatpickr-calendar .hasWeeks .dayContainer, .flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0;
}

.flatpickr-calendar.hasTime .flatpickr-time {
  height: 40px;
  border-top: 1px solid #e6e6e6;
}

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto;
}

.flatpickr-calendar:before, .flatpickr-calendar:after {
  pointer-events: none;
  content: "";
  height: 0;
  width: 0;
  border: solid rgba(0, 0, 0, 0);
  display: block;
  position: absolute;
  left: 22px;
}

.flatpickr-calendar.rightMost:before, .flatpickr-calendar.arrowRight:before, .flatpickr-calendar.rightMost:after, .flatpickr-calendar.arrowRight:after {
  left: auto;
  right: 22px;
}

.flatpickr-calendar.arrowCenter:before, .flatpickr-calendar.arrowCenter:after {
  left: 50%;
  right: 50%;
}

.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px;
}

.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px;
}

.flatpickr-calendar.arrowTop:before, .flatpickr-calendar.arrowTop:after {
  bottom: 100%;
}

.flatpickr-calendar.arrowTop:before {
  border-bottom-color: #e6e6e6;
}

.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #fff;
}

.flatpickr-calendar.arrowBottom:before, .flatpickr-calendar.arrowBottom:after {
  top: 100%;
}

.flatpickr-calendar.arrowBottom:before {
  border-top-color: #e6e6e6;
}

.flatpickr-calendar.arrowBottom:after {
  border-top-color: #fff;
}

.flatpickr-calendar:focus {
  outline: 0;
}

.flatpickr-wrapper {
  display: inline-block;
  position: relative;
}

.flatpickr-months {
  display: flex;
}

.flatpickr-months .flatpickr-month {
  color: rgba(0, 0, 0, .9);
  fill: rgba(0, 0, 0, .9);
  height: 34px;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background: none;
  flex: 1;
  line-height: 1;
  position: relative;
  overflow: hidden;
}

.flatpickr-months .flatpickr-prev-month, .flatpickr-months .flatpickr-next-month {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  cursor: pointer;
  height: 34px;
  z-index: 3;
  color: rgba(0, 0, 0, .9);
  fill: rgba(0, 0, 0, .9);
  padding: 10px;
  text-decoration: none;
  position: absolute;
  top: 0;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-disabled, .flatpickr-months .flatpickr-next-month.flatpickr-disabled {
  display: none;
}

.flatpickr-months .flatpickr-prev-month i, .flatpickr-months .flatpickr-next-month i {
  position: relative;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month, .flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
  left: 0;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-next-month, .flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  right: 0;
}

.flatpickr-months .flatpickr-prev-month:hover, .flatpickr-months .flatpickr-next-month:hover {
  color: #959ea9;
}

.flatpickr-months .flatpickr-prev-month:hover svg, .flatpickr-months .flatpickr-next-month:hover svg {
  fill: #f64747;
}

.flatpickr-months .flatpickr-prev-month svg, .flatpickr-months .flatpickr-next-month svg {
  width: 14px;
  height: 14px;
}

.flatpickr-months .flatpickr-prev-month svg path, .flatpickr-months .flatpickr-next-month svg path {
  fill: inherit;
  transition: fill .1s;
}

.numInputWrapper {
  height: auto;
  position: relative;
}

.numInputWrapper input, .numInputWrapper span {
  display: inline-block;
}

.numInputWrapper input {
  width: 100%;
}

.numInputWrapper input::-ms-clear {
  display: none;
}

.numInputWrapper input::-webkit-outer-spin-button, .numInputWrapper input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.numInputWrapper span {
  width: 14px;
  height: 50%;
  opacity: 0;
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid rgba(57, 57, 57, .15);
  padding: 0 4px 0 2px;
  line-height: 50%;
  position: absolute;
  right: 0;
}

.numInputWrapper span:hover {
  background: rgba(0, 0, 0, .1);
}

.numInputWrapper span:active {
  background: rgba(0, 0, 0, .2);
}

.numInputWrapper span:after {
  content: "";
  display: block;
  position: absolute;
}

.numInputWrapper span.arrowUp {
  border-bottom: 0;
  top: 0;
}

.numInputWrapper span.arrowUp:after {
  border-bottom: 4px solid rgba(57, 57, 57, .6);
  border-left: 4px solid rgba(0, 0, 0, 0);
  border-right: 4px solid rgba(0, 0, 0, 0);
  top: 26%;
}

.numInputWrapper span.arrowDown {
  top: 50%;
}

.numInputWrapper span.arrowDown:after {
  border-top: 4px solid rgba(57, 57, 57, .6);
  border-left: 4px solid rgba(0, 0, 0, 0);
  border-right: 4px solid rgba(0, 0, 0, 0);
  top: 40%;
}

.numInputWrapper span svg {
  width: inherit;
  height: auto;
}

.numInputWrapper span svg path {
  fill: rgba(0, 0, 0, .5);
}

.numInputWrapper:hover {
  background: rgba(0, 0, 0, .05);
}

.numInputWrapper:hover span {
  opacity: 1;
}

.flatpickr-current-month {
  font-size: 135%;
  line-height: inherit;
  color: inherit;
  width: 75%;
  height: 34px;
  text-align: center;
  padding: 7.48px 0 0;
  font-weight: 300;
  line-height: 1;
  display: inline-block;
  position: absolute;
  left: 12.5%;
  transform: translate3d(0, 0, 0);
}

.flatpickr-current-month span.cur-month {
  color: inherit;
  margin-left: .5ch;
  padding: 0;
  font-family: inherit;
  font-weight: 700;
  display: inline-block;
}

.flatpickr-current-month span.cur-month:hover {
  background: rgba(0, 0, 0, .05);
}

.flatpickr-current-month .numInputWrapper {
  width: 6ch;
  width: 7ch�;
  display: inline-block;
}

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: rgba(0, 0, 0, .9);
}

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: rgba(0, 0, 0, .9);
}

.flatpickr-current-month input.cur-year {
  box-sizing: border-box;
  color: inherit;
  cursor: text;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  height: auto;
  vertical-align: initial;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
  background: none;
  border: 0;
  border-radius: 0;
  margin: 0;
  padding: 0 0 0 .5ch;
  display: inline-block;
}

.flatpickr-current-month input.cur-year:focus {
  outline: 0;
}

.flatpickr-current-month input.cur-year[disabled], .flatpickr-current-month input.cur-year[disabled]:hover {
  color: rgba(0, 0, 0, .5);
  pointer-events: none;
  background: none;
  font-size: 100%;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  appearance: menulist;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  height: auto;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  vertical-align: initial;
  width: auto;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  margin: -1px 0 0;
  padding: 0 0 0 .5ch;
  position: relative;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:focus, .flatpickr-current-month .flatpickr-monthDropdown-months:active {
  outline: none;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background: rgba(0, 0, 0, .05);
}

.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  background-color: rgba(0, 0, 0, 0);
  outline: none;
  padding: 0;
}

.flatpickr-weekdays {
  text-align: center;
  width: 100%;
  height: 28px;
  background: none;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.flatpickr-weekdays .flatpickr-weekdaycontainer {
  flex: 1;
  display: flex;
}

span.flatpickr-weekday {
  cursor: default;
  color: rgba(0, 0, 0, .54);
  text-align: center;
  background: none;
  flex: 1;
  margin: 0;
  font-size: 90%;
  font-weight: bolder;
  line-height: 1;
  display: block;
}

.dayContainer, .flatpickr-weeks {
  padding: 1px 0 0;
}

.flatpickr-days {
  width: 307.875px;
  align-items: flex-start;
  display: flex;
  position: relative;
  overflow: hidden;
}

.flatpickr-days:focus {
  outline: 0;
}

.dayContainer {
  text-align: left;
  width: 307.875px;
  min-width: 307.875px;
  max-width: 307.875px;
  box-sizing: border-box;
  opacity: 1;
  outline: 0;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 0;
  display: flex;
  transform: translate3d(0, 0, 0);
}

.dayContainer + .dayContainer {
  box-shadow: -1px 0 #e6e6e6;
}

.flatpickr-day {
  box-sizing: border-box;
  color: #393939;
  cursor: pointer;
  width: 14.2857%;
  max-width: 39px;
  height: 39px;
  text-align: center;
  background: none;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 150px;
  flex-basis: 14.2857%;
  justify-content: center;
  margin: 0;
  font-weight: 400;
  line-height: 39px;
  display: inline-block;
  position: relative;
}

.flatpickr-day.inRange, .flatpickr-day.prevMonthDay.inRange, .flatpickr-day.nextMonthDay.inRange, .flatpickr-day.today.inRange, .flatpickr-day.prevMonthDay.today.inRange, .flatpickr-day.nextMonthDay.today.inRange, .flatpickr-day:hover, .flatpickr-day.prevMonthDay:hover, .flatpickr-day.nextMonthDay:hover, .flatpickr-day:focus, .flatpickr-day.prevMonthDay:focus, .flatpickr-day.nextMonthDay:focus {
  cursor: pointer;
  background: #e6e6e6;
  border-color: #e6e6e6;
  outline: 0;
}

.flatpickr-day.today {
  border-color: #959ea9;
}

.flatpickr-day.today:hover, .flatpickr-day.today:focus {
  color: #fff;
  background: #959ea9;
  border-color: #959ea9;
}

.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  background: #569ff7;
  border-color: #569ff7;
}

.flatpickr-day.selected.startRange, .flatpickr-day.startRange.startRange, .flatpickr-day.endRange.startRange {
  border-radius: 50px 0 0 50px;
}

.flatpickr-day.selected.endRange, .flatpickr-day.startRange.endRange, .flatpickr-day.endRange.endRange {
  border-radius: 0 50px 50px 0;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  box-shadow: -10px 0 #569ff7;
}

.flatpickr-day.selected.startRange.endRange, .flatpickr-day.startRange.startRange.endRange, .flatpickr-day.endRange.startRange.endRange {
  border-radius: 50px;
}

.flatpickr-day.inRange {
  border-radius: 0;
  box-shadow: -5px 0 #e6e6e6, 5px 0 #e6e6e6;
}

.flatpickr-day.flatpickr-disabled, .flatpickr-day.flatpickr-disabled:hover, .flatpickr-day.prevMonthDay, .flatpickr-day.nextMonthDay, .flatpickr-day.notAllowed, .flatpickr-day.notAllowed.prevMonthDay, .flatpickr-day.notAllowed.nextMonthDay {
  color: rgba(57, 57, 57, .3);
  cursor: default;
  background: none;
  border-color: rgba(0, 0, 0, 0);
}

.flatpickr-day.flatpickr-disabled, .flatpickr-day.flatpickr-disabled:hover {
  cursor: not-allowed;
  color: rgba(57, 57, 57, .1);
}

.flatpickr-day.week.selected {
  border-radius: 0;
  box-shadow: -5px 0 #569ff7, 5px 0 #569ff7;
}

.flatpickr-day.hidden {
  visibility: hidden;
}

.rangeMode .flatpickr-day {
  margin-top: 1px;
}

.flatpickr-weekwrapper {
  float: left;
}

.flatpickr-weekwrapper .flatpickr-weeks {
  padding: 0 12px;
  box-shadow: 1px 0 #e6e6e6;
}

.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
  line-height: 28px;
}

.flatpickr-weekwrapper span.flatpickr-day, .flatpickr-weekwrapper span.flatpickr-day:hover {
  width: 100%;
  max-width: none;
  color: rgba(57, 57, 57, .3);
  cursor: default;
  background: none;
  border: none;
  display: block;
}

.flatpickr-innerContainer {
  box-sizing: border-box;
  display: flex;
  overflow: hidden;
}

.flatpickr-rContainer {
  box-sizing: border-box;
  padding: 0;
  display: inline-block;
}

.flatpickr-time {
  text-align: center;
  height: 0;
  max-height: 40px;
  box-sizing: border-box;
  outline: 0;
  line-height: 40px;
  display: flex;
  overflow: hidden;
}

.flatpickr-time:after {
  content: "";
  clear: both;
  display: table;
}

.flatpickr-time .numInputWrapper {
  width: 40%;
  height: 40px;
  float: left;
  flex: 1;
}

.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: #393939;
}

.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: #393939;
}

.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%;
}

.flatpickr-time.time24hr .numInputWrapper {
  width: 49%;
}

.flatpickr-time input {
  -webkit-box-shadow: none;
  box-shadow: none;
  text-align: center;
  height: inherit;
  line-height: inherit;
  color: #393939;
  box-sizing: border-box;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
  background: none;
  border: 0;
  border-radius: 0;
  margin: 0;
  padding: 0;
  font-size: 14px;
  position: relative;
}

.flatpickr-time input.flatpickr-hour {
  font-weight: bold;
}

.flatpickr-time input.flatpickr-minute, .flatpickr-time input.flatpickr-second {
  font-weight: 400;
}

.flatpickr-time input:focus {
  border: 0;
  outline: 0;
}

.flatpickr-time .flatpickr-time-separator, .flatpickr-time .flatpickr-am-pm {
  height: inherit;
  float: left;
  line-height: inherit;
  color: #393939;
  width: 2%;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  align-self: center;
  font-weight: bold;
}

.flatpickr-time .flatpickr-am-pm {
  width: 18%;
  cursor: pointer;
  text-align: center;
  outline: 0;
  font-weight: 400;
}

.flatpickr-time input:hover, .flatpickr-time .flatpickr-am-pm:hover, .flatpickr-time input:focus, .flatpickr-time .flatpickr-am-pm:focus {
  background: #eee;
}

.flatpickr-input[readonly] {
  cursor: pointer;
}

@keyframes fpFadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.flatpickr-calendar {
  width: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background: #fff;
  border: 0;
  border-radius: .75rem;
  margin-top: -2px;
  box-shadow: 0 16px 16px -1px rgba(10, 22, 70, .1), 0 0 1px rgba(10, 22, 70, .06);
}

.flatpickr-calendar:before, .flatpickr-calendar:after {
  display: none;
}

.flatpickr-months {
  background-color: #fff;
  border-top-left-radius: .375rem;
  border-top-right-radius: .375rem;
}

.flatpickr-months .flatpickr-month {
  height: 60px;
}

.flatpickr-months .flatpickr-prev-month, .flatpickr-months .flatpickr-next-month {
  width: 35px;
  height: 35px;
  color: #16192c;
  justify-content: center;
  align-items: center;
  padding: 0;
  line-height: 100%;
  transition: background-color .4s linear;
  display: flex;
  top: 13px;
}

@media (prefers-reduced-motion: reduce) {
  .flatpickr-months .flatpickr-prev-month, .flatpickr-months .flatpickr-next-month {
    transition: none;
  }
}

.flatpickr-months .flatpickr-prev-month svg, .flatpickr-months .flatpickr-next-month svg {
  fill: rgba(22, 25, 44, .7);
}

.flatpickr-months .flatpickr-prev-month:hover, .flatpickr-months .flatpickr-next-month:hover {
  color: #16192c;
}

.flatpickr-months .flatpickr-prev-month:hover svg, .flatpickr-months .flatpickr-next-month:hover svg {
  fill: #16192c;
}

.flatpickr-months .flatpickr-prev-month {
  margin-left: 15px;
}

.flatpickr-months .flatpickr-next-month {
  margin-right: 15px;
}

.flatpickr-current-month {
  color: #16192c;
  padding-top: 18px;
  font-size: 1.125rem;
}

.flatpickr-current-month span.cur-month:hover, .flatpickr-current-month .numInputWrapper:hover {
  background-color: rgba(0, 0, 0, 0);
}

.flatpickr-current-month .numInputWrapper span {
  border: 0;
  padding: 0;
  right: -5px;
}

.flatpickr-current-month .numInputWrapper span:after {
  left: 3px;
}

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: rgba(255, 255, 255, .7);
}

.flatpickr-current-month .numInputWrapper span.arrowUp:hover:after {
  border-bottom-color: #fff;
}

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: rgba(255, 255, 255, .7);
}

.flatpickr-current-month .numInputWrapper span.arrowDown:hover:after {
  border-top-color: #fff;
}

span.flatpickr-weekday {
  color: #16192c;
  font-weight: 600;
}

.flatpickr-day {
  color: #16192c;
  border: 0;
  border-radius: .375rem;
  font-size: .875rem;
}

.flatpickr-day.today {
  color: #5c60f5 !important;
}

.flatpickr-day.today:hover, .flatpickr-day:hover {
  color: theme-color("primary");
  background-color: rgba(0, 0, 0, 0);
}

.flatpickr-day.selected {
  background-color: #5c60f5;
  color: #fff !important;
}

.flatpickr-day.selected:hover {
  color: #fff;
  background-color: #5c60f5;
}

.numInputWrapper span:hover {
  background-color: rgba(0, 0, 0, 0);
}

.flatpickr-time {
  border-top: 1px solid #f2f3f7;
}

.flatpickr-innerContainer {
  padding: 15px;
}

@media only screen and (prefers-color-scheme: dark) {
  :root:not([data-theme="light"]) {
    color-scheme: dark;
    --x-body-bg: 22, 25, 44;
    --x-body-color: var(--x-gray-100);
    --x-headings-color: var(--x-gray-50);
    --x-heading-rgb: 250, 250, 250;
    --x-muted-rgb: 207, 214, 223;
    --x-border-color: 45, 55, 72;
    --x-surface-primary-rgb: 22, 25, 44;
    --x-surface-secondary-rgb: 45, 55, 72;
  }

  :root:not([data-theme="light"]) .navbar {
    --x-navbar-color: var(--x-gray-100);
    --x-navbar-hover-bg: #27303f;
    --x-navbar-hover-color: var(--x-gray-100);
  }

  :root:not([data-theme="light"]) .card {
    --x-card-bg: var(--x-gray-800);
    --x-card-border-color: var(--x-gray-800);
    --x-card-color: var(--x-body-color);
  }
}

[data-theme="dark"] {
  color-scheme: dark;
  --x-body-bg: 22, 25, 44;
  --x-body-color: var(--x-gray-100);
  --x-headings-color: var(--x-gray-50);
  --x-heading-rgb: 250, 250, 250;
  --x-muted-rgb: 207, 214, 223;
  --x-border-color: 45, 55, 72;
  --x-surface-primary-rgb: 22, 25, 44;
  --x-surface-secondary-rgb: 45, 55, 72;
}

[data-theme="dark"] .navbar {
  --x-navbar-color: var(--x-gray-100);
  --x-navbar-hover-bg: #27303f;
  --x-navbar-hover-color: var(--x-gray-100);
}

[data-theme="dark"] .card {
  --x-card-bg: var(--x-gray-800);
  --x-card-border-color: var(--x-gray-800);
  --x-card-color: var(--x-body-color);
}

/*# sourceMappingURL=main.css.map */
